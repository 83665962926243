const newPageClear = () => {

	const keysToRemove = [
		'search_source',
		'search', 
		'wasSearched', 
		'filters', 
		'limit', 
		'sort', 
		'searchtype', 
		'selectedArticle', 
		'subject', 
		'receipients', 
		'content'];

	keysToRemove.forEach(k => 
			localStorage.removeItem(k)
	)

	localStorage.setItem('filters', JSON.stringify({['type']: ['PDF', 'Excel', 'Word', "Powerpoint"]}))
}

export default newPageClear