import React, { useState, useEffect, useRef } from 'react';
import { Defaultnav } from '../components/navbar';
import { Container, Card, Row, Col, Form, Button, Modal, Alert, Spinner, Badge } from 'react-bootstrap';
import { PDFTextSelector } from '../components/highlightpdf'
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory, useNavigate } from 'react-router-dom';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css'
import { API_ENDPOINT } from '../utils/endpoint'
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import DropDownContainer from '../components/dropdowntree'
import RangeSlider from 'react-bootstrap-range-slider';
import { FaFile } from 'react-icons/fa';
import { ModalAlert } from '../components/toast';
import { useReportContextProvider } from '../contexts/reportContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const InferencePage = () => {
	const history = useHistory()

	const {
		onChange, 
		onAction, 
		onNodeToggle, 
		selectedArticle,
		setSelectedArticle, 
		refreshReportSources, 
		sourceIDs,
		getFileStructure:updateReportFileStructure
	} = useReportContextProvider()

	const [article, setArticle] = useState(
			JSON.parse(localStorage.getItem('selectedArticle')) || 
			history.location.state ||  selectedArticle ||
			{}
		)
	//const data = location.state.article
	const [loaded, isLoaded] = useState(false)
	const [url, setURL] = useState(null)
	const [numPages, setNumPages] = useState(5)
	const [preview, setPreview] = useState('pdf')
	const [selection, setSelection] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [inputValue, setInputValue] = useState(3)
	const [inferenceTypes, setInferenceTypes] = useState({
		'text-classification': false,
		'ner': false,
		'keyword': false,
		'summary': true
	})
	const [fileTree, setFileTree] = useState([{}])
	const [showSelection, setShowSelection] = useState(true)
	const [length, setLength] = useState(1)
	const [summary, setSummary] = useState([])
	const [fetching, setFetching] = useState(false)
	const [headlines, setHeadLines] = useState({})
	const [count, setCount] = useState(0) 
	const [timeoutError, setTimeoutError] = useState(false)
	const [pageNums, setPageNums] = useState(null)
	const [textSelections, setTextSelections] = useState([])


	const pageRangeIsValid = new RegExp("^(\\s*\\d+\\s*\\-\\s*\\d+\\s*,?|\\s*\\d+\\s*,?)+$");
	

	const fetchingTimeOut = () => {
		setFetching(false);
		setTimeoutError(true);
	}

	useEffect(() => {
		let timeout
		if (fetching) {
		  timeout = setTimeout(() => fetchingTimeOut(), 300000);
		  
		}
		return () => clearTimeout(timeout);
	  }, [fetching]);


	useEffect(() => {
		if (count == selection.length) {
			setFetching(false)
			setTimeoutError(false);
		} else if (count == 1 && inputValue != 3) {
			setFetching(false); 
			setTimeoutError(false);
		}
	}, [count, selection, inputValue])

	useEffect(() => {
		const getFileStructure = async () => {
			const token = localStorage.getItem('token')
			await fetch(`${API_ENDPOINT}/data/file/tree`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
			})
				.then(response => response.json())
				.then(res => {
					if (Object.keys(selectedArticle).length > 0) {
						let updatedTree = res
						let folderIndex = updatedTree.findIndex(o => o.label === selectedArticle.directory)
						let fileIndex = updatedTree[folderIndex]['children'].findIndex(o => o.label === selectedArticle.title)
						updatedTree[folderIndex]['children'][fileIndex]['checked'] = true
						setFileTree(updatedTree)
						setSelectedArticle(prevState => ({ ...prevState, summaries: updatedTree[folderIndex]['children'][fileIndex].summaries }))
					} else {
						setFileTree(res)
					}
				})
		}
		getFileStructure()
	}, [])


	const fetchSummary = async (selected) => {
		let text = inputValue != 3 ? null : selected.content['text'] 
		//let length = length
		let file = inputValue != 3 ? url : null 
		let pagenums = inputValue == 2 ? pageNums : null
		
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/summarize`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}, 
			body: JSON.stringify({"text": text, "file": file, "pagenums": pagenums, "length": length})
		}).then(response => response.json())
			.then(res => {
				setSummary(summary => [...summary, {
					summary: res.summary.replaceAll(" .", "."),
					order: inputValue == 3 ? selected.order : 1,
					selection: inputValue == 3 ? selected.content['text'] : 'Full Text'
				}
				])

				setCount(prevState => prevState + 1)

			})
			.catch((error) => {
				setTimeoutError(true)
				setFetching(false)
				// console.log(error)
			  })

	}

	const disabledInference = (inputValue == 3 && selection.length === 0) || (inputValue == 2 && !pageNums) || (inputValue == 2 && !pageRangeIsValid.test(pageNums))

	const sendSummary = async (summary, title, id) => {
		const token = localStorage.getItem('token'); 
		const data = {
			summary: summary,
			title: title,
			id: id
		}
		await fetch(`${API_ENDPOINT}/data/store/summary`, {
			method: 'POST', 
			headers: { 'Content-Type': 'application/json'},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(res => console.log(res))
	}

	const sendText = async (text, selectedArticle) => {
		const token = localStorage.getItem('token'); 
		const data = {
			id: selectedArticle.id,
			title: selectedArticle.title,
			text: text
		}
		await fetch(`${API_ENDPOINT}/data/store/text`, {
			method: 'POST', 
			headers: { 'Content-Type': 'application/json'},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(res => {console.log(res)
				updateReportFileStructure()})
	}

	const sendImage = async (image, selectedArticle) => {

		const data = {
			image: image, 
			id: selectedArticle.id, 
			article: selectedArticle.title
		}
		// console.log("data being sent: ", data)
		await fetch (`${API_ENDPOINT}/data/store/image`, {
			headers: {'Content-Type': 'application/json'},
			method: 'POST', 
			body: JSON.stringify(data),
		}).then(res => res.json())
		.then(res => {console.log(res)
		
		updateReportFileStructure()})

	}
	const onClickSendSummary = () => {
		summary.forEach(s => {
			let summary = s.summary
			let title = headlines[s.order] || null
			let id = selectedArticle.id
			sendSummary(summary, title, id)
		})
		setShowModal(true)
		updateReportFileStructure()
	}


	useEffect(() => {
		const token = localStorage.getItem('token')
		const fetchURL = async (url) => {
			await fetch(`${API_ENDPOINT}/data/url?key=${url}&filename=${selectedArticle.title + '.pdf'}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'content-type': 'application/json'
				}
			})
				.then(res => res.json())
				.then(result => { setURL(result) })
		}
		if (selectedArticle) {
			const url = encodeURIComponent(selectedArticle.key)
			fetchURL(url)
			setSummary([])
			setSelection([])
			setHeadLines({})
		}
	}, [selectedArticle])

	const onHide = () => {
		setShowModal(false)
	}

	const countOfInferenceTypes = Object.keys(inferenceTypes).filter(key => inferenceTypes[key]).length

	const onCloseSelection = (selected) => {
		setSelection(selection.filter(s => s.id !== selected.id))

	}

	const onClearSummary = () => { setSummary([]) }

	const onClickInference = () => {
		setFetching(true) 
		setCount(0)
		setSummary([])
		if (selection.length > 0) {
			selection.forEach(selected => {
				fetchSummary(selected);
				}
			)
		} else {
				fetchSummary();
			}
		}

	const displaySelections = (selected) => {
		const summaryText = summary.find(s => s.order === selected.order) || null
			return (
				<>
					<Button variant='link' onClick={() => setShowSelection(prevState => !prevState)}>
						{showSelection ? "Hide Selection" : "Show Selection"}
					</Button>
					<div><Form>
						
						<Form.Control
							className="mb-2"
							style={{ backgroundColor: '#D0D8DE', borderColor: '#D0D8DE' }}
							type="input"
							placeholder="optional headline"
							onChange={(e) => setHeadLines({ ...headlines, [selected.order]: e.target.value })}
							value={headlines[selected.order] || ''}
						>
						</Form.Control></Form>
					</div>
					{showSelection &&
						<Alert variant="info" onClose={() => { onClearSummary(); onCloseSelection(selected) }} dismissible>
							{selected.content['text']}
						</Alert>}
					{summaryText && <Alert variant="success">{summaryText.summary}</Alert>}
				</>
			)
		
	}

	const inputTypes = { 1: 'Entire Article', 2: 'Page Range', 3: 'Selected Text' }
	const isDisabled = {'Entire Article': true, 'Page Range': true, 'Selected Text': false}

	return (
		<>
			<Defaultnav />
			<Container fluid>
				<Row>
					<Col className = "mt-3">
						<Button className = "rounded-pill" onClick={() => history.goBack()}> Go Back </Button>
					</Col>
				</Row>
				<Row className="mt-5 mb-3">
					<Col>
						<h1> Document Summarizer  </h1>
						<p className="lead">Create a summary using a specific page, a set of pages, or a selected area of text.
							Multiple seperate selections can be made.
						</p>
						<p>
							Choose your settings and run the inference. The time to run the summary depends on the length of the input.
						</p>
						<p>
							You may also directly save the raw selected text or select and save images from an article. To select an image, hold the
							 <Badge className="m-2 p-2" variant="primary">alt key</Badge> and drag your mouse over the image. 
						</p>
					</Col>
				</Row>
				<Row>
					<Col >
						<Card className="shadow-none border-0 p-2" style={{ boxShadow: '0 0 5px #ffb236' }}>
							<Card.Header className="bg-transparent border-0">
								<h4>Extraction Settings </h4>
								{countOfInferenceTypes > 1 && <Alert variant="warning" className="mt-2">
									Selecting multiple inference types will increase the time it takes to run!
								</Alert>}
							</Card.Header>
							<Card.Body>
								<Row>
									<Col>
										<h5> Select Article </h5>
										<DropDownContainer
											className="bootstrap-demo"
											mode='radioSelect'
											value={selectedArticle.title || null}
											data={fileTree}
											onChange={onChange}
											onAction={onAction}
											onNodeToggle={onNodeToggle}
										/>
									</Col>
								</Row>

								
									<Row>
										<Col>
											<h5 className="mb-2">Summarization Settings</h5>
											<h6>Summary Length Slider</h6>
											<p>Select a value, between -5 and 5 to determine the length of the summary. Smaller values coincide with a shorter summary</p>
											<p>If you select a small amount of text, you should choose a lower number on the slider.</p>
											<Alert className = "m-3" variant = "warning"> Summaries that are duplicating can generally be enhanced by selecting a lower summary length </Alert>
											<RangeSlider
												max={5}
												min={-5}
												step={.5}
												tooltip={'on'}
												value={length}
												variant="primary"
												onChange={changeEvent => setLength(changeEvent.target.value)}
											/>
											<h6>Input Source</h6>
											<Form>
												{Object.keys(inputTypes).map(key => {
													return (
														<Form.Check
															inline
															id={key}
															onChange={(e) => setInputValue(e.target.id)}
															type='radio'
															name='text-input'
															label={inputTypes[key]}
															defaultChecked={!isDisabled[inputTypes[key]]}
															// disabled={isDisabled[inputTypes[key]]}
														/>
													)
												})}

											</Form>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col>
											{/* {(inputValue == 1 && summary.length == 0) ? <Alert variant="info">Entire Article Selected</Alert> : (
												(inputValue == 2 && summary.length == 0) ? <Form.Control label='Pages' placeholder="1-4, 5" onChange={(e) => setPageNums(e.target.value)} /> :
													(selection.length > 0 ? (selection.map(s => displaySelections(s))): 
													<Alert variant="warning">No text has been selected</Alert> )
											)} */}
											{inputValue == 1 && summary.length == 0 && <Alert variant="info">Entire Article Selected</Alert> }
											{inputValue == 2 && summary.length == 0 && <Form.Control label='Pages' placeholder="1-4, 5" onChange={(e) => setPageNums(e.target.value)} />}
											{inputValue == 3 && selection.length == 0 && <Alert variant="warning">No text has been selected</Alert>}
											{inputValue != 3 && summary.length > 0 && <Alert variant="success">{summary[0].summary}</Alert>}
											{inputValue == 3 && selection.length > 0 && selection.length > 0 && selection.map(s => displaySelections(s))}
										</Col>
									</Row>
							
							</Card.Body>
							<Card.Footer className="border-0 bg-white">
								<Button style={{ width: '150px' }} className="rounded-pill"
									onClick={(e) => selection && onClickInference()} 
									disabled={disabledInference}> 
									{fetching ? <Spinner
										as="span"
										variant="light"
										size="sm"
										role="status"
										aria-hidden="true"
										animation="border" /> : "Run Inferences"}
								</Button>
								<Button 
									variant = 'success' 
									onClick = {(e) => onClickSendSummary()}
									className = "ml-3 rounded-pill" 
									disabled = {Object.keys(summary).length === 0}
									>
									Save Inferences
								</Button>
								{timeoutError && 
								<Alert className = "mt-3 mb-3" variant="danger">
									The request timed out or there was an error. Please try again. 
								</Alert>}
							</Card.Footer>
						</Card>
					</Col>
					<Col>
						{url ?
							<PDFTextSelector
								url={url}
								sendImage={sendImage}
								selectedArticle={selectedArticle}
								setSelection={setSelection}
								selection={selection} 
								setTextSelections={setTextSelections}
								textSelections={textSelections}
								sendText={sendText}
								clearSummary={onClearSummary}/> :
							<div style={{ height: '500px' }}>
								<FaFile size="lg" opacity={0.4} color='gray' />
							</div>
						}
					</Col>
				</Row>
				<Row>
					<Col>
						<ModalAlert 
							show={showModal} 
							onHide={onHide}
							text={'Summaries have been successfuly saved!'} 
							header={'Saved Summaries'} 
							// children={<Button as="input" onClick={() => history.goBack()}>Return to Report Builder</Button>}
						/>
					</Col>
				</Row>
			</Container>
		</>

	)


}