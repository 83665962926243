import React, { useState, useEffect } from 'react';
import { API_ENDPOINT } from '../utils/endpoint';
import { Button } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';
export const ImageDiv = ({ image, onContentChange }) => {

	// console.log("imagediv", image)
	const [url, setUrl] = useState(null);

	const getImageUrl = async (image) => {
		// console.log("image", image)
		await fetch(`${API_ENDPOINT}/data/store/images?key=${encodeURIComponent(image)}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
			.then(res => res.json())
			.then(res => {
				// console.log(res.url)
				setUrl(res.url)
			})
	}

	useEffect(() => {
		getImageUrl(image)
	}, [])

	return (
		<div className="mt-2 mb-2 p-3 align-items-center text-center" >
			<div className="align-center">
			
				<img  className="d-block mx-auto" src={url} alt=""></img>
			</div>
			{/* <FaEllipsisH ></FaEllipsisH> */}
			<Button
				className="mt-3 rounded-pill mx-auto align-center "
				variant="success"
				onClick={(e) => onContentChange(
					{
						summary: `<img src=${url}></img>`,
						title: ''
					}
				)
				}
			>
				Add to Report
			</Button>
			{/* <Button variant = "danger" className = "mt-3 ml-2 rounded-pill"> Delete Image </Button> */}
		</div>
	)
}

