import React, { useContext, useState, useEffect, createContext, useCallBack } from "react";
import { API_ENDPOINT } from '../utils/endpoint';
import _ from 'lodash';

export const ManageDataContext = createContext();

export function ManageDataContextProvider({ children }) {

	const [view, setView] = useState('default')
	const [directory, setDirectory] = useState(null)
	const [folderData, setFolderData] = useState(null)
	const [updateTable, setUpdateTable] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [updateDirectory, setUpdateDirectory] = useState(false)
	const [updateFields, setUpdateFields] = useState(false)
	const [showDeleteAllModal, setShowDeleteAllModal] = useState(false)
	const [wasDeleted, setWasDeleted] = useState(null)
	const [data, setData] = useState([]);
	const [showModal, setShowModal] = useState(false)
	const [articleId, setArticleId] = useState(null)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [article, setArticle] = useState({})


	const getFileData = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/folders/folder?folder=${directory}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(response => response.json())
			.then(res => { Object.keys(res).length > 0 ? setData(res) : setData(null) })
	}

	// if a folder was selected, then get the data from that folder
	useEffect(() => {
		
		if (directory) {
			getFileData()
		}
	}, [directory])

	// if the table has been updated by editing or deleting, update the data 
	useEffect(() => {
		if (updateTable && directory) {
			getFileData()
			setArticleId(null)
		}
	}, [updateTable, directory])

	// if the modal is closed reset the article id that is selected
	useEffect(() => {
		if (!showModal) {
			setArticleId(null)
		}
	}, [articleId])
	
	//fetch the data for the folders and their children + content stats whenever the viewchanges, the directory is updated
	useEffect(() => {
		const getData = async () => {
			const token = localStorage.getItem('token')
			await fetch(`${API_ENDPOINT}/data/folders`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
			})
				.then(response => response.json())
				.then(res => {
					if (Array.isArray(res)) {
						let orderedRes = res.map(r => {
							return {
								'Directory': r.Directory,
								'Tags': r.Tags,
								'parent': r.parent,
								'isChild': r.isChild,
								'child': r.child,
								'Count of Files': r['Count of Files']
							}
						})
						setFolderData(_.orderBy(orderedRes, ['Directory', 'child', 'Count of Files'], ['asc', 'desc']))
						setIsLoading(false)
						setUpdateDirectory(false)
					}
				})
		}
		getData()
	}, [view, updateDirectory, isLoading])

	return (
		<ManageDataContext.Provider
		  value={{
			  data, 
			  setData, 
			  showModal, 
			  setShowModal, 
			  articleId, 
			  setArticleId,
			  showDeleteModal, 
			  setShowDeleteModal, 
			  article,
			  setArticle,
			  view, 
			  setView, 
			  directory, 
			  setDirectory, 
			  folderData, 
			  setFolderData, 
			  updateTable, 
			  setUpdateTable, 
			  isLoading,
			  setIsLoading,
			  updateDirectory, 
			  setUpdateDirectory, 
			  updateFields, 
			  setUpdateFields,
			  showDeleteAllModal, 
			  setShowDeleteAllModal,
			  wasDeleted,
			  setWasDeleted
		  }}
		>
		  {children}
		</ManageDataContext.Provider>
	  );
}

export function useManageDataProvider() {
	const context = useContext(ManageDataContext);
	if (context === undefined) {
	  throw new Error("Context must be used within a Provider");
	}
	return context;
  }