import React from 'react'; 
import {Container, Form, Col, Row, Button, Card, Alert} from 'react-bootstrap'
import { Defaultnav } from '../components/navbar';
export const FeedbackPage = (props) => {
	return (
		<>
		<Defaultnav />
		<Container >
			
			<Row className = "mt-5">
				<Col>
					<Card className = "border-0 shadow">
						<Card.Header className = "border-0 bg-white">
							<h3>Feedback Form</h3>
						</Card.Header>
						<Card.Body>
							<p className = "lead">
								Please enter your feedback in the below form. Select the appropriate category based on the guidelines below. 
							</p>
							<Alert className = "border-0" variant="info">
							<p> 
								<ul>
									<li>
										<strong>Account Issues: </strong> Unable to change your password, login issues, etc. 
									</li>
									<li>
										<strong>Bug: </strong> An issue or error in the application. This can be as simple as a broken link, or an entire page is not loading.
									</li>
									<li>
										<strong>Incorrect Content: </strong> Something about the content is not accurate (e.g., wrong title for a file, incorrect tags).
									</li>
									<li>
										<strong>Feature Request: </strong> A request for a new feature, or a way to improve an existing feature.
									</li>
									<li>
										<strong>Other:</strong>Any other type of feedback you may have.
									</li>
								</ul>
							</p>
							</Alert>
							<p> 
								<strong>For all feedback, please be as specific as possible. We will do our best to provide a response to your feedback when applicable. </strong>
							</p>
							<Form>
								<Form.Group>
								<Form.Label>
									Feedback Subject 
								</Form.Label>
								<Form.Control type="input" placeholder="Feedback Subject"/>
								<Form.Label className = "mt-2">
									Feedback Message
								</Form.Label>
								<Form.Control as="textarea" rows={5} placeholder="Feedback Message"/>
								<Button className = "mt-3 rounded-pill" type="submit" >Submit</Button>
								</Form.Group>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
		</>
	)
}
