import React, { useEffect, useState } from 'react';
import {
	Form,
	Button,
	Container,
	Alert,
	Modal,
	Row,
	Col
} from 'react-bootstrap';
import auth from '../auth/auth';
import { Defaultnav } from '../components/navbar';
import { SimpleTable } from '../components/table';
import { API_ENDPOINT } from '../utils/endpoint';
import { FaLock } from 'react-icons/fa';
import generator from "generate-password";
import { AdminTable } from '../components/admintable';

export const Admin = () => {

	const [first, setFirst] = useState(null)
	const [last, setLast] = useState(null)
	const [email, setEmail] = useState(null)
	const [password, setPassword] = useState(null)
	const [passwordConfirmation, setPasswordConfirmation] = useState(null)
	const [users, setUsers] = useState([])
	const [modal, openModal] = useState(false)
	const [updateTable, setUpdateTable] = useState(false)

	const [user, setUser] = useState(null);


	const makePassword = () => {
		var password = generator.generate({
			length: 10,
			numbers: true
		})

		return password
	}

	// Fetch user information on page load
	useEffect(() => {
		const fetchData = async () => {
			if (auth.isAuthenticated()) {
				const result = await auth.getUser();
				setUser(result);
			} else {
				setUser(null)
			};
		};
		fetchData();
		// eslint-disable-next-line
	}, []);


	const sendEmail = async (emailinfo) => {
		//console.log(emailinfo)
		await fetch(`${API_ENDPOINT}/email`, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(emailinfo),
		}).then(d => console.log(d));

	};

	const onSubmit = async (e) => {
		e.preventDefault()
		const info = {
			"email": email,
			"password": makePassword(),
			"firstName": first,
			"lastName": last
		}
		const emailInfo = {
			"email": [info.email], 
			"body": {
				"first": info.first, 
				"username": info.email, 
				"password": info.password
			}
		}
		//console.log(info)
		await fetch(`${API_ENDPOINT}/auth/register`, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(info),
		}).then(d => {
			sendEmail(emailInfo)
			openModal(false)
			setUpdateTable(prevState => !prevState)
		})

	}


	return (
		<>
			<Defaultnav />
			{user && user.is_superuser && <Container className="mt-2">
				<div style={{ textAlign: 'right' }}>
					<Button className="mt-3 mb-2 rounded-pill" onClick={(e) => openModal(true)} >Add User</Button>
				</div>
				{/* //<SimpleTable data={users} shadow={true} editable={false} /> */}
				<AdminTable key = {updateTable} updateTable={updateTable} setUpdateTable={setUpdateTable}/>
				<Modal show={modal} onHide={(e) => openModal(false)}>
					<Modal.Header className="border-0 bg-white" closeButton>
						<h2 >Add User</h2>
					</Modal.Header>
					<Modal.Body>
						<Alert variant={'info'}>Password will be generated automatically and sent to the user. No one aside from the user will have access to the password.</Alert>
						<Form onSubmit={onSubmit}>
							<Row>
								<Col>
									<Form.Label> First Name</Form.Label>
									<Form.Control type="first" placeholder="First Name" value={first} onChange={(e) => setFirst(e.currentTarget.value)} />
								</Col>
								<Col>
									<Form.Label> Last Name</Form.Label>
									<Form.Control type="last" placeholder="Last Name" value={last} onChange={(e) => setLast(e.currentTarget.value)} />
								</Col>
							</Row>
							<Form.Group >
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
							</Form.Group>
							{/* <Form.Group>
								<Form.Label>Password</Form.Label>
								<Form.Control type="password" placeholder="Enter password" value={password} onChange={(p) => setPassword(p.currentTarget.value)} />
								<Form.Label>Password Confirm</Form.Label>
								<Form.Control type="password" placeholder="Enter password" value={passwordConfirmation} onChange={(p) => setPasswordConfirmation(p.currentTarget.value)} />
							</Form.Group> */}
							<Button className="btn rounded-pill" variant="primary" type="submit" block>
								Add User
							</Button>
						</Form>
					</Modal.Body>

				</Modal>
			</Container>}
			{user && (!user.is_superuser || user.email !== 'Matt.Gray@ec3co.com') && <Container><div className="lock"></div>
				<div className="message">
					<h1>Access to this page is restricted</h1>
					<FaLock color={'#ffb236'} size={100}></FaLock>
					<p className="mt-2">Please check with the site admin if you believe this is a mistake.</p>
				</div></Container>}
		</>
	)
}