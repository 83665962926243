import React, { useEffect, useState } from 'react';
import { hrinfo, alloptions } from "../utils/hr";
import { Modal, Col, Row, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';
import { UpdateUser } from '../auth/manageuser';

export const EditUserModal = ({ user, setUpdateTable, showModal, setShowModal }) => {

	const [userInfo, setUserInfo] = useState({})
	const [selectedDepartment, setSelectedDepartment] = useState(null)
	const [roleOptions, setRoleOptions] = useState({})
	const [userUpdates, setUserUpdates] = useState({})

	useEffect(() => {
		setUserInfo(user)
		setRoleOptions(alloptions)
	}, [user])

	const onSubmit = () => {
		//console.log("update", userUpdates)
		UpdateUser(userInfo.ID, userUpdates)
		setShowModal(false)
		setUpdateTable(prevState => !prevState)
	}

	const onHide = () => {
		setShowModal(false)
	}

	const changeHandler = (e) => {
		let info = userInfo 
		info[e.target.name] = e.target.value 
		setUserInfo(info)
		setUserUpdates({...userUpdates, [e.target.name]: e.target.value})
	}

	const departmentOptions = Object.keys(hrinfo).map(key => {
		return {
			value: key,
			label: key
		}
	})

	useEffect(() => {
		if (selectedDepartment) {
			const newRoleOptions = hrinfo[selectedDepartment].Role.map(role => {
				return {
					value: role,
					label: role
				}
			})
			if (selectedDepartment === 'Americas') {
				setRoleOptions(newRoleOptions)
			} else {
				setRoleOptions(_(alloptions).concat(newRoleOptions).groupBy('label').map(_.last).value())
			}
		}

	}, [selectedDepartment])

	const deptChangeHandler = (option) => {
		setSelectedDepartment(option.value)
		let info = userInfo 
		info['Department'] = option.value 
		setUserInfo(info)
		setUserUpdates({...userUpdates, 'department': option.value})

	}

	const roleChangeHandler = (option) => {
		let info = userInfo 
		//console.log(info)
		info['Role'] = option.value 
		setUserInfo(info)
		setUserUpdates({...userUpdates, 'role': option.value})
	}
	return (
		<Modal
			size='lg'
			show={showModal}
			onHide={onHide}
			backdrop="static"
			keyboard={false}>
			<Modal.Header
				closeButton
				className="border-0">
				<Modal.Title>Edit User</Modal.Title>
			</Modal.Header>
			{Object.keys(userInfo).length > 0 && <Modal.Body >
				<Form.Group controlId="formFile" className="mb-3" >
					<Row>
						<Col>
							<Form.Label>Email</Form.Label>
							<Form.Control 
								name='email'
								value={userInfo.email}
								onChange={changeHandler}
								type="input" />
						</Col>
						<Col className="col-2">
							<Form.Label> First Name </Form.Label>
							<Form.Control
								name="firstname"
								type="input"
								defaultValue={userInfo.firstname}
								onChange={changeHandler}
							>
							</Form.Control>
						</Col>
						<Col className="col-2">
							<Form.Label> Last Name </Form.Label>
							<Form.Control
								name="lastname"
								onChange={changeHandler}
								defaultValue={userInfo.lastname}
								as="input"
							></Form.Control>
						</Col>
					</Row>
					<Row>
						<Col >
							<Form.Label>Department</Form.Label>
							<Select
								name='department'
								defaultValue={{label: userInfo.department, value: userInfo.department}}
								options={departmentOptions}
								onChange={deptChangeHandler}
							/>
						</Col>
						<Col>
							<Form.Label>Role</Form.Label>
							<Select
								name='role'
								defaultValue={{label: userInfo.role, value: userInfo.role}}
								options={roleOptions}
								onChange={roleChangeHandler}
			
							/>
						</Col>
					</Row>
					<Button
						onClick={(e) => onSubmit()}
						type="submit"
						className="mt-3 btn btn-primary rounded-pill">
						Update
					</Button>
				</Form.Group>
			</Modal.Body>}
		</Modal>
	)


}

