import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class BasicBarChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data, 
			x: props.x, 
			y: props.y
		} 
	}

	render() {
		return (
		  <ResponsiveContainer width="99%" height = "99%">
			<BarChart
			  width={500}
			  height={300}
			  data={this.state.data}
			  margin={{
				top: 5,
				right: 30,
				left: 20,
				bottom: 5,
			  }}
			>
			  {/* <CartesianGrid strokeDasharray="3 3" /> */}
			  <XAxis dataKey={this.state.x} />
			  <YAxis tickFormatter={(tick) => {
				return `${(tick * 100).toFixed(2)}%`;
				}}/>
			  <Tooltip />
			  {/* <Legend /> */}
			  <Bar dataKey={this.state.y} fill="#0032A0" />
			</BarChart>
		  </ResponsiveContainer>
		);
	  }
	}