import React from 'react';
import { Defaultnav } from '../components/navbar';
import { Container, Col, Row, Button, Spinner, Breadcrumb, Alert } from 'react-bootstrap'
import { DirectoryModal, FormModal } from '../components/modal';
import { DirectoryTable } from '../components/directorytable'
import _ from 'lodash';
import { Footer } from '../components/footer';
import DefaultSpinner from '../components/spinner'
import { useManageDataProvider } from '../contexts/manageDataContext';
import { useHistory } from 'react-router-dom';

export const Data = (props) => {

	const history = useHistory();

	const {
		setView,
		setDirectory,
		folderData,
		isLoading,
		setUpdateDirectory,
		wasDeleted,
		setWasDeleted } = useManageDataProvider()


	const clickDirectory = (e) => {
		setView('directory')
		setDirectory(e.target.id)
		setWasDeleted(null)
		history.push("/files")
	}

	return (
		<>
			<Defaultnav />
			<Container>
				{folderData && !isLoading ?
					<>
						<Row className="pt-4">
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
									<Breadcrumb.Item>
										Directory
									</Breadcrumb.Item>
								</Breadcrumb>
								{wasDeleted && 
								<Alert variant={'danger'} onClose={(e) => setWasDeleted(null)} dismissible> 
									The {wasDeleted} directory was successfully removed 
								</Alert>}
								<DirectoryModal setUpdateDirectory={setUpdateDirectory} parent={null} title={'Add Directory'}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<DirectoryTable 
									clickDirectory={clickDirectory}
									data={folderData}
								/>
							</Col>
						</Row>
					</>
					: <DefaultSpinner />}
			</Container>
			<Footer />
		</>
	)
}