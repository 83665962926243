import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Navbar, Nav, NavLink, Container, Row, Col, Alert, Button, Card, NavDropdown } from 'react-bootstrap';
import { FaDatabase, FaSearch, FaSearchengin, FaQuestionCircle, FaSearchPlus, FaUserCircle } from 'react-icons/fa';
import auth from '../auth/auth';
import newPageClear from '../utils/clear';

export const Defaultnav = (props) => {
	// History hook
	const history = useHistory();
	const [show, setShow] = useState(true);

	// User information hook
	const [user, setUser] = useState({
		"id": "",
		"email": "",
		"is_active": true,
		"is_superuser": false,
		"firstName": "",
		"lastName": ""
	});

	const permissions = localStorage.getItem('permissions')

	useEffect(() => {
		const fetchData = async () => {
			if (auth.isAuthenticated()) {
				const result = await auth.getUser();
				setUser(result);
			} else {
				setUser(null)
			};
		};
		fetchData();
		// eslint-disable-next-line
	}, []);

	// Function to call logout
	const callLogout = async () => {
		auth.logout(() => {
			history.push('/');
		});
	};

	const date = new Date().toDateString()

	const logoImage = require("../images/marmon foodservice technologies logo_reverse.png")

	return (
		<>
			<Navbar className="align-middle justify-content-between" bg="primary" variant="primary">
				<Container fluid>
					<Navbar.Brand href="/" onClick={newPageClear}>
						<img
							alt=""
							src={logoImage.default}
							width="300"
							height="60"
							
							className="d-inline-block align-top"
							// style={{ filter: 'brightness(0) invert(1)' }}
						/>
						{/* <Navbar.Brand className="pl-3 ml-1 pr-5" style={{ color: '#fff' }}> Marketing Insights</Navbar.Brand> */}
					</Navbar.Brand>
					{permissions && <Nav className="mr-auto">
						<Nav.Link className="text-white" href="/" onClick={() => newPageClear()}>Home</Nav.Link>
						<Nav.Link className="text-white" href="/search" onClick={() => newPageClear()}>Advanced Search</Nav.Link>
						<Nav.Link className="text-white" href="/help" onClick={() => newPageClear()}>Help Center</Nav.Link>
						{/* {permissions && permissions == 'admin' && <Nav.Link className="text-white" href="/managedata" onClick={() => newPageClear()}> Manage Data</Nav.Link>} */}
						{/* {permissions && permissions == 'admin' && <Nav.Link className="text-white" href="/admin" onClick={() => newPageClear()}>Admin</Nav.Link>} */}
						{permissions && permissions == 'admin' && 
						<NavDropdown 
							variant='dark' 
							className = "text-white" 
							title="Admin" 
							id="nav-dropdown" 
							style={{color: 'white'}}>
							<NavDropdown.Item href="/managedata">Manage Data</NavDropdown.Item>
							<NavDropdown.Item href="/reportbuilder">Report Builder</NavDropdown.Item>
							{/* <NavDropdown.Item href="/inference">Machine Learning</NavDropdown.Item> */}
							<NavDropdown.Divider />
							<NavDropdown.Item href="admin">Manage Users</NavDropdown.Item>
							
						</NavDropdown>}
					</Nav>}

					{user &&
						<div>

							<label className="ml-4 text-white" style={{ color: '#fff' }}><FaUserCircle size={21} /></label>
							{/* <label className="ml-2 text-white">{user.firstName + " " + user.lastName}</label> */}
							<NavDropdown 
							variant='dark' 
							className = "text-white" 
							title={user.firstName + " " + user.lastName}
							id="nav-dropdown" 
							style={{color: 'white', display: "inline-block"}}>
							<NavDropdown.Item href="/resetpassword">Reset Password</NavDropdown.Item>
							{/* <NavDropdown.Item href="/feedback">Leave Feedback</NavDropdown.Item> */}
							{/* <NavDropdown.Item href="/inference">Machine Learning</NavDropdown.Item> */}
							<NavDropdown.Divider />
							<NavDropdown.Item href="/help" ><FaQuestionCircle></FaQuestionCircle>  Help</NavDropdown.Item>
							
						</NavDropdown>
							{/* <label className="ml-4" style={{ color: "#ffb236" }}>{date}</label> */}
							<Button className="ml-4 rounded-pill" 
							style={{ background: 'transparent', color: 'white', border: '1px solid white' }} 
							onClick={callLogout}>Log Out</Button>
						</div>
					}
				</Container>
			</Navbar>
		</>)
}