import React, { useState, useEffect } from 'react';
import { Defaultnav } from '../components/navbar';
import { Card, Container, Col, Row, Button, Spinner, Breadcrumb, Alert } from 'react-bootstrap'
import { CrudTable } from '../components/filetable';
import axios from '../utils/axiosInterceptor';
import _ from 'lodash';
import { AddFields } from '../components/fieldlist';
import { API_ENDPOINT } from '../utils/endpoint';
import { DeleteAllModal } from '../components/delete';
import { Footer } from '../components/footer';
import DefaultSpinner from '../components/spinner'
import { useManageDataProvider } from '../contexts/manageDataContext';
import { EditArticleModal, FormModal } from "../components/modal";
import { DeleteModal } from "../components/delete";
import { useHistory } from "react-router-dom"

export const FilePage = () => {


	const {
		data,
		showModal,
		setShowModal,
		articleId,
		setArticleId,
		showDeleteModal,
		setShowDeleteModal,
		article,
		setArticle,
		directory,
		setDirectory,
		setIsLoading,
		setUpdateTable,
		setShowDeleteAllModal,
		showDeleteAllModal,
		setWasDeleted,
		updateTable,
		setView } = useManageDataProvider()

	const history = useHistory();

	const returnView = (e) => {
		setView('default')
		setIsLoading(true)
		history.push("/managedata")
	}

	return (
	<>
		<Defaultnav />
			{!directory ? history.push('/managedata') :
				<Container>
					<Row className="mt-3">
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="/managedata">
									Directory
								</Breadcrumb.Item>
								{directory.split('/')[1] ? <><Breadcrumb.Item onClick={() => setDirectory(directory.split('/')[0])}>{directory.split('/')[0]}</Breadcrumb.Item> <Breadcrumb.Item>{directory.split('/')[1]}</Breadcrumb.Item></> :
								<Breadcrumb.Item>{directory}</Breadcrumb.Item>}
								{/* <Breadcrumb.Item active>
									{directory.split('/')[1] ? directory.split('/')[0] + ' / ' + directory.split('/')[1] : directory}
								</Breadcrumb.Item> */}
							</Breadcrumb>
							<Button
								onClick={returnView}
								className="rounded-pill"> Go Back </Button>
							<FormModal
								setUpdateTable={setUpdateTable}
								directory={directory} />
							<AddFields />
							<Button
								className="mr-2 float-right rounded-pill"
								variant={"danger"}
								onClick={(e) => setShowDeleteAllModal(true)}>
								Delete Directory
							</Button>
							<DeleteAllModal
								setWasDeleted={setWasDeleted}
								setView={setView}
								showDeleteAllModal={showDeleteAllModal}
								setShowDeleteAllModal={setShowDeleteAllModal}
								directory={directory}
							></DeleteAllModal>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>
							<Card>
								<Card.Body>
									<EditArticleModal
										setUpdateTable={setUpdateTable}
										id={articleId}
										modalState={showModal}
										setModalState={setShowModal}
										key={articleId}
									/>
									<DeleteModal
										setShowDeleteModal={setShowDeleteModal}
										article={article}
										showDeleteModal={showDeleteModal}
										setUpdateTable={setUpdateTable}
									/>
									<CrudTable
										key={updateTable}
										data={data}
										setShowModal={setShowModal}
										setArticleId={setArticleId}
										setShowDeleteModal={setShowDeleteModal}
										setArticle={setArticle} />
								</Card.Body>
							</Card>
						</Col>
					</Row>
				<Footer />
		</Container>}
	</>
	)

}
