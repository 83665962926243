import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Alert, Modal, Row, Col, Spinner } from 'react-bootstrap';
import auth from './auth';
import { ModalAlert } from '../components/toast';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import DefaultSpinner from '../components/spinner'; 

export const Register = () => {
  // History hook
  const history = useHistory();

  // User information hook
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [confirm, setConfirm] = useState(false);


  // Function to call submit
  const callSubmit = async (e) => {
    // Prevents page reload on wrongs creds
    e.preventDefault();
    setError('');
    try {
      const data = await auth.register(firstName, lastName, email, password, passwordConfirmation);
      // Executes only when there are no 400 and 500 errors, else they are thrown as errors
      // Callbacks can be added here
      if (data) {
        setConfirm(true);
        await auth.login(email, password);
        setTimeout(() => { history.push('/'); }, 5000);
      }
    }
    catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      }
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  const search = window.location.search 
  const params = new URLSearchParams(search) 
  const access_token = params.get("access_token")
  const onHide = () => { setConfirm(false) }

  const confirm_token = "TZqwPkd9jg7ztNwAmTtrs4MFaXgZRK"

  return (
    <>
      <Modal

        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        show={confirm}
        // onHide={onHide}
        variant="success"
      >
        <Modal.Header style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1f9e6c",
        }} className="border-0 ">
          <FaCheck color={'white'} size={70}></FaCheck>
        </Modal.Header>
        <Modal.Body>
          <h2><strong >Success!</strong></h2>
          <br></br>
          <p className="lead"> You will be redirected to the application momentarily.</p>
        </Modal.Body>
        <Modal.Footer className = "align-items-center">
          <Spinner className = "mx-auto" animation="border" role="status"></Spinner>
        </Modal.Footer>
      </Modal>
      <Form onSubmit={callSubmit} >
        <Form.Group controlId="formRegisterFirstname">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={(f) => setFirstName(f.currentTarget.value)} />
        </Form.Group>
        <Form.Group controlId="formRegisterLastname">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" placeholder="Enter last name" value={lastName} onChange={(l) => setLastName(l.currentTarget.value)} />
        </Form.Group>
        <Form.Group controlId="formRegisterEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
        </Form.Group>
        <Form.Group controlId="formRegisterPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Enter password" value={password} onChange={(p) => setPassword(p.currentTarget.value)} />
        </Form.Group>
        <Form.Group controlId="formRegisterPasswordConfirmation">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type="password" placeholder="Confirm password" value={passwordConfirmation} onChange={(p) => setPasswordConfirmation(p.currentTarget.value)} />
        </Form.Group>
        <Alert variant='danger' style={error !== '' ? { display: "block" } : { display: "none" }}>
          {error}
        </Alert>
        <Button className = "rounded-pill" size={'sm'} variant="primary" type="submit" disabled={access_token != confirm_token}>
          Register
        </Button>
      </Form>
    </>
  );
};
