import _ from 'lodash'

export const formatTypeFields = (field) => {
	if (field.includes('type')) {
		let fieldname = field.split('type')[0]
		let fieldCap = fieldname.slice(0, 1).toUpperCase() + fieldname.slice(1, fieldname.length)
		let formattedField = fieldCap + ' Type'
		return formattedField
	} else if (field.includes('_')) {
		let fieldname = field.replaceAll('_', ' ') 
		let fieldCap = fieldname.slice(0, 1).toUpperCase() + fieldname.slice(1, fieldname.length) 
		return fieldCap
	} else if (field === 'outlet') {
		return 'Channel Segment'
	}else {
		return field.slice(0, 1).toUpperCase() + field.slice(1, field.length)
	}
}

export const titleCase = (str) => _.map(str.split(" "), _.capitalize).join(" ");