import React from 'react';
import { Container, Row, Col, Alert, Card } from 'react-bootstrap';
import { Login } from '../auth/login';
import { Register } from '../auth/register';
import { Defaultnav } from '../components/navbar';

export const Landing = (props) => {
  return (
    <>
      <Defaultnav />
      <Container className="mt-4 ">
        <Row>

          <Col className="mt-4 ml-4 mr-4 ">
           <Card className = "mx-auto p-5 smallwidth"> <Login {...props}/> </Card>
          </Col>

          {/* <Col lg={6} md={6} sm={12} className="mt-4">
            <Register {...props}/>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};
