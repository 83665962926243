import React, { useState, useEffect } from 'react';
import { SearchBar } from '../components/search';
import {
	Spinner,
	Container,
	Row,
	Col,
	Card,
	Accordion,
	Button,
	Form,
	Navbar,
	Alert,
	Breadcrumb
} from 'react-bootstrap';
import Select from 'react-select'
import { Defaultnav } from '../components/navbar';
import { Reportcard } from '../components/report';
import { FaFilter, FaFolderOpen, FaSearchengin } from 'react-icons/fa';
import { ContextAwareToggle } from '../utils/toggle';
import { formatTypeFields } from '../utils/formatter';
import _ from 'lodash';
import { API_ENDPOINT } from '../utils/endpoint';
import newPageClear from '../utils/clear';
import { NewsCard } from '../components/newscard';
import moment from 'moment';
import { customSelect, limitSelect } from '../lib/style';

export const Search = () => {

	const [search, setSearch] = useState(localStorage.getItem('search') || "''")
	const [results, setResults] = useState([])
	const [submit, setSubmit] = useState(localStorage.getItem('wasSearched') || false)
	const [searchType, setSearchType] = useState(localStorage.getItem('searchtype') || 'text')
	const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters")) || {'type': ['PDF', 'Excel', 'Word', "Powerpoint"]})
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [fields, setFields] = useState([])
	const [includeKeywords, setIncludeKeywords] = useState(true)
	const [suggestions, setSuggestions] = useState([])
	const [types, setTypes] = useState([])
	const [returned, setReturned] = useState(false)
	const [loading, setLoading] = useState(false)
	const [limit, setLimit] = useState(localStorage.getItem('limit') || 10)
	const [sort, setSort] = useState(JSON.parse(localStorage.getItem('sort')) || {value: 'publish', label: 'Most Recent' })
	const [searchSource, setSearchSource] = useState(localStorage.getItem('search_source') || 'internal_search')
	const [totalResultCount, setTotalResultCount] = useState(0)

	const addType = (type) => {
		const currentTypes = filters['type'] || []
		if (currentTypes.length === 0) {
			let oldFilter = filters 
			oldFilter['type'] = [type]
			setFilters(oldFilter)
		} else if (currentTypes.includes(type)) {
			let oldFilter = filters
			oldFilter['type'] = oldFilter['type'].filter(t => t != type) 
			setFilters(oldFilter)
		} else {
			let oldFilter = filters 
			oldFilter['type'] = [...oldFilter['type'], type]
			setFilters(oldFilter)
			
		}
	}

	const limitOptions = [
		{ value: 10, label: '10' },
		{ value: 20, label: '20' },
		{ value: 30, label: '30' },
		{ value: 40, label: '40' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: 200, label: '200' }
	]


	const changeHandler = (options, action) => setFilters(prevState => {
		setSubmit(true)
		setLoading(true)
		if (options.length > 0) {
			return {
				...prevState,
				[action.name]: options.map(o => o.value)
			}
		} else {
			const newState = _.omit(prevState, action.name)
			return newState
		}

	})

	useEffect(() => {
		localStorage.setItem('search_source', searchSource)
	}, [searchSource])

	useEffect(() => {
		localStorage.setItem('search', search)
	}, [search])

	useEffect(() => {
		localStorage.setItem('filters', JSON.stringify(filters))
	}, [filters])

	useEffect(() => {
		localStorage.setItem('wasSearched', returned)
	}, [returned])

	useEffect(() => {
		localStorage.setItem("limit", limit)
	}, [limit])

	useEffect(() => {
		localStorage.setItem("searchtype", searchType)
	}, [searchType])

	useEffect(() => {
		localStorage.setItem('sort', JSON.stringify(sort))
	}, [sort])

	useEffect(() => {
		const fetchFields = async () => {
			await fetch(`${API_ENDPOINT}/fields/tags`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(res => { setFields(res.sort()) })
		}
		const fetchSearches = async () => {
			await fetch(`${API_ENDPOINT}/searches`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(res => setSuggestions(res))
		}

		const fetchTypes = async () => {
			await fetch(`${API_ENDPOINT}/fields/datatypes`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(res => setTypes(res))
		}
		fetchFields();
		fetchSearches();
		fetchTypes();
	}, [])

	useEffect(() => {
		if (search === '') {
			setSearch("''")
		}
	}, [search])

	const DocTypes = types.map(t => {
		return { value: t, label: t }
	})

	const returnFieldOptions = (v) => {
		const opts = fields.length !== 0 &&
			fields.filter(f => f.field === v)[0]['values'].map(field => { return { value: field, label: field } })
		return opts
	}

	const sortOptions = [
		{ value: 'num_of_occ', label: 'Relevance' },
		{ value: 'publish', label: 'Most Recent' },
		{ value: 'Views', label: 'Most Viewed' }
	]

	const handleChange = (e) => {
		e.preventDefault();
		setSearch(e.target.value);
		let oldFilter = filters 
		_.omit(filters, 'advancedSearch');
		setFilters(oldFilter); 
		setSubmit(false)
		setReturned(false)
	}

	const clearSearch = () => {
		setSearch('')
		document.getElementById('enddate').value = "";
		document.getElementById('startdate').value = ""
		setSubmit(false)
		setReturned(false)
		setFilters({})
		newPageClear()
	}

	const returnWebResults = async (search) => {
		// let filter = Object.keys(filters).length > 0 ? filters.tags.join(",") : null
		await fetch(`${API_ENDPOINT}/scrapes/search?query=${search}&typeofsearch=${searchType.toLowerCase()}&limit=${limit}&sort=${sort.value}`, {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			body: JSON.stringify(filters),
		}).then(res => res.json())
			// .then(res => console.log("search for data:", res))
			.then(res => {
				setResults(res)
				setReturned(true)
				setSubmit(false)
				setLoading(false)
			})
	}

	const returnResults = async (search, filters, includeKeywords) => {
		setLoading(true)
		const token = localStorage.getItem('token')
		const typeOfSearch = searchType.toLowerCase()
		const searchString = includeKeywords ? search : "''"
		const sorter = sort.value
		await fetch(`${API_ENDPOINT}/data/search/filters/${typeOfSearch}/${searchString}/${limit}/${sorter}`, {
			method: 'POST',
			body: JSON.stringify(filters),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(res => res.json())
			.then(results => {
				setResults(results)
				setReturned(true)
				setSubmit(false)
				setLoading(false)
			})
	}

	const getTotalResults = (r1, r2) => {
		if (r1.length > 0 && r2.length > 0 ) {
			setTotalResultCount(r1[0].resultcount + r2[0].resultcount)
		} else if (r1.length > 0 && r2.length == 0) {
			setTotalResultCount( r1[0].resultcount)
		} else if ( r1.length == 0 && r2.length > 0) {
			setTotalResultCount( r2[0].resultcount)
		} else {
			setTotalResultCount(0)
		}
	}

	const returnAllResults = async () => {
		setLoading(true)
		const individualLimit = limit / 2
		const token = localStorage.getItem('token')
		const typeOfSearch = searchType.toLowerCase()
		const searchString = includeKeywords ? search : "''"
		const sorter = sort.value
		// let filter = filters.tags ? filters.tags.join(",") : null || null
		await fetch(`${API_ENDPOINT}/data/search/filters/${typeOfSearch}/${searchString}/${individualLimit}/${sorter}`, {
			method: 'POST',
			body: JSON.stringify(filters),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(res => res.json())
			.then(res1 => {
				fetch(`${API_ENDPOINT}/scrapes/search?query=${search}&typeofsearch=${searchType.toLowerCase()}&limit=${individualLimit}&sort=${sort.value}}`, {
					headers: { 'Content-Type': 'application/json' },
					method: 'POST',
					body: JSON.stringify(filters),
				}).then(res => res.json())
					// .then(res => console.log("search for data:", res))
					.then(res2 => {
						setResults(_.orderBy(_.concat(res2, res1), 'publishdate', 'desc'))
						getTotalResults(res1, res2)
						setReturned(true)
						setSubmit(false)
						setLoading(false)
					})
			})

		// setResults(_.orderBy(_.concat(webResults, internalResults), 'publishdate'))
	}

	// New Method
	const returnSemanticResults = async (search, filters, includeKeywords) => {
		setLoading(true)
		const token = localStorage.getItem('token')
		// const typeOfSearch = searchType.toLowerCase()
		const searchString = includeKeywords ? search : "''"
		const sorter = sort.value
		await fetch(`${API_ENDPOINT}/data/search/filters/semantic/${searchSource}/${searchString}/${limit}/${sorter}`, {
			method: 'POST',
			body: JSON.stringify(filters),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(res => res.json())
			.then(results => {
				console.log(results)
				setResults(results)
				setReturned(true)
				setSubmit(false)
				setLoading(false)
			})
	}

	useEffect(() => {

		// if (submit && !disabledSearch) {
		// 	returnSemanticResults(search, filters, includeKeywords)
		// }
		if (submit && !disabledSearch && searchSource === 'internal_search') {
			returnResults(search, filters, includeKeywords)
		} else if (submit && !disabledSearch && searchSource === 'external_search') {
			returnWebResults(search);
		} else if (submit && !disabledSearch && searchSource === 'all_sources_search') {
			returnAllResults();
		}
	}, [search, submit, searchSource])


	const nullScreen = () => {

		return (
			<div className="mt-5" style={{ 'textAlign': 'center' }}>
				<FaFolderOpen size={200} color="#eeeeee"></FaFolderOpen>
			</div>
		)
	}

	const updateDates = (e) => {
		let date = e.target.value || null
		if (e.target.name === 'start') {
			setStartDate(date)
			if (date) {
				setFilters(prevState => ({ ...prevState, ['startdate']: date }))
			} else {
				setFilters(prevState => _.omit(prevState, 'startdate'))
			}
		} if (e.target.name === 'end') {
			setEndDate(date)
			if (date) {
				setFilters(prevState => ({ ...prevState, ['enddate']: date }))
			} else {
				setFilters(prevState => _.omit(prevState, 'enddate'))
			}
		}
	}


	const errorBoth =
		_.has(filters, 'startdate') && !_.has(filters, 'enddate') || _.isNull(filters['enddate'])
	_.has(filters, 'enddate') && !_.has(filters, 'startdate') || _.isNull(filters['startdate'])


	const errorMismatch = _.has(filters, 'startdate') && _.has(filters, 'enddate') && new Date(filters['startdate']).getTime() > new Date(filters['enddate']).getTime()

	const error = errorBoth || errorMismatch

	const disabledSearch = Object.keys(filters).length === 0 && (search === null || search === "''") && sort.value === 'num_of_occ' || error

	const handleKeyPress = e => {
		//it triggers by pressing the enter key
		if (e.keyCode === 13) {
			if (!disabledSearch) {
				setSubmit(true);
			}
		}
	};
	return (
		<>
			<Defaultnav />
			<Container >
				<Row className="mt-5">
					<Breadcrumb>
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item href="/search">
							Advanced Search
						</Breadcrumb.Item>
					</Breadcrumb>
				</Row>
				<Row>
					<Col className="ml-4 mb-4">
						<h4>Advanced Search</h4>
					</Col>
				</Row>
				<Row className="ml-0 mr-2">
					<Col className="pr-0" >
						<Navbar className=" border-0 card-no-shadow" >
							<Col>
								<Card className="" >
									<Card.Header className="border-0 bg-transparent">
										<FaFilter className='mr-2'></FaFilter>Filter and Sort
									</Card.Header>
									<Card.Body>
										<Row>
											<Col>
												<Form.Label>Limit Results</Form.Label>
												<Select
													options={limitOptions}
													styles={limitSelect}
													defaultValue={limitOptions[1]}
													value={{ value: limit, label: limit }}
													onChange={(options, e) => setLimit(options.value)} />
											</Col>
											<Col>
												<Form.Label> Sort By </Form.Label>
												<Select
													name='sort'
													closeMenuOnSelect={true}
													onChange={(o, a) => setSort(o)}
													options={sortOptions}
													value={sort}
													// isDisabled={searchSource !== 'internal_search'}
												/>

											</Col>
										</Row>
										<Row className="mt-1">
											<Col>
												<Form.Label> Published From </Form.Label>
												<Form.Control
													id='startdate'
													name="start"
													type="date"
													max={filters['enddate'] || null}
													value={filters['startdate'] || null}
													onChange={updateDates}
													isInvalid={error} />
												<Form.Control.Feedback type="invalid">
													{errorMismatch ? 'Start Date must be before End Date' : 'Publish from and to must both be populated if using date as a filter. Otherwise, clear the field.'}
												</Form.Control.Feedback>
											</Col>
											<Col>
												<Form.Label> Published To </Form.Label>
												<Form.Control
													id='enddate'
													name="end"
													type="date"
													min={filters['startdate'] || null}
													value={filters['enddate'] || null}
													onChange={updateDates}
													isInvalid={error} />
												<Form.Control.Feedback type="invalid">
													{errorMismatch ? 'Start Date must be before End Date' : 'Publish from and to must both be populated if using date as a filter. Otherwise, clear the field.'}
												</Form.Control.Feedback>
											</Col>
										</Row>
										{fields.length > 0 && <Row className = "mt-2">
											<Col>
												<Form.Label>{formatTypeFields(fields[0].field)}</Form.Label>
												<Select
													menuPortalTarget={document.body}
													menuPosition={'fixed'}
													name={fields[0].field}
													closeMenuOnSelect={true}
													closeMenuOnScroll={true}
													isMulti
													onChange={changeHandler}
													options={returnFieldOptions(fields[0].field)}
													value={filters[fields[0].field] ? filters[fields[0].field].map(d => { return { label: d, value: d } }) : null}
												/>
											</Col>
										</Row>
										}
										<Row>
											<Col className="mt-2">
												<hr></hr>
												<strong>Search Type</strong>
												<Form.Check
													// className="ml-2" 
													type="radio"
													onClick={() => {
														setSearchSource('internal_search');
														setSubmit(true);
														setLoading(true);
														setReturned(false)
													}}
													label={<p className = "mb-0">Internal Search<span style={{fontSize: '8pt'}}> (Vendor Articles, Internal Documents)</span></p>}
													checked={searchSource === 'internal_search'} />
												<Form.Check
													// className="ml-2 mr-2" 
													type='radio'
													label={<p className = "mb-0">External Search<span style={{fontSize: '8pt'}}> (Web Results)</span></p>}
													onClick={() => {
														setSearchSource('external_search');
														// clearSearch(); 
														setSubmit(true);
														setLoading(true);
														setReturned(false);
														// setSort({ value: 'publish', label: 'Most Recent' })
													}
													}
													checked={searchSource === 'external_search'} />
												<Form.Check
													// className="ml-2 mr-2" 
													type='radio'
													label={<p>Internal & External Search<span style={{fontSize: '8pt'}}> (All Sources)</span></p>}
													onClick={() => {
														setSearchSource('all_sources_search');
														// clearSearch(); 
														setSubmit(true)
														setLoading(true);
														setReturned(false)
														// setSort({ value: 'publish', label: 'Most Recent' })
													}
													}
													checked={searchSource === 'all_sources_search'} />
											</Col>
										</Row>
										<Row>
											<Col>
												<hr></hr>
												<strong>Document Types</strong>
												{Object.keys(DocTypes).map(key => {
													return (
														<Form.Check
															label={DocTypes[key].value}
															onChange={(e) => addType(DocTypes[key].value)}
															defaultChecked={true}>
														</Form.Check>
													)
												})}
											</Col>
										</Row>
										<Button className="rounded-pill mt-2" onClick={(e) => setSubmit(true)} disabled={error || disabledSearch}>Apply</Button>
									</Card.Body>
								</Card>
							</Col>
						</Navbar>
					</Col>
					<Col lg={8} md={8} className="p-0">
						<Row>
							<Col>
								<SearchBar
									setSort={setSort}
									limit={limit}
									search={search}
									searchType={searchType}
									handleKeyPress={handleKeyPress}
									setLimit={setLimit}
									suggestions={suggestions}
									setSearch={setSearch}
									disabledSearch={disabledSearch}
									setSearchType={setSearchType}
									setFilters={setFilters}
									handleChange={handleChange}
									setSubmit={setSubmit}
									clearSearch={clearSearch}
									searchSource={searchSource}
									setSearchSource={setSearchSource}
									setLoading={setLoading}
									setReturned={setReturned}
									error = {error}
									// setFilters={setFilters} 
									filters = {filters}>
								</SearchBar>
							</Col>
						</Row>
						
						<Container className={returned ? "vertical-scrollable": null}>
							<Row>
								<Col>
									{returned && results.length > 0 && searchSource !== 'all_sources_search' && 
										<Alert>  Showing {results.length} of <strong>{limit}</strong> results </Alert>}

									 {returned && results.length > 0 && searchSource === 'all_sources_search' && 
										<Alert> Showing {results.length} of <strong> {limit} </strong> results </Alert>} 

									{loading ? <div className="mt-3 text-center"><Spinner
										className="align-items-center"
										animation="border"
										variant="primary"
										role="status"
										style={{
											height: 50,
											width: 50,
										}}>
									</Spinner> </div> : returned && results.length > 0 && searchSource === 'internal_search' ? results.map(
										res => {
											return (<Reportcard article={res} search={search}></Reportcard>)
										}
									) : (returned && results.length == 0 ? <div className="mt-5" style={{ 'textAlign': 'center' }}>No Results Found</div> : (returned && results.length > 0 && searchSource === 'external_search' ?
										results.map(
											res => {
												return (<NewsCard article={res}></NewsCard>)
											}
										) : (returned && results.length > 0 && searchSource === 'all_sources_search' ? results.map(
											res => {
												if (res.search_type === 'vendor') {
													return (<Reportcard article={res} search={search}></Reportcard>)
												} else {
													return (<NewsCard article={res}> </NewsCard>)
												}
											}
										) : nullScreen())))}

								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<footer><br></br></footer>
			</Container>

		</>
	)
}