import React, { useState, useEffect, useContext } from 'react';
import {
	Button,
	Card,
	Modal,
	Form,
	Accordion,
	ListGroup,
	InputGroup
} from 'react-bootstrap';
// import auth from '../auth/auth';
// import axios from '../utils/axiosInterceptor';
import { FaPlusCircle, FaTimes } from 'react-icons/fa';
import { formatTypeFields } from '../utils/formatter';
import { ContextAwareToggle } from '../utils/toggle';
import { API_ENDPOINT } from '../utils/endpoint';
import _ from 'lodash'; 

export const AddFields = () => {
	const [showModal, setShowModal] = useState(false)
	const [fields, setFields] = useState(null)
	const [newOption, setNewOption] = useState('')
	const [newField, setNewField] = useState('')
	const [disable, setDisabled] = useState(true)
	const [isRemoved, setIsRemoved] = useState(false)
	const [countFields, setCountFields] = useState(null)
	const [countOptions, setCountOptions] = useState(null)

	// function ContextAwareToggle({ children, eventKey, callback }) {
	// 	const currentEventKey = useContext(AccordionContext);

	// 	const decoratedOnClick = useAccordionToggle(
	// 		eventKey,
	// 		() => callback && callback(eventKey),
	// 	);

	// 	const isCurrentEventKey = currentEventKey === eventKey;

	// 	return (
	// 		<Row>
	// 			<InputGroup className=" bg-white" onClick={decoratedOnClick}>
	// 				<Col>
	// 					{children}
	// 				</Col>
	// 				<Col>
	// 					<InputGroup.Prepend className=" border-0 bg-white float-right">
	// 						<InputGroup.Text className='border-0 bg-white'>
	// 							{isCurrentEventKey ? <FaAngleUp> </FaAngleUp> : <FaAngleDown></FaAngleDown>}
	// 						</InputGroup.Text>
	// 					</InputGroup.Prepend>
	// 				</Col>
	// 			</InputGroup>
	// 		</Row>
	// 	);
	// }


	const disabledAddOption = newOption === ''
	const disabledAddField = newField === ''


	const handleShow = () => setShowModal(true);
	const handleClose = () => setShowModal(false);

	const fetchFields = async () => {
		await fetch(`${API_ENDPOINT}/fields/tags`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(res => {setFields(res) 
			setCountFields(res.length)
			setCountOptions(_.sum(Object.keys(res).map(k => res[k].values.length)))})
	}

	useEffect(() => {
		fetchFields()
	}, [])


	const changeHandler = (e) => {
		setNewOption(e.target.value)
	}

	const changeHandlerField = (e) => {
		setNewField(e.target.value)
	}

	const removeOption = (key, defaultitem) => {
		setDisabled(true)
		const currentFieldList = fields
		const newvals = [...fields[key]['values'].slice(0, fields[key]['values'].indexOf(defaultitem)), ...fields[key]['values'].slice(fields[key]['values'].indexOf(defaultitem) + 1)]
		currentFieldList[key]['values'] = newvals
		setFields(currentFieldList)
		setNewOption('')
		setIsRemoved(prevState => !prevState)
		setDisabled(false)
	}

	const editOption = (key, defaultitem, edit) => {
		const currentFieldList = fields
		currentFieldList[key]['values'].splice(currentFieldList[key]['values'].indexOf(defaultitem), 1, edit)
		setFields(currentFieldList)
		setNewOption('')
		setDisabled(false)
	}

	const addField = () => {
		if (!disabledAddField) {
			const currentFieldList = fields
			currentFieldList.push({ field: newField.toLowerCase().replaceAll(' ', '_'), values: [] })
			setFields(currentFieldList)
			setNewField('')
			setDisabled(false)
		}
	}

	const addOption = (key) => {
		if (!disabledAddOption) {
			const currentFieldList = fields
			currentFieldList[key]['values'].push(newOption)
			setFields(currentFieldList)
			setNewOption('')
			setDisabled(false)
		}
	}

	const submitUpdates = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/fields/tags/batchupdate`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`  },
			body: JSON.stringify({ "data": fields })
		})
			.then(res => res.json())
			.then(results => {
				setShowModal(false) 
				setDisabled(true)})
	}


	const numberAdded = fields && (fields.length + _.sum(Object.keys(fields).map(k => fields[k].values.length))) - (countFields +countOptions)


	return (
		<>
			<Button
				onClick={handleShow}
				className="mr-2 float-right rounded-pill"
				variant="success">
				Add or Edit Tag Fields
			</Button>
			<Modal
				size='lg'
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}>
				<Modal.Header
					className="border-0 bg-white"
					closeButton>
					<Modal.Title >Add or Edit Tag Fields</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<InputGroup className="mb-2 bg-white">
						<InputGroup.Prepend className="mb-2">
							<InputGroup.Text>
								<FaPlusCircle
									as={Button}
									color='#1f9e6c'
									onClick={addField}
									style={{ cursor: "pointer" }}>
								</FaPlusCircle>
							</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							className="mb-2"
							type="input"
							value={newField}
							placeholder='New Tag Field'
							onChange={changeHandlerField} />
					</InputGroup>
					<Accordion >
						{fields && Object.keys(fields).map(k => {
							const field = fields[k].field
							return (
								<Card key={fields[k].key}>
									<Card.Header className="border-0 bg-white">
										<ContextAwareToggle as={Card.Header} eventKey={k} className="bg-transparent">
											{formatTypeFields(field)}
										</ContextAwareToggle>
									</Card.Header>
									<Accordion.Collapse eventKey={k}>
										<Card.Body key={isRemoved}>
											<ListGroup className="border-0">
												{fields[k]['values'].map(v => {
													return <ListGroup.Item className="border-0"> <InputGroup>
														<InputGroup.Prepend>
															<InputGroup.Text>
																<FaTimes as={Button} onClick={(e) => removeOption(k, v)}></FaTimes>
															</InputGroup.Text>
														</InputGroup.Prepend>
														<Form.Control
															key={v}
															className="m-0"
															type="input"
															defaultValue={v}
															onChange={(e) => editOption(k, v, e.target.value)} />
													</InputGroup>
													</ListGroup.Item>
												})}
											</ListGroup>
											<InputGroup className="mt-2 bg-white">
												<InputGroup.Prepend>
													<InputGroup.Text>
														<FaPlusCircle
															name={k}
															as={Button}
															color='#1f9e6c'
															onClick={(e) => addOption(k)}
															style={{ cursor: "pointer" }}>
														</FaPlusCircle>
													</InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control
													className="m-0"
													type="input"
													value={newOption}
													placeholder='New Option'
													onChange={changeHandler} />
											</InputGroup>
										</Card.Body>
									</Accordion.Collapse>
								</Card>)
						})}
					</Accordion>
					<Button
						className="mt-2 btn rounded-pill"
						variant="success"
						onClick={submitUpdates}
						disabled={disable}>
						Submit ({numberAdded}) Updates
					</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}