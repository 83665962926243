import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDashboardProvider } from '../contexts/dashboardContext';

export const BuildNewsItems = () => {

	const {
		dashboardSettings, 
		newsSelections, 
		setShowSettings,
		addNewsItem, 
		saveDashboardSettings} = useDashboardProvider(); 

	const saveAndClose = () => {
		saveDashboardSettings();
		setShowSettings(false);
	}
	
	return (
		<>
		<Form>
			<Row> 
				<Col> 
				<h4>News Topics</h4>
				{dashboardSettings.map((s, i) => {
						let isChecked = newsSelections.includes(s.id) ? true : false;
						return <Form.Check
							className="col-4"
							inline key={s.id}
							label={s.id}
							id={s.id}
							type='checkbox'
							onChange={(e) => addNewsItem(e.target.id)}
							defaultChecked={isChecked}
							>
						</Form.Check>
					})}
				</Col>
			</Row>
		</Form>
		
		<Button className="primary rounded-pill mt-3 " onClick={() => saveAndClose()}>Save Layout </Button>
		</>
	)

}