import React, {PureComponent} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import WEI from '../../exampledata/wei.json'; 
import moment from 'moment'; 

export default class BasicLineChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			data:props.data, 
			x: props.x, 
			y: props.y
		} 


	}
  
	xAxisTickFormatter = (timestamp_measured) => {
		return moment(timestamp_measured)
		  .format("ll")
		//   .slice(0, 6);
		 }
	
	render() {
		
	  return (
		<ResponsiveContainer  width="90%" height="100%">
		  
		  <LineChart
			width={500}
			height={300}
			data={this.state.data}
			margin={{
			  top: 5,
			  right: 75,
			  left: 20,
			  bottom: 5,
			}}
		  >
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey={this.state.x}  tick={{fontSize: 12}}/>
			<YAxis  />
			<Tooltip />
			{/* <Legend /> */}
			<Line type="monotone" dataKey={this.state.y} stroke="#0032A0" strokeWidth={1.5} dot={false} />
			<Brush tickFormatter={this.xAxisTickFormatter} dataKey={this.state.x} tick={{fontSize: 4}} stroke='#0032A0' />
		  </LineChart>
		</ResponsiveContainer>
	  );
	}
  }
  