import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Alert, Container } from 'react-bootstrap';
import { FaDownload, FaFolder, FaFilePowerpoint, FaFileExcel, FaFilePdf, FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { API_ENDPOINT } from '../utils/endpoint';
import moment from 'moment';

export const Reportcard = ({ article, search }) => {

	const [url, setURL] = useState(null)

	const type_map = {
		'Excel': '.xlsx',
		'PDF': '.pdf',
		'Powerpoint': '.pptx',
		'Word': '.docx'
	}

	useEffect(() => {
		const token = localStorage.getItem('token')
		let title = encodeURIComponent(article.title + type_map[article.type])
		const fetchURL = async (url) => {
			await fetch(`${API_ENDPOINT}/data/url?key=${url}&filename=${title}`, {
				method: 'GET',
				headers: { 'Authorization': `Bearer ${token}` }
			})
				.then(res => res.json())
				.then(result => setURL(result))
		}
		const url = encodeURIComponent(article.key)
		fetchURL(url)

	}, [])


	const handleDownload = (item) => {
		let a = document.createElement('a');
		a.href = item
		a.click();
	}

	const textResults = (article) => {
		if (article.highlights) {
			let highlight = article.highlights
			let texts = highlight[0].texts;
			let replacements = texts.map(text => {
				if (text.type == "hit") {
					return text.value;
				} else {
					return text.value;
				}
			}).join("");
			let originals = texts.map(text => {
				return text.value;
			}).join("");

			return replacements.substring(0, 500)

		} else {
			return article.text.substring(0, 500)
		}
	}

	const returnIcon = (type) => {
		if (type === 'PDF') {
			return <FaFilePdf size={50} color='white' style={{ textAlign: 'center' }}></FaFilePdf>
		} else if (type === 'Excel') {
			return <FaFileExcel size={50} color='white' style={{ textAlign: 'center' }}></FaFileExcel>
		} else if (type === 'Powerpoint') {
			return <FaFilePowerpoint size={50} color='white' style={{ textAlign: 'center' }}></FaFilePowerpoint>
		}
	}

	const colorMap = new Map()

	const reportHeaders = (article) => {

		const longestText = (arr) => {
			var longest = arr.reduce(
				function (a, b) {
					return a.length > b.length ? a : b;
				}
			)
			return longest
		}

		const shortestText = (arr) => {
			var shortest = arr.reduce(
				function (a, b) {
					return a.length < b.length ? a : b;
				}
			)
			return shortest
		} 

		if (article.auto_headline && article.auto_title) {
			return (<>
				<Row>
					<Col>
						<h4 style = {{overflowWrap: 'break-word'}} ><Link to={{ pathname: '/preview', state: { article: article } }} >
							{longestText([article.auto_headline, article.auto_title])}
							</Link ></h4>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="lead">{shortestText([article.auto_headline, article.auto_title])}</p>
					</Col>
				</Row>
				<Row >
					<Col>
						<div style = {{overflowWrap: 'break-word'}} >
							<p>{article.auto_summary.replace(/\s+(\W)/g, "$1")}</p>
						</div>
					</Col>
				</Row>
			</>)
		} else {
			return (
				<>
				<Row>
					<Col>
						<h4><Link to={{ pathname: '/preview', state: { article: article } }} >
							{article.auto_title ? article.auto_title : article.title}
							</Link >
						</h4>
					</Col>
				</Row>
				<Row className = "">
					<Col>
						<p>{article.auto_summary}</p>
					</Col>
				</Row>
				</>
			)
		}

	}

	colorMap.set('PDF', "#ed5251")
	colorMap.set("Excel", "#1D6F42")
	colorMap.set("Powerpoint", '#d63c06')

	const getColor = (type) => {
		return colorMap.get(type)
	}
	return (


		<Card className=" card flex-row  m-2" >
			<Card.Header className={article.type && article.type.concat(" border-0")} style={{ backgroundColor: getColor(article.type) }}>
				<div style={{ verticalAlign: "middle" }}>
					{returnIcon(article.type)}
				</div>
			</Card.Header>

			<Card.Body>
				{reportHeaders(article)}
				<Row>
				{/* <Col className = "col-sm-2">
					{article.score && <Badge variant={Math.round(article.score * 100) >= 88 ? "success": "warning"}>{Math.round(article.score * 100) + "% relevant"}</Badge>}
					</Col> */}
					<Col>
						{article.auto_tags && article.auto_tags.map(t => {
							return <Badge className="ml-2" variant="info">{t} </Badge>
						})}
					</Col>
				</Row>
				<hr></hr>
				<Row className="align-items-start" style = {{fontSize: '10pt'}}>
					<Col md={4}>
						<strong>Publish Date: </strong>
						<span className="ml-1"> {moment(article.publish).format('LL')} </span>
					</Col>
					{/* <Col>
						<strong>Filename: </strong>
						<span className="ml-2"> {article.title} </span>
					</Col> */}
					<Col>
						<span className="ml-1 mt-1"><strong>Source: </strong> {article.directory}</span>
					</Col>
					<Col md={3}>
						<a href="#" onClick={() => handleDownload(url)}><FaDownload onClick={() => handleDownload(url)} color='#0032A0'></FaDownload>
							<span className="ml-1 mt-1"><strong>Download as </strong>{article.type}</span></a>
					</Col>
					<Col>
						<Link to={{ pathname: '/preview', state: { article: article } }} ><FaEye color='#0032A0'></FaEye>
							<span className="pl-0 ml-0 mt-1"><strong> Preview File</strong></span> </Link>
					</Col>
				</Row>
				<Row>
					{search !== "''" &&
						// <Col className="mt-2">
						// 	{/* <em>{search} </em>is mentioned {[...article.text.toLowerCase().matchAll(new RegExp(search.toLowerCase(), "gm"))].length} times in this report.  */}
						// 	<em>{search}</em> is mentioned {article.num_of_occ} times in this report
						// </Col>
						<div></div>
					}
				</Row>
			</Card.Body>
		</Card>
	)
}