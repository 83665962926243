import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, ProgressBar, Badge, Alert, Button, Spinner } from 'react-bootstrap';
import { FaDownload, FaGrimace, FaFilePdf, FaFilePowerpoint, FaFileExcel } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { Defaultnav } from '../components/navbar';
import _ from 'lodash'
import { API_ENDPOINT } from '../utils/endpoint';
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment'; 
import {MissingPage} from '../components/404'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Viewer = () => {

	const [article, setArticle] = useState({})
	const location = useLocation()
	const data = location.state.article
	const [loaded, isLoaded] = useState(false)
	const [url, setURL] = useState(null)
	const [fullPreview, setFullPreview] = useState(false)
	const [numPages, setNumPages] = useState(5)
	const [preview, setPreview] = useState('pdf')
	const [iFrameStatus, setIFrameStatus] = useState(1) 
	const [seePreview, setSeePreview] = useState(true)

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
		setPreview('pdf')
	}

	useEffect(() => {
		const token = localStorage.getItem('token')
		const fetchInfo = async (id) => {
			await fetch(`${API_ENDPOINT}/data/files/${id}`, {
				method: 'GET',
				headers: { 'Authorization': `Bearer ${token}` }
			})
				.then(res => res.json())
				.then(result => setArticle(result[0]))
		}
		fetchInfo(data._id)
	}, [])

	const type_map = {
		'Excel': '.xlsx', 
		'PDF': '.pdf', 
		'Powerpoint': '.pptx', 
		'Word': '.docx'
   }

	useEffect(() => {
		const token = localStorage.getItem('token')
		let title = encodeURIComponent(article.title + type_map[article.type])
		const fetchURL = async (url) => {
			await fetch(`${API_ENDPOINT}/data/url?key=${url}&filename=${title}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'content-type': 'application/json'
				}
			})
				.then(res => res.json())
				.then(result => { setURL(result) })
		}
		if (article) {
			const url = encodeURIComponent(article.key)
			fetchURL(url)

		}
	}, [article])


	const handleDownload = (item, filename) => {
		let a = document.createElement('a');
		a.href = item;
		a.download = filename;
		//console.log(filename)
		//console.log(a)
		a.click();
	}

	const returnBadges = (x, variant = 'info') => {
		if (x) {
			if (typeof (x) === 'object') {
				const tags = x.flat()
				return (Array.from(tags).map(tag => {
					return (<Badge className="ml-2" variant={variant}> {tag} </Badge>)
				}))
			} else {
				return (<Badge className="ml-2" variant={variant}>{x}</Badge>)
			}
		}
	}

	// const percentCompleted = (article) => {
	// 	let obj = Object.fromEntries(
	// 		['publish', 'summary', 'description', 'tags']
	// 		.map(key => [key, article[key]])
	// 	)
	// 	let count = Object.keys(obj).filter(x => article[x] === '' | article[x] == undefined | article[x] === []).length
	// 	let totalFields = 4
	// 	return _.round(100 - (count / totalFields * 100))
	// }

	const formatDate = (date) => {
		let dt = new Date(date * 1000)
		return dt.toLocaleDateString()
	}

	const options = {
		cMapUrl: 'cmaps/',
		cMapPacked: true,
	};

	const returnIcon = (type) => {
		if (type === 'PDF') {
			return <FaFilePdf  size = {50} color = "#ed5251" style = {{textAlign:'center'}}></FaFilePdf>
		} else if (type === 'Excel') {
			return <FaFileExcel size = {50} color = "#1D6F42" style = {{textAlign:'center'}}></FaFileExcel>
		} else if (type === 'Powerpoint') {
			return <FaFilePowerpoint size = {50} color = '#d63c06' style = {{textAlign:'center'}}></FaFilePowerpoint>
		}
	}

	const colorMap = new Map() 

	colorMap.set('PDF', "#ed5251")
	colorMap.set("Excel", "#1D6F42")
	colorMap.set("Powerpoint",'#d63c06')

	const getColor = (type) => {
		return colorMap.get(type)
	}


	return (
		<>
			<Defaultnav />
			<Container >
				{_.isEmpty(article) && <Spinner
					animation="border"
					variant="primary"
					role="status"
					style={{
						height: 100,
						width: 100,
						position: "fixed",
						top: "50%",
						left: "50%"
					}}>
				</Spinner>}
				{!_.isEmpty(article) &&
					<Card className="m-3 mt-5 border-0 shadow">
						<Card.Header className = {article.type.concat(" border-0")} style = {{backgroundColor: '#fff'}}>
				<div style = {{verticalAlign: "middle"}}>
			{returnIcon(article.type)}
			</div>
			</Card.Header>
						<Card.Body>
							{
							article.auto_title && article.auto_title !== '' ? 
								<h2>{article.auto_title}</h2> :
								<h2>{article.title}</h2>
							}
							<hr></hr>
							<h4>{article.auto_headline}</h4>
							<div> 
							<p className="lead">{article.auto_summary && article.auto_summary.replace(/\s+(\W)/g, "$1")}</p>
							</div>
							<div className="icon">
							<FaDownload id="download" size={20} onClick={() => handleDownload(url, article.title + type_map[article.type])} style={{ cursor: 'pointer' }}></FaDownload>
							</div>
							<Card.Subtitle className="mt-2 mb-2">

								<span variant="primary">{article.views && article.views.length} {article.views && article.views.length > 1 ? 'views' : 'view'}</span>
								<br></br>
								<br></br>
								<em>{ article.lastviewed ? 'Last viewed on ' + formatDate(article.lastviewed): 'Not viewed previously'}</em>
							</Card.Subtitle>
					
							<Row>
								<Col>
									{/* <ProgressBar
										variant='success'
										style={{ height: '25px' }}
										now={percentCompleted(article)}
										label={`Information for this file is ${percentCompleted(article)}% completed`}>
									</ProgressBar> */}
									{article.Tags && article.Tags.flat().length === 0  && article.auto_tags && article.auto_tags.flat().length === 0 && <Alert className="mt-2" variant="warning">Tags still need to be assigned for this file</Alert>}
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<p>Uploaded by <strong>{article.user}</strong> on {new Date(article.date).toLocaleDateString()}</p>
								</Col>
							</Row>
							<Row className="mt-4 mb-4" >
								
								<Col className="mb-2">
									<strong>Tags: </strong>
									{returnBadges(article.auto_tags)}
								</Col>
								<Col>
									<strong>Organizations: </strong>
									{returnBadges(article.auto_organization, 'alt0')}
								</Col>
								{/* <Col>
									<strong> Report Type(s):  </strong>
									{article.reporttype && returnBadges(article.reporttype, 'alt2')}
								</Col> */}
							</Row>
							<Row className="align-items-start">
								<Col >
									<strong>Publish Date: </strong>
									<span className="ml-2"> {moment(article.publish).format('L')} </span>
								</Col>
								<Col>
									<strong> Source: </strong>
									<span className="ml-2"> {article.directory}</span>
								</Col>
							</Row>
							{/* <Row className="mt-1">
								<Col>
									<strong>Rating:</strong> {article.rating} out of 5
								</Col>
							</Row> */}
							{/* <Row className="mt-2 align-items-end">
								<Col>
									<strong>Description:</strong>
								</Col>
							</Row>
							<Row>
								<Col>
									{article.description}
								</Col>
								<Col>

								</Col>
							</Row>
							<Row className="mt-2 align-items-end">
								<Col>
									<strong> Summary: </strong>
								</Col>
							</Row>
							<Row>
								<Col>
									{article.summary}

								</Col>
							</Row> */}
							{/* <Row className="mt-2 align-items-end">
								<Col>
								
									<Alert variant={'info'} > {preview === 'text' ? 
									'If the preview below does not appear please download the file to access the contents' : 
									'Preview only includes the first 20 pages. Download the file in order to view all pages of the file.'}</Alert>
								</Col>
							</Row> */}
							<Row>
							<Col>
	
							<hr></hr>
							<Button variant="link" onClick={(() => setSeePreview(prevState => !prevState))}>{!seePreview ? "View Report" : "Hide Report" }</Button>
							</Col>
							</Row>
							{seePreview === true && <Row className = "mt-2">
	
								<Col>
									{preview === 'text' && 
									    <>
										
										{iFrameStatus === 1 ? <iframe
										  id='iframe'
										  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
										  width="100%"
										  height="600px"
										  frameBorder="0"
										  title="slides"
										  onLoad={(e) => setIFrameStatus(e.target.contentWindow.window.length)}
										></iframe> : <MissingPage />}
									  </>}
									{preview === 'pdf' && <div className="documentwrapper">
										<Document file={url} options={options} onLoadSuccess={(e) => onDocumentLoadSuccess(e)} onLoadError={(e) => setPreview('text')}>
											{Array.from(new Array(numPages > 20 ? 20: numPages), (el, index) => (
											
												<Page onMouseDown={(e) => console.log(window.getSelection().toString())} className={"documentpdf"} key={`page_${index + 1}`} pageNumber={index + 1} width={1000} >
											
												</Page>
												
											))}
										</Document></div>}
								</Col>
							</Row>}
						</Card.Body>
					</Card>}

			</Container>

		</>
	)
}