import React, { useEffect, useState } from "react";
import { Card, Spinner } from 'react-bootstrap'
import { FaEdit, FaTrash } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory, { textFilter, dateFilter, numberFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import { hrinfo } from "../utils/hr";
import { API_ENDPOINT } from "../utils/endpoint";
import { EditUserModal } from "./edituser";
import { DeleteUserModal } from "./delete";

export const AdminTable = ({ updateTable, setUpdateTable }) => {

	const [data, setData] = useState([]);
	const [showModal, setShowModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null) 

	const fetchData = async () => {
			const token = localStorage.getItem('token')
			await fetch(`${API_ENDPOINT}/users?isactive=true`, {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
				method: 'GET',

			})
				.then(response => response.json())
				.then(data => {
					setData(data.map(d => (
						{ email: d.email, 
						  firstname: d.firstName, 
						  lastname: d.lastName, 
						  department: d.department || '', 
						  role: d.role || '',
						  ID: d.id,
						  access: d.is_superuser === true ? 'Admin' : 'ReadOnly' })))
				})
		};


	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		if (updateTable) {
			fetchData()
		}
	}, [updateTable])



	function rankFormatter(cell, row, rowIndex, formatExtraData) {
		return (
			< div
				style={{
					textAlign: "center",
					cursor: "pointer",
					lineHeight: "normal"
				}}>

				< FaEdit
					style={{ fontSize: 20 }}
					color="#0032A0"
				/>
			</div>
		);
	}

	function delFormatter(cell, row, rowIndex, formatExtraData) {
		return (
			< div
				style={{
					textAlign: "center",
					cursor: "pointer",
					lineHeight: "normal"
				}}>

				< FaTrash
					style={{ fontSize: 20 }}
					color='#d11a2a'
				/>
			</div>
		);
	}


	function colFormatter(column, colIndex, { sortElement, filterElement }) {
		return (
			<div style={{ unset: 'all', display: 'flex', flexDirection: 'column' }}>
				{filterElement}
				{column.text}
				{sortElement}
			</div>
		);
	}

	const deparments = Object.keys(hrinfo).map(key => {
		return {value: key, label: key}
	})


	const columns = [
		{
			dataField: 'email',
			text: 'Email',
			headerFormatter: colFormatter,
			sort: true,
			headerAttrs: { width: 350 },
			style: { width: 350 },
			filter: textFilter(),
			
		},
		{
			dataField: 'firstname',
			text: 'First Name',
			sort: true,
			filter: textFilter(),
			headerFormatter: colFormatter,
		},
		{
			dataField: 'lastname',
			text: 'Last Name',
			sort: true,
			filter: textFilter(),
			headerFormatter: colFormatter,
		},

		{
			dataField: 'department',
			text: 'Department', 
			sort: true,
			headerFormatter: colFormatter,
			filter: textFilter(),
		},
		{
			dataField: 'role',
			text: 'Role', 
			sort: true,
			headerFormatter: colFormatter,
			filter: textFilter()
		},
		{
			dataField: 'access',
			text: 'Access Type', 
			sort: true,
			headerFormatter: colFormatter,
			filter: textFilter()
		},
		{
			dataField: "Edit", text: "", sort: false, formatter: rankFormatter,
			headerAttrs: { width: 50 },
			attrs: { width: 50, className: "EditRow" },
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					e.preventDefault();
					setCurrentUser(row)
					setShowModal(true)
				}
			}
		},
		{
			dataField: "Delete", text: "", sort: false, formatter: delFormatter,
			headerAttrs: { width: 60 },
			attrs: { width: 50, className: "DeleteRow" },
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					e.preventDefault();
					setCurrentUser(row)
					setShowDeleteModal(true)
					
				}
			}
		}
	]



	return <Card className="mt-2">
		<Card.Body>
			<BootstrapTable
				classes="table mt-3"
				keyField="_id"
				data={data || []}
				columns={columns}
				pagination={paginationFactory()}
				filter={filterFactory()}
				bordered={false}
				noDataIndication={() => data ? <div className = "mt-3 text-center"><Spinner
					animation="border"
					variant="primary"
					role="status"
					style={{
						height: 50,
						width: 50,
						// position: "fixed",
						// top: "50%",
						// left: "50%"
					}}>
				</Spinner> </div>: <div> No Data </div>}
				bootstrap4
				condensed
				hover
			/>
			<DeleteUserModal 
			setShowDeleteModal={setShowDeleteModal} 
			setUpdateTable={setUpdateTable} 
			showDeleteModal={showDeleteModal} 
			user={currentUser || {}}/>
			<EditUserModal
			setShowModal={setShowModal}
			showModal={showModal}
			setUpdateTable={setUpdateTable}
			user={currentUser || {}}>

			</EditUserModal>
		</Card.Body>
	</Card>


}