import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { DashboardContextProvider } from './contexts/dashboardContext';
import { ManageDataContextProvider } from './contexts/manageDataContext';
import { ReportContextProvider } from './contexts/reportContext';

ReactDOM.render(
  <React.StrictMode>
    <DashboardContextProvider>
      <ReportContextProvider>
        <ManageDataContextProvider>
          <App />
        </ManageDataContextProvider>
      </ReportContextProvider>
    </DashboardContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
