import React, { useContext, useCallback, useState, useEffect, createContext, useCallBack } from "react";
import { API_ENDPOINT } from '../utils/endpoint';
import auth from '../auth/auth';
import { titleCase } from '../utils/formatter';
import _ from 'lodash';
import { debounce } from 'lodash';
import authchecker from '../auth/authchecker';
export const DashboardContext = createContext();

const INITIAL_SELECTIONS = [
  'Seated Diners',
  'Weekly Economic Index',
  'Consumer Price Index (CPI)',
  'Unemployment Rate',
  'Google Mobility Index',
  'Consumer Price Index (CPI) Food Category',
]

export function DashboardContextProvider({ children }) {
  const [sources, setSources] = useState([]);
  const [user, setUser] = useState({
    "id": "",
    "email": "",
    "is_active": true,
    "is_superuser": false,
    "firstName": "",
    "lastName": ""
  });
  const [topTags, setTopTags] = useState([{}])
  const [freqSearch, setFreqSearch] = useState([{}])
  const [selections, setSelections] = useState(INITIAL_SELECTIONS)
  const [show, setShow] = useState(false)
  const [layout, setLayout] = useState({})
  const [articleCount, setArticleCount] = useState(null)
  const [filteredSources, setFilteredSources] = useState([])
  const [loading, setLoading] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [showSource, setShowSource] = useState(true)
  const [showDate, setShowDate] = useState(true)
  const [itemAdded, setItemAdded] = useState(false)
  const [newsSelections, setNewsSelections] = useState([])
  const [dashboardSettings, setDashboardSettings] = useState([])
  const [newsData, setNewsData] = useState([])
  const [newsLayout, setNewsLayout] = useState([])
  const [newsCounter, setNewsCounter] = useState(0)
  const [showAddCard, setShowAddCard] = useState(false)
  const [indicatorLayout, setIndicatorLayout] = useState([]) 
  const [indicatorSelections, setIndicatorSelections] = useState([])
  const [indicatorSettings, setIndicatorSettings] = useState([])
  const [token, setToken] = useState(null)

  const previousLoginDate = new Date(localStorage.getItem('lastlogin')).toLocaleDateString('en-US')


  // useEffect(() => {
  //   saveIndicatorSettings()
  // }, [indicatorLayout])

  const addNewsItem = (item) => {
    if (!newsSelections.includes(item)) {
      setNewsSelections([...newsSelections, item]);
      setNewsLayout(newsLayout.concat({
        i: item,
        x: newsSelections.length % 2 ? 4 : 0,
        y: Infinity,
        w: newsSelections.length % 2  === 0 ? 4 : 8,
        h: 16,
      }))

      setNewsCounter(newsCounter + 1);
    } else {
      let oldSelections = newsSelections.filter(s => s !== item);
      setNewsSelections(oldSelections);
      setNewsCounter(newsCounter + 1);
    }
  }

  const fetchNewsData = async () => {
    let token = localStorage.getItem('token');
    await fetch(`${API_ENDPOINT}/dashboard/news`, {
      headers: { 'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`},
      method: 'GET',
    }).then(res => res.json())
      .then(result => setNewsData(result));
  }

  const addNewDashboardItem = async ( title, search, type ) => {
    let token = localStorage.getItem('token'); 

    let newDashboardSettings = [
      ...dashboardSettings, 
      {
        id: title, 
        search: search, 
        show: true, 
        type: type, 
        index: 'search'
      }
    ]

    setDashboardSettings(newDashboardSettings)

    let newNewsSelections = [...newsSelections, title];
    setNewsSelections(newNewsSelections);
    let newNewsLayout = newsLayout.concat({
        i: title,
        x: newNewsSelections.length % 2 ? 4 : 0,
        y: Infinity,
        w: newNewsSelections.length % 2  === 0 ? 4 : 8,
        h: 16,
      })

      setNewsLayout(newNewsLayout);
      setNewsCounter(newsCounter + 1);

    // addNewsItem(title);

    await fetch(`${API_ENDPOINT}/dashboard/settings/update`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}, 
			body: JSON.stringify({'settings': newDashboardSettings, 'layout': newNewsLayout})
		}).then(response => response.json())
    .then(res => fetchNewsData())
    // .then(res => saveDashboardSettings())

    
  }

  const saveDashboardSettings = async () => {
    let token = localStorage.getItem('token');
    let oldDashboardSettings = dashboardSettings; 
    oldDashboardSettings.map(setting => {
      if (newsSelections.includes(setting.id)) {
            setting.show = true;
      } else {
            setting.show = false;
      }
    })

    await fetch(`${API_ENDPOINT}/dashboard/settings/update`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}, 
			body: JSON.stringify({'settings': dashboardSettings, 'layout': newsLayout})
		}).then(response => response.json())
    .then(res => console.log(res));

  }

  const saveIndicatorSettings = async () => {
    const token = localStorage.getItem('token');
    await fetch(`${API_ENDPOINT}/dashboard/settings/update/indicators`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }, 
      body: JSON.stringify({'settings': indicatorSettings, 'layout': indicatorLayout})
    }).then(response => response.json())
    .then(res => console.log(res))
  }
  
  

  useEffect(() => {
    const token = localStorage.getItem('token')

    const fetchDashboardIndicatorSettings = async () => {
      const token = localStorage.getItem('token')
      await fetch(`${API_ENDPOINT}/dashboard/settings/indicators`, {
        headers: { 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` },
        method: 'GET',
      }).then(res => res.json())
        .then(result => {
          // console.log("result from API", result);
            if (result.settings != undefined) {
              setIndicatorLayout( Object.keys(result.layout).map(key => result.layout[key])); 
              setIndicatorSelections(Object.keys(result.settings).map(key => result.settings[key]).filter(r => r.show === true).map(r => r.title));
              setIndicatorSettings(Object.keys(result.settings).map(key => result.settings[key]));
            }
          })
        }


    const fetchDashboardSettings = async () => {
      const token = localStorage.getItem('token')
      await fetch(`${API_ENDPOINT}/dashboard/settings`, {
        headers: { 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` },
        method: 'GET',
      }).then(res => res.json())
        .then(result => {
          if (result.settings !== undefined) {
            setDashboardSettings(result.settings)
            
            let show = result.settings.filter(r => r.show === true).map(r => r.id)
            setNewsSelections(show)
            setNewsLayout(result.layout)
          }
        })
    }

    const fetchSources = async () => {
      await fetch(`${API_ENDPOINT}/dashboard/sources`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(res => res.json())
        .then(result => setSources(result))
    }

    const fetchUploads = async () => {
      const token = localStorage.getItem('token')
      await fetch(`${API_ENDPOINT}/dashboard/info/uploads?login=${previousLoginDate}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(res => res.json())
        .then(result => result.length > 0 ? setArticleCount(result[0]['count']) : setArticleCount(0))

    }

    const fetchTags = async () => {
      await fetch(`${API_ENDPOINT}/dashboard/info/toptags`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(res => res.json())
        .then(res => res.map(r => {
          return {
            'Tags': r.Tags,
            'Field': r.Field,
            'Views': r.Views,
            'Percent of Titles': Math.round(r.perc) + '%'
          }
        }))
        .then(result => setTopTags(result))
    }

    const fetchSearched = async () => {
      await fetch(`${API_ENDPOINT}/dashboard/info/searches`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(res => res.json())
        .then(res => res.map(r => {
          return (
            {
              'Phrase': titleCase(r.Phrase),
              'Unique Searches': r.Searches,
              'Results': r.Results
            })
        }))
        .then(result => setFreqSearch(result))
    }



			const checkIfUserExists = async () => {
        let token = localStorage.getItem('token');
				await fetch(`${API_ENDPOINT}/dashboard/settings/newuser`, {
					headers: { 'Content-Type': 'application/json', 
					'Authorization': `Bearer ${token}`},
					method: 'POST',
				  }).then(res => res.json())
					.then(result => console.log(result));
			}
				


    if (user.id !== "") {
      fetchTags();
      fetchSearched();
      fetchUploads();
      fetchSources();
      fetchNewsData();
      checkIfUserExists();
      fetchDashboardSettings();
      fetchDashboardIndicatorSettings();
    }

  }, [user])

  const setInitialSettings = () => {
    setSelections(INITIAL_SELECTIONS)
  }

  useEffect(() => {
    // let filtered = sources.filter(s => selections.includes(s.name)) 
    let filtered = _.orderBy(sources.filter(s => selections.includes(s.name)), ['graph.type'], ['desc'])
    setFilteredSources(filtered);
  }, [selections, sources])

  const typeAttributeMap = {
    'line': { w: 6, h: 12 },
    'bar': { w: 6, h: 18 },
    'number': { w: 3, h: 6 }
  }

  // const debouncedSaveDashboard = useCallback(debounce(() => saveDashboardSettings(), 5000), []);

  // const updateNewsLayout = debounce((layout) => {
  //   setNewsLayout(layout)
  //   // debouncedSaveDashboard();
  //   saveDashboardSettings();
  // }, 5000)

  const resetLayout = () => {
    setLayout({})
  }

  useEffect(() => {
    setLoading(true)
    let xCounter = 0
    let yCounter = 0
    let updatedLayout = filteredSources.map((source, index) => {
      let x = index == 0 || !(index % 2) ? 0 : xCounter
      let y = index < 2 ? 0 : yCounter
      let w = typeAttributeMap[source.graph.type].w
      let h = typeAttributeMap[source.graph.type].h
      if (index === 0 || !(index % 2)) {
        xCounter += w
        yCounter += h
      }
      return (
        {
          i: index.toString(),
          x: x,
          y: y,
          w: w,
          h: h
        })
    }

    )
    setLayout(updatedLayout)
    setLoading(false)
  }, [filteredSources, showDate, showSource])


  const addNewIndicatorItem = (item) => {
    setLoading(true)
    let source = sources.find(source => source.name == item)
    // console.log("source: ", source)
    let itemCount = indicatorSelections.length
    let index = item 
    let x = index == 0 || !(index % 2) ? 0 : itemCount
    let y = Infinity
    let w = typeAttributeMap[source.graph.type].w
    let h = typeAttributeMap[source.graph.type].h
    let itemLayout = 
        {
          i: index.toString(),
          x: x,
          y: y,
          w: w,
          h: h
        }
    
    setIndicatorLayout(prevState => [...prevState, itemLayout])
    setLoading(false)
  }

  return (
    <DashboardContext.Provider
      value={{
        user,
        setUser,
        sources,
        setSources,
        topTags,
        freqSearch,
        selections,
        setSelections,
        show,
        layout,
        setLayout,
        articleCount,
        previousLoginDate,
        filteredSources,
        setInitialSettings,
        resetLayout,
        setLoading,
        loading,
        showSettings,
        setShowSettings,
        INITIAL_SELECTIONS,
        setShowSource,
        setShowDate,
        showDate,
        showSource,
        dashboardSettings,
        setDashboardSettings,
        itemAdded,
        setItemAdded,
        newsSelections,
        setNewsSelections,
        newsData,
        newsLayout,
        setNewsLayout,
        addNewsItem,
        saveDashboardSettings, 
        showAddCard, 
        setShowAddCard,
        addNewDashboardItem,
        indicatorLayout, 
        indicatorSelections,
        addNewIndicatorItem,
        setIndicatorSelections, 
        setIndicatorSettings,
        setIndicatorLayout,
        indicatorSettings,
        saveIndicatorSettings
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardProvider() {
  const context = useContext(DashboardContext)
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}