import hr from './roles-deparments.json'

export const hrinfo = hr

const options = [
'Intern',
'Account Manager',
'VP',
'Director',
'Manager',
'Senior Manager',
'Assistant Manager',
'Specialist'
]

export const alloptions = options.map(option => {
	return {
		value: option, 
		label: option
	}
})