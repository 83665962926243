import React, { useEffect, useState} from 'react';
import auth from './auth';
import { withRouter, useHistory } from 'react-router';

const authChecker = Component => withRouter((props) => {

	const [show, setShow] = useState(true)
	const history = useHistory()

	const MINUTE_MS = 30000 

	useEffect(() => {
		const interval = setInterval(() => {
			//console.log(auth.expiredToken())
			if (auth.expiredToken()) {
				auth.logout(() => {
					history.push('/')
				})
			} else {
				setShow(true)
			}
		}, MINUTE_MS);
	  
		return () => clearInterval(interval); 
	  }, [history, show])
	  

	return show && <Component {...props} /> 
		  
})
	  
export default authChecker;
