
import auth from '../auth/auth';
import axios from 'axios';

const axiosInterceptor = axios.create({
  baseURL: 'http://localhost:8000/',
});

/**
 * Catch the AunAuthorized Request
 */
axiosInterceptor.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    auth.logout()
	window.location('/')
  }
});

export default axiosInterceptor;