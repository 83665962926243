import React, { useState, useEffect } from 'react';
import {
	FormControl,
	Button,
	Navbar,
	Form,
	Modal,
	Row,
	Col,
	InputGroup,
	Badge,
	ButtonGroup,
	Accordion,
	Card
} from 'react-bootstrap';
import { Search } from '../pages/Search';
import Select from 'react-select'
import { customSelect, limitSelect } from '../lib/style';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { FaFilter, FaSearch, FaPlusCircle,FaTimes, FaCaretSquareRight, FaRegCaretSquareUp, FaRegCaretSquareRight, FaRegArrowAltCircleUp, FaExternalLinkAlt } from 'react-icons/fa';
import { Autocomplete } from './autocomplete';
import { ContextAwareToggle } from '../utils/toggle';
import _ from 'lodash'; 

export const SearchBar = ({
	setSort,
	limit,
	search,
	clearSearch,
	searchType,
	setLimit,
	disabledSearch,
	setSearchType,
	handleChange,
	setSubmit,
	searchSource,
	setSearchSource,
	setLoading,
	setReturned,
	setFilters, 
	filters, 
	error }) => {

	const [showModal, setShowModal] = useState(false) //open and close modal
	const [argLength, setArgLength] = useState(1) //add more arguments to search
	const [advancedSearch, setAdvancedSearch] = useState([])
	const [query, setQuery] = useState({}) 
	const [terms, setTerm] = useState({})
	const [fields, setFields] = useState({}) 
	const [types, setTypes] = useState({})
	const [booleans, setBooleans] = useState({})

	// const [form, setForm] = useState([])

	useEffect(() => {
		let filters = localStorage.hasOwnProperty("filters") ? JSON.parse(localStorage.getItem("filters")) : null 
		let advancedSearch = filters && filters.hasOwnProperty('advancedSearch') ? filters['advancedSearch'] : null
		if (advancedSearch) {
			if (Array.isArray(advancedSearch)) {
				setArgLength(advancedSearch.length)
				advancedSearch.map((parameters, key) => {
					setQuery(prevState => ({...prevState, [key]: parameters['query']}))
					setTerm(prevState => ({...prevState, [key]: parameters['term']}))
					setFields(prevState => ({...prevState, [key]: parameters['field']}))
					setTypes(prevState => ({...prevState, [key]: parameters['type']}))
					setBooleans(prevState => ({...prevState, [key]: parameters['boolean'] || null}))
				})
			} else {
				 setQuery({[0]: advancedSearch['query']})
				 setTerm({[0]:advancedSearch['term']})
				 setFields({[0]:advancedSearch['field']})
				 setTypes({[0]:advancedSearch['type']})
				 setBooleans({[0]:advancedSearch['boolean']})
				 setArgLength(1)
			}
			
		}
	}, [])

	const onHide = () => setShowModal(false)

	const keyDownHandler = (e) => {
		if (e.keyCode === 13) {
			setSubmit(true)
			setLoading(true)
		}

	}

	const queryBuilder = (form) => {
		const getId = (element) => {
			let id = element.children[element.selectedIndex].id
			return id
		}
		if (argLength > 1) {
			let qry = Array(argLength).fill(0).map((x, y) => x + y).map(key => {
			
				return {
					'query': form.query[key].value, 
					'term': form.term[key].value, 
					'field': form.field[key].value,
					'type': form.type[key].value,
					'boolean': (key + 1) === argLength ? form.boolean[key-1].value : form.boolean[key].value 
				}

			} )
			setAdvancedSearch(qry)
			setFilters({...filters, 'advancedSearch': qry})
			setSubmit(true);
			setLoading(true);
			setReturned(false);
			setShowModal(false);
		} else {
			let qry = {
				'query': form.query.value,
				'term': form.term.value, 
				'field': form.field.value,
				'type': form.type.value,
				// 'boolean': 'And'
				'boolean': form.boolean.value 
			}
			setAdvancedSearch(qry)
			setFilters({...filters, 'advancedSearch': qry})
			setSubmit(true);
			setLoading(true);
			setReturned(false);
			setShowModal(false)
		}
		
	}

	const removeKeyFromObject = (object, key) => {
		let newObj = {};
		let keys = Object.keys(object); 
		// console.log("keys", keys);
		let newkeys = keys.filter(k => k !== key.toString())
		newkeys.map((originalKey, newKey) => {
			newObj[newKey] = object[originalKey]
		})

		return newObj;
	}

	const deleteRow = (key) => {
		setArgLength(prevState => prevState -= 1)
		setQuery(removeKeyFromObject(query, key)) 
		setTypes(removeKeyFromObject(types, key))
		setTerm(removeKeyFromObject(terms, key))
		setFields(removeKeyFromObject(fields, key))
		setBooleans(removeKeyFromObject(booleans, key))
		// setFilters(filters['advancedSearch'].splice(key, 1))

	}

	const clearAdvancedSearch = () => {
		clearSearch(); 
		setArgLength(1) 
		setQuery({})
		setTypes({})
		setTerm({})
		setFields({})
		setBooleans({})
	}

	const FormRow = (key) => {
		return (
			<Form.Row  className = "mt-2" key = {key} >
				{argLength > 1 && key > 0 && <Col md={1} className = "align-items-center text-right">
					<FaTimes id={key} color={"#0032A0"} className = "mx-auto" style={{cursor: "pointer", textAlign: 'right'}} onClick = {(e) => deleteRow(key)}></FaTimes>
				</Col> }
				<Col md={4}>
					<Form.Control name='query' type="input" value={query[key] ? query[key] : (key === 0 &&search != "''" ? search : null)} placeholder="Enter Search Term or Phrase" onChange = {(e) => setQuery({...query, [key]: e.target.value})}>
					</Form.Control>
				</Col>
				<Col>
					<Form.Control name ='term' as="select" value={terms[key] || null} defaultValue="Includes" onChange={(e) => setTerm({...terms, [key]: e.target.value})}>
						<option id="in">In</option>
						<option id="mustNot">Not In</option>
					</Form.Control>
				</Col>
				<Col>
					<Form.Control name = 'field' as="select" onChange={(e) => setFields({...fields, [key]: e.target.value})} value={fields[key] || null}>
						<option id = "title">Title</option>
						<option id = "text">Text</option>
						<option id = "wildcard">Any Field</option>
					</Form.Control>
				</Col>
				<Col>
					<Form.Control name='type' as="select" onChange={(e) => setTypes({...types, [key]: e.target.value})} value={types[key] || null}>
						<option id = 'text'>Keyword(s)</option>
						<option id = 'phrase'>Exact Phrase</option>
					</Form.Control>
				</Col>
				<Col>
					<Form.Control name='boolean'  style = {{display: (argLength - 1) == key && "none"}} as="select" onChange={(e) => setBooleans({...booleans, [key]: e.target.value})} value={booleans[key] || null}>
						<option id = "must">And</option>
						<option id = "should">Or</option>
					</Form.Control>
				</Col>
			</Form.Row>
		)
	}

	return (
		<>
			<Row >
				<Col>
					<Form.Row >
						<Form.Group as={Col} onKeyDown={keyDownHandler}>
							<InputGroup >
								<Form.Control
									id='searchbar'
									type="search"
									placeholder={"Search"}
									// className="py-2 mr-5"
									aria-label="Search"
									aria-describedby="search-bar"
									onChange={handleChange}
									clearSearch={() => clearSearch()}
									value={filters['advancedSearch'] ? 'Advanced Search' : (search !== "''" ? search : null)}
									style={{ borderTopLeftRadius: '40px', borderBottomLeftRadius: '40px', borderRight: '0px', outline: 'none !important' }}
									disabled={filters['advancedSearch']}
								/>
								<InputGroup.Append >
									<InputGroup.Text className="border-1 bg-white" style={{ borderTopRightRadius: '40px', borderBottomRightRadius: '40px', borderLeft: '0px !important', outline: 'none !important' }}>
										<FaSearch as={'button'} onClick={(e) => setSubmit(true)} style={{ cursor: "pointer" }}></FaSearch>
									</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
			<Row>
				<Col className = "ml-2 mb-2">
					{Array.isArray(filters['advancedSearch']) && filters['advancedSearch'].map((av, key) => {
					let text = key > 0 ? av.boolean.toUpperCase().concat(' ', av.query, ' ', av.term,  ' ', av.field) : av.query.concat(' ', av.term, ' ', av.field); 
					return (<Badge 
								key = {av.query} 
								className = "m-1" variant="dark"> 
								{text} 
							</Badge>)})}
					{!Array.isArray(filters['advancedSearch']) && filters['advancedSearch'] && <Badge variant="dark"> {filters['advancedSearch'].query + " " + filters['advancedSearch'].term + " " + filters['advancedSearch'].field} </Badge>}
				</Col>

			</Row>
			<Row>
				<Col className = "mb-2">
					<Button className = "rounded-pill" style = {{backgroundColor: filters['advancedSearch'] ? '#0032A0' : 'white', color: filters['advancedSearch'] ? 'white' : '#0032A0'}}  onClick={() => setShowModal(true)}>Advanced Search <FaExternalLinkAlt></FaExternalLinkAlt></Button>
				</Col>
				<Modal
					size={'xl'}
					show={showModal}
					onHide={onHide}
					variant="success"
				><Form onSubmit={(e) => {e.preventDefault();  queryBuilder(e.target.elements)}}>
					<Modal.Header className="border-0 bg-transparent" closeButton>
					<h4 className = "mb-0"><strong className="mr-auto mb-1">Advanced Search Settings</strong></h4>
					</Modal.Header>
					
					<Modal.Body key={argLength}>
					<p className = "lead"> Use the query builder to refine your search parameters. You can define multiple keywords to include or not include in the search. Terms and phrases can be searched in 
					the title, text or all fields.</p>
							{Array(argLength).fill(0).map((x, y) => x + y).map(key => {
								// console.log("key", key);
								return (
									FormRow(key)
								)
							})}
						<Row>
							<Col className="ml-2 mt-3">
								Add New Row <FaPlusCircle as={"button"} color={"#0032A0"} onClick = {() => setArgLength(prevState => prevState+=1)} style={{cursor: "pointer"}}></FaPlusCircle>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button className = "rounded-pill" variant="danger" onClick = {() => clearAdvancedSearch()}> Clear Search</Button>
						<Button type='submit' className = "rounded-pill" disabled={error || Object.keys(query).length === 0 && (!filters.hasOwnProperty('advancedSearch') || filters['advancedSearch']['query'] == "")}>Apply Changes</Button>
					</Modal.Footer>
					</Form>
				</Modal>
			</Row>
		</>
	)
}

