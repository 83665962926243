import React, { useState } from 'react';
import { Form, Button, Card, Container, Row, Col } from 'react-bootstrap'
import { API_ENDPOINT } from '../utils/endpoint';

export const ForgotPassword = () => {

	const [email, setEmail] = useState(null)
	const [sent, isSent] = useState(false)

	const forgottonpasswordLink = async () => {
		const formdata = {
			"email": email
		}
		await fetch(`${API_ENDPOINT}/auth/users/forgot-password`, {
			method: 'POST',
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(formdata)
		})
			.then(res => res.json())
			.then(response => {isSent(true)})
	}

	const callSubmit = () => {
		forgottonpasswordLink()
		isSent(true)
	}

	return (
		<>
			<Container style={{ width: '30%' }}>
				<Row>
					<Col>
						<Card className="mt-5">
							<Card.Header className="bg-white border-0">
								Forgot Password
							</Card.Header>
							<Card.Body>
								{!sent &&
									<Form onSubmit={callSubmit}>
										<Form.Group controlId="formLoginEmail">
											<Form.Label>Email</Form.Label>
											<Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
										</Form.Group>
										<Button className="mb-2 rounded-pill" variant="primary" type="submit" block>
											Request Reset Password Link
										</Button>
									</Form>}
								{sent && <div> Password reset link has been sent to {email} </div>}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	);


}