import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FaBorderNone } from 'react-icons/fa';

const DefaultSpinner = () => {
	return (
		<Spinner
			animation="border"
			variant="primary"
			role="status"
			style={{
				height: 100,
				width: 100,
				position: "fixed",
				top:  "50%",
				left: "50%"
			}}>
		</Spinner>
	)
}


export default DefaultSpinner