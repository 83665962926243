export const customSelect = {
    
    control: (style) => ({
        ...style,
        borderRadius: "0px 40px 40px 0px !important",
        boxShadow: '0px',
        '&:focus': {
            border: '1px solid black',
        },
		margin: "0px 0px 0px 0px",

    }),
    container: (provided) => ({
        ...provided,
        width: 250,
        borderColor: '#fff', 
        color: 'black',


    }), 
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu : (styles) => ({
        ...styles, 
        zIndex: "9999 !important"
    })
};

export const limitSelect = {
    
    control: (style) => ({
        ...style,
        borderRadius: "0px  !important",
        boxShadow: '0px',
        '&:focus': {
            border: '1px solid black',
        },
		margin: "0px 0px 0px 0px",

    }),
    container: (provided) => ({
        ...provided,
        width: 150,
        borderColor: '#fff', 
        color: 'black',


    }), 
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu : (styles) => ({
        ...styles, 
        zIndex: "9999 !important"
    })
};

export const reportSelect = {
    
    control: (style) => ({
        ...style,
        borderRadius: "40px 40px 40px 40px !important",
        boxShadow: '0px',
        '&:focus': {
            border: '1px solid black',
        },
		margin: "1px 1px 1px 1px",

    }),
    container: (provided) => ({
        ...provided,
        width: 350,
        borderColor: '#fff', 
        color: 'black',


    }), 
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu : (styles) => ({
        ...styles, 
        zIndex: "9999 !important"
    })
};