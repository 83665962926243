import React from "react";
import ReactDOM from "react-dom";
import { Badge, Col, Row } from 'react-bootstrap';
import { Form } from "react-bootstrap";
import { ContactModal } from './contacts';

class EmailEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.recipients || [],
      value: "",
      error: null
    };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.recipients !== this.state.items) {
      this.setState({items: nextProps.recipients});
    }
  }
  
  handleSavedListItems = list => {
    this.setState({
      items: list, 
      value: "", 
    })
    this.props.setReceipients(
      list
    )
  }


  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: ""
        });
        this.props.setReceipients(
          [...this.state.items, this.state.value]
        )
      }
    }
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = item => {
    this.setState({
      items: this.state.items.filter(i => i !== item)
    });
    this.props.setReceipients(
      this.state.items.filter(i => i !== item)
    )
  };

  handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));
      this.setState({
        items: [...this.state.items, ...toBeAdded]
      });
      this.props.setReceipients(
        [...this.state.items, ...toBeAdded]
      )
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.some(x => x.toLowerCase() == email.toLowerCase());
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    return (
      <>
      <ContactModal setContacts={this.handleSavedListItems} receipients={this.state.items}/>
        <Row>
          <Col>
            {this.state.items.map(item => (
              <Badge className="mt-2 mb-2 mr-2 ml-2" variant="secondary" key={item}>
                {item}
                <Badge
                  className="mt-1 mb-1 ml-1 mr-1"
                  variant="light"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                >
                  &times;
                </Badge>
              </Badge>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>

            <Form>
              <Form.Control
                value={this.state.value}
                placeholder="Type or paste email addresses and press `Enter`..."
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                onPaste={this.handlePaste}
              />
            </Form>
          </Col>
        </Row>
        {this.state.error && <p className="error">{this.state.error}</p>}
      </>
    );
  }
}

export default EmailEntry