import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDashboardProvider } from '../contexts/dashboardContext';

export const LayoutDesigner = () => {

	const {
		INITAL_SELECTIONS,
		sources,
		selections,
		setIndicatorSelections,
		setSelections,
		resetLayout,
		setLoading,
		setInitialSettings,
		setShowSettings,
		setShowSource,
		setShowDate,
		showDate,
		showSource,
		indicatorSelections,
		addNewIndicatorItem, 
		indicatorSettings, 
		setIndicatorSettings,
		setIndicatorLayout, 
		saveIndicatorSettings } = useDashboardProvider()

	const [localSelections, setLocalSelections] = useState(indicatorSelections)
	const [displayDate, setDisplayDate] = useState(showDate)
	const [displaySource, setDisplaySource] = useState(showSource)


	const categories = Object.keys(sources.reduce((r, { category }) => (r[category] = '', r), {}))


	const removeItem = (item) => {
		let index = localSelections.indexOf(item)
		setLocalSelections([
			...localSelections.slice(0, index),
			...localSelections.slice(index + 1)
		]);
		let oldIndicatorSettings = indicatorSettings; 
		let idx = oldIndicatorSettings.findIndex(indicator => indicator.title === item);
		oldIndicatorSettings[idx].show = false;
		setIndicatorSettings(oldIndicatorSettings);
		setIndicatorLayout(indicatorLayout => indicatorLayout.filter(l => l.i != item))
	}

	const updateSource = (e) => {
		let value = e.target.id
		if (localSelections.includes(value)) {
			removeItem(value);
		} else {
			setLocalSelections(localSelections => [...localSelections, value])
			addNewIndicatorItem(value);
			let oldIndicatorSettings = indicatorSettings; 
			let idx = oldIndicatorSettings.findIndex(indicator => indicator.title === value);
			oldIndicatorSettings[idx].show = true;
			setIndicatorSettings(oldIndicatorSettings);
			// console.log(oldIndicatorSettings);
		}
	}

	const restoreDefault = () => {
		resetLayout();
		setLoading(true);
		setShowSettings(false);
		setInitialSettings();
		setLocalSelections(INITAL_SELECTIONS);
		setShowDate(false); 
		setShowSource(false);
	}

	const submitSources = () => {
		resetLayout();
		setLoading(true);
		setShowSettings(false);
		setSelections(localSelections);
		setIndicatorSelections(localSelections);
		setShowDate(displayDate);
		setShowSource(displaySource);
		saveIndicatorSettings();
	}

	const disabled =
		(indicatorSelections === localSelections) &&
		(showDate === displayDate) &&
		(showSource === displaySource)


	return (
		<Form>
			{categories.map((category) => (
				<div className="mb-3">
					<p className="lead">Macroeconomic Indicators</p>
					{sources.filter((source) => source.category === category).map((s, i) => {
						let isChecked = indicatorSelections.includes(s.name) ? true : false;
						return <Form.Check
							className="ml-3 mr-3 mt-3"
							// inline key={s.name}
							label={s.name}
							id={s.name}
							type='checkbox'
							defaultChecked={isChecked}
							onChange={(e) => updateSource(e)}>
						</Form.Check>
					})}
					
				</div>
			))}
			{/* <div className="mb-3">
				<p className="lead">Display Settings</p>
				<Form.Check
					className="mt-3"
					type="checkbox"
					id='date'
					label="Show Update Date"
					defaultChecked={displayDate}
					onChange={() => setDisplayDate(prevState => !prevState)}
				>
				</Form.Check>
				<Form.Check
					className="mt-3"
					type="checkbox"
					id='source'
					label="Show Source"
					defaultChecked={displaySource}
					onChange={() => setDisplaySource(prevState => !prevState)}
				>
				</Form.Check>
			</div> */}
			<hr></hr>
			<Button className="rounded-pill" onClick={() => submitSources()} > Update & Save Layout</Button>
			{/* <Button className="rounded-pill ml-4" variant="success" onClick={() => restoreDefault()}> Save Layout </Button> */}
		</Form>
	)

}