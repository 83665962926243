import { API_ENDPOINT } from '../utils/endpoint';

export const RemoveUser = (user_id) => {
	const token = localStorage.getItem('token')
	fetch(`${API_ENDPOINT}/auth/users/${user_id}`, {
		headers : {
			'Content-Type': 'application/json', 
			'Authorization': `Bearer ${token}`
		},
		method: 'DELETE'
	}).then((response) => console.log(response))

}

export const UpdateUser = (user_id, userInfo) => {
	const token = localStorage.getItem('token') 
	fetch(`${API_ENDPOINT}/auth/users/${user_id}`, {
		headers: {
			'Content-Type': 'application/json', 
			'Authorization': `Bearer ${token}`
		}, 
		method: 'PATCH', 
		body: JSON.stringify(userInfo)
	}).then((response) => console.log(response))
}
