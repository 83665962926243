import React, { useState } from "react";
import { Table, Badge, Card, Form, Button } from 'react-bootstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import { FaFolderOpen, FaFolder, FaTrash, FaEllipsisH } from "react-icons/fa";
import { ModalAlert } from '../components/toast'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { DirectoryModal } from "./modal";
import { API_ENDPOINT } from "../utils/endpoint";

export const DirectoryTable = ({
	setUpdateDirectory,
	clickDirectory,
	data }) => {


	const [expandedParents, setExpandedParents] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [clickedDirectory, setClickedDirectory] = useState('')
	const [directory, setDirectory] = useState('')

	const onHide = () => {
		setShowModal(false)
	}


	const columns = ['Directory', 'Tags', 'Count of Files']


	const directories = data.filter((d) => d.isChild === false)
	const subdirectories = data.filter((d) => d.isChild === true)

	const handleRowClick = (row) => {
		const currentExpandedRows = expandedParents
		const isRowCurrentlyExpanded = currentExpandedRows.includes(row.Directory)

		const newExpandedRows = isRowCurrentlyExpanded ?
			currentExpandedRows.filter(id => id !== row.Directory) :
			currentExpandedRows.concat(row.Directory);

		setExpandedParents(newExpandedRows)
	}

	const parentRowIcon = (row) => {
		return (expandedParents.includes(row.Directory) ?
			<FaFolderOpen
				as="button"
				cursor="pointer"
				onClick={() => handleRowClick(row)}>
			</FaFolderOpen> :
			<FaFolder
				as="button"
				cursor="pointer"
				onClick={() => handleRowClick(row)}>
			</FaFolder>)
	}

	const returnTags = (taginfo) => {
		let list = Array.isArray(taginfo) ? taginfo : Array.from([taginfo])
		let tags = new Set([].concat(...list.filter(i => i).map(t => t.split(','))))
		return Array.from(tags).map(tag => { return (<Badge id={tag} className="ml-2" variant={'info'}> {tag} </Badge>) })
	}

	const renderTooltip = (props) => (
			<Tooltip id="button-tooltip" {...props}>
				Add Subdirectory
			</Tooltip>
		);

	const clickEllipsis = (directory) => {
		setShowModal(true); 
		setClickedDirectory(directory)
	}
	

		const renderItem = (item) => {
			const itemRows = [
				<tr key={"row-data-" + item.Directory}>
					<td style={{ width: '40px' }}>
						{parentRowIcon(item)}
					</td>
					<td
						id={item.Directory}
						onClick={clickDirectory}
						style={{ cursor: "pointer" }}>
						{item.Directory}
					</td>
					<td>{returnTags(item.Tags)}</td>
					<td>{item['Count of Files']}</td>
					<OverlayTrigger
						placement="right"
						delay={{ show: 50, hide: 200 }}
						overlay={renderTooltip}
					>
						<td style={{ width: '40px', cursor: 'pointer' }} onClick={() => clickEllipsis(item.Directory)}><FaEllipsisH></FaEllipsisH></td>
					</OverlayTrigger>
				</tr>
			];
			if (expandedParents.includes(item.Directory)) {
				subdirectories.filter(s => s.parent == item.Directory).forEach(item => {
					itemRows.push(
						<tr className="subdirectory" key={"row-expanded-" + item.child}>
							<td
								className="subdirectorycell"
								id={item.Directory} onClick={clickDirectory}
								style={{ cursor: "pointer" }}>
								{item.child}
							</td>
							<td>{returnTags(item.Tags)}</td>
							<td>{item['Count of Files']}</td>
						</tr>
						)
					}
				)
			}
			return itemRows
		}

		const addDirectory = async (event) => {
			event.preventDefault()
			const token = localStorage.getItem('token')
	
			await fetch(`${API_ENDPOINT}/fields/add`, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({ 'field': 'directory', 'value': [clickedDirectory + '/' + directory] })
			}
			)
				.then((response) => response.json())
				.then(res => {
					// setUpdateDirectory(true)
					setShowModal(false)
				})
		}


		const modalText = <Form><Form.Label>Subdirectory</Form.Label><Form.Control type="input" onChange={(e) => setDirectory(e.target.value)}></Form.Control></Form>
		return (
			<>
				<Card className={'mt-2'} >
					<Card.Body >
						<ModalAlert 
							show={showModal} 
							onHide={onHide} 
							header={"Add Subdirectory to " + clickedDirectory} 
							text={modalText} 
							children={<Button onClick = {(e) => addDirectory(e)}>Submit</Button>}>
						</ModalAlert>
						<Table className="table-hover w-auto table-condensed" >
							<thead style={{ backgroundColor: '#ffffff', color: '#0032A0' }}>
								<tr >
									<th style={{ width: '40px' }} key={'icon'}></th>
									{columns.map((column) => {
										return <th key={column}>{column}</th>
									})}
									<th style={{ width: '40px' }}></th>

								</tr>
							</thead>
							<tbody style={{ height: 'auto' }} >
								{directories.map((row) => {
									return renderItem(row)
								})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</>
		)
	}