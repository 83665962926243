import React, { useState } from 'react';
import { ModalFooter } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

export const ModalAlert = ({ show, onHide, header, text, children }) => {
	return (

			<Modal
			 	
				style={{
					position: 'absolute',
					top: 0,
					right: 0,
				}}
				show={show}
				onHide={onHide}
				variant = "success"
			>
				<Modal.Header className="border-0 bg-transparent" closeButton>
					<strong className="mr-auto">{header}</strong>
				</Modal.Header>
				<Modal.Body>
					{text}
				</Modal.Body>
				<Modal.Footer>
					{children ? children: null}
				</Modal.Footer>
			</Modal>

	)
}

