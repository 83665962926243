import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { Defaultnav } from '../components/navbar'
import { Container, Row, Col, Form, Button, Modal, Alert, Spinner, Tab, Nav, Tabs, Carousel } from 'react-bootstrap';
import DropDownContainer from '../components/dropdowntree'
import 'react-dropdown-tree-select/dist/styles.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import _ from 'lodash'
import EmailEntry from '../components/emailentry';
import { ModalAlert } from '../components/toast';
import { useReportContextProvider } from '../contexts/reportContext';
import { API_ENDPOINT } from '../utils/endpoint';
import Select from 'react-select';
import { reportSelect } from '../lib/style';
import { ImageDiv } from '../components/image'
import ImageResize from 'quill-image-resize-module-react'
import { FaXing } from 'react-icons/fa';

Quill.register('modules/imageResize', ImageResize);

const modules = {
	imageResize: {
	   parchment: Quill.import('parchment'),
	   modules: ['Resize', 'DisplaySize']
	}
  }


const ReportBuilder = () => {

	const {
		fileTree,
		selectedArticle,
		content,
		setContent,
		subject,
		setSubject,
		receipients,
		setReceipients,
		showToast,
		message,
		sendingStatus,
		setSendingStatus,
		onHideToast,
		onContentChange,
		sendReport,
		onAction,
		onNodeToggle,
		clearAll,
		onChange,
		setSelectedArticle,
		getFileStructure,
		pingInference,
		selectedArticleList,
		saveReport,
		showSaveModal, 
		setShowSaveModal, 
		isNewReport, 
		setIsNewReport, 
		reportList, 
		setReportList,
		setReport, 
		report,
		onChangeReport, 
		getImageUrl, 
		updateReport, 
		sourceIDs, 
		refreshReportSources
	} = useReportContextProvider()
	
	const [urlForImage, setUrlForImage] = useState(null)

	const onHideSaveModal = () => { setShowSaveModal(false) }
	const onShowSaveModal = () => { setShowSaveModal(true) }

	const clearReportButton = <Button className="rounded-pill" variant="danger" onClick={clearAll} >Clear</Button>

	const history = useHistory();

	const debouncePingInference = _.debounce(() => {pingInference()}, 180000, {'leading': true, 'trailing': false})

	useEffect(() => {
		getFileStructure();
		debouncePingInference();
	}, [])

	const onClickDeleteText = async (id, text) => {
			const token = localStorage.getItem('token'); 
			const data = {
				id: id,
				text: text,
			}
			// console.log(data)
			await fetch(`${API_ENDPOINT}/data/store/delete/text`, {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json'},
				body: JSON.stringify(data)
			})
				.then(res => res.json())
				.then(res => getFileStructure())
		}
	
		const onClickDeleteSummary = async (id, text, title) => {
			const token = localStorage.getItem('token'); 
			const data = {
				id: id,
				text: text,
				title: title,
			}
			// console.log(data)
			await fetch(`${API_ENDPOINT}/data/store/delete/summary`, {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json'},
				body: JSON.stringify(data)
			})
				.then(res => res.json())
				.then(res => getFileStructure())
		}

		const onClickDeleteImage = async (id, image) => {
			const token = localStorage.getItem('token'); 
			const data = {
				id: id,
				image: image,
			}
			// console.log(data)
			await fetch(`${API_ENDPOINT}/data/store/delete/image`, {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json'},
				body: JSON.stringify(data)
			})
				.then(res => res.json())
				.then(res => getFileStructure())
		}

	// useEffect(() => {
	// 	if (history.action === 'POP') {
	// 		getFileStructure()
	// 		if (sourceIDs.length > 0) {
	// 			refreshReportSources(sourceIDs)
	// 		}
			
	// 	}

	// }, [history.action])


	const saveForm =
		<Form>
			<Form.Label>Name for Report</Form.Label>
			<Form.Control type="input" onChange={(e) => setReport({ ...report, ['name']: e.target.value })} ></Form.Control>
			<Form.Check
				className="mt-3"
				type="checkbox"
				id='date'
				label="Set as Complete"
				onChange={(e) => setReport({ ...report, ['status']: e.target.checked ? 'complete' : 'in-progress' })}
			>
			</Form.Check>
		</Form>

	const updateForm =
		<Form>
			<Form.Label>Name for Report</Form.Label>
			<Alert variant="warning">Changing the name will save the report as a new report </Alert>
			<Form.Control type="input" defaultValue={report.title} onChange={(e) => setReport({ ...report, ['name']: e.target.value })} ></Form.Control>
			<Form.Check
				className="mt-3"
				type="checkbox"
				id='date'
				label="Set as Complete"
				isChecked={report.status === 'complete'}
				onChange={(e) => setReport({ ...report, ['status']: e.target.checked ? 'complete' : 'in-progress' })}
			>
			</Form.Check>
		</Form>

	const saveButton = isNewReport ?  <Button onClick={() => saveReport()}>Save</Button> : <Button onClick={() => updateReport()}>Update</Button>

	const saveHeader = "Save Report"
	const updateHeader = "Update Report"

	const saveModalInfo = {
		form: isNewReport ? saveForm : updateForm,
		header: isNewReport ? saveHeader : updateHeader,
	}

	const truncateWithEllipses = (text, max) => {
    	return text.substr(0,max-1)+(text.length>max?'.....':''); 
}

	const defaultDropdown = report.title && !isNewReport ? {'label': report.title, 'value': report.title} : null

	return (
		<>
			<Defaultnav />
			<Container>
				<Row>
					<Col className="mt-5 mb-3">
						<h1>Report Builder</h1>
					</Col>
					<Col>
						<ModalAlert
							show={showToast}
							onHide={onHideToast}
							header={'Email Status'}
							text={message}
							children={clearReportButton}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<ModalAlert
							show={showSaveModal}
							onHide={onHideSaveModal}
							header={saveModalInfo.header}
							text={saveModalInfo.form}
							children={saveButton}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Row>
							<Col className="mb-3">
								<p className="lead"> Select From Saved Reports or create a new report. </p>
								<Alert variant={'danger'} >  If you change the report without saving, your progress will be lost.</Alert>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<h5> Select a Report </h5>
								<Select
									key={report}
									options={reportList.map(report => { return { 'value': report.title, 'label': report.title } })}
									styles={reportSelect}
									onChange={(e) => onChangeReport(e.value)}
									placeholder="No Report Selected"
									value={defaultDropdown}
								/>
							</Col>
							<Col className = "mt-3">
								<Button className = "rounded-pill mt-3" variant="success" onClick={() => clearAll()}>Create New Report</Button>
							</Col>
						</Row>

					</Col>
				</Row>
				<Row>
					<Col className="mt-2">
						<h5> Available Content</h5>
						<p>Select the sources that you'd like to include in the report. Any summaries, images or text that has been saved from the source will 
							be available to include in the report Additionally, you can click the "go to data extraction tool" to add summaries, 
							images, or select raw text from any article.
						</p>
						<DropDownContainer
							
							// className="bootstrap-demo"
							// mode='radioSelect'
							keepOpenOnSelect={true}
							data={fileTree}
							onChange={onChange}
							onAction={onAction}
							onNodeToggle={onNodeToggle}
							texts={{placeholder: "Select sources"}}
						/>
					</Col>

				</Row>
				{Object.keys(selectedArticleList).length !== 0 &&
					<>
						<Tabs defaultActiveKey="Summaries" key={report}>
							<Tab eventKey="Summaries" title="Summaries" key={fileTree}>
								{/* {!selectedArticleList.map(article => article.summaries) &&
									<Alert variant="warning">
										No Summaries exist for this article. Click the button below to generate one!
									</Alert>} */}
									<Tab.Container 
									key={selectedArticleList[0].title}
									transition={false} 
									className="border" 
									id="reportSummaryContainer" 
									defaultActiveKey= {selectedArticleList[0].title}>
										<Row>
											<Col sm={6}>
												<Nav variant="pills" className="flex-column">
													{selectedArticleList.map(s => {
														let title = s.title
														return (<Nav.Item key={title}>
															<Nav.Link className = "mt-3"  onClick = {() => 
															setSelectedArticle({title: title, 
																id: s.id, 
																key: s.key,
																summaries: s.summaries, 
																directory: s.directory, 
																texts: s.extracted_text})} 
															eventKey={title}>{truncateWithEllipses(title, 80)}</Nav.Link>
														</Nav.Item>)
													})}
												</Nav>
											</Col>
											<Col sm={6}>
												<Tab.Content onOverflow={"scroll"}>
													{selectedArticleList.map(article => {
														let title = article.title;
														let summaries = article.summaries;
														return (
															<Tab.Pane eventKey={title}>
																{summaries !== undefined ? summaries.map(summary => {
																	let header = _.keys(summary).pop()
																	let summarytext = summary[header]
																	return (
																		<div className="mt-2 mb-2 p-3" style={{ backgroundColor: '#f5f5f5' }}>
																			<Alert onClick={() => onClickDeleteSummary(article.id, summarytext, header)}dismissible>
																				<h4>{header}</h4>
																				<p>
																					{summarytext}
																				</p>
																			</Alert>
																			<Button
																				className="rounded-pill"
																				variant="success"
																				onClick={(e) => onContentChange({ summary: summarytext, title: header })}
																			>
																				Add to Report
																			</Button>

																		</div>
																	)
																}) :<Alert variant="warning" className="mt-2 ml-2">
																No Summaries exist for this article. Click the button below to generate one!
															</Alert> }
															</Tab.Pane>
														)
													})}
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
							</Tab>
							<Tab eventKey="Text" title="Text" > 
							
							<Tab.Container key={selectedArticleList[0].title} transition={false} className="border" id="reportTextContainer" defaultActiveKey= {selectedArticleList[0].title}>
										<Row>
											<Col sm={6}>
												<Nav variant="pills" className="flex-column">
													{selectedArticleList.map(s => {
														let title = s.title
														return (<Nav.Item key={title}>
															<Nav.Link className = "mt-3"  onClick = {() => 
															setSelectedArticle({title: title, 
																id: s.id, 
																key: s.key,
																summaries: s.summaries, 
																directory: s.directory, 
															    texts: s.extracted_text})} 
															eventKey={title}>{truncateWithEllipses(title, 80)}</Nav.Link>
														</Nav.Item>)
													})}
												</Nav>
											</Col>
											<Col sm={6}>
												<Tab.Content>
													{selectedArticleList.map(article => {
														// console.log(selectedArticleList)
														let title = article.title;
														let texts = article.extracted_text;
														return (
															
															<Tab.Pane eventKey={title}>
																{texts !== undefined ? texts.map((text, key) => {
														
																	return (
																		<div key={key} className="mt-2 mb-2 p-3" style={{ backgroundColor: '#f5f5f5' }} >
																			
																			<Alert onClose={() => onClickDeleteText(article.id, text)} dismissible>
																				<p>
																					{text}
																				</p>
																			</Alert>
																			<Button
																				className="rounded-pill"
																				variant="success"
																				key={key}
																				onClick={(e) => onContentChange({ summary: text, title: '' })}
																			>
																				Add to Report
																			</Button>

																		</div>
																	)
																}) :<Alert variant="warning" className="mt-2 ml-2">
																No text has been extracted from this article. Click the button below to extract text!
															</Alert> }
															</Tab.Pane>
														)
													})}
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
							
							 </Tab>
							<Tab eventKey="Images" title="Images" onOverflow={"scroll"}> 
							<Tab.Container key={selectedArticleList[0].title} transition={false} className="border" id="reportImageContainer" defaultActiveKey= {selectedArticleList[0].title}>
										<Row>
											<Col sm={6}>
												<Nav variant="pills" className="flex-column">
													{selectedArticleList.map(s => {
														let title = s.title
														return (<Nav.Item key={title}>
															<Nav.Link className = "mt-3"  onClick = {() => 
															setSelectedArticle({title: title, 
																id: s.id, 
																key: s.key,
																summaries: s.summaries, 
																directory: s.directory, 
															    texts: s.extracted_text, 
															    images: s.extracted_image})} 
															eventKey={title}>{truncateWithEllipses(title, 80)}</Nav.Link>
														</Nav.Item>)
													})}
												</Nav>
											</Col>
											<Col sm={6}>
												<Tab.Content >
													{selectedArticleList.map(article => {
														
														let title = article.title;
														let images = article.extracted_image;
														let imageExists = images !== undefined
														return (
															<Tab.Pane eventKey={title}>
																<Carousel interval={null} controls={imageExists} indicators={imageExists}>
																{imageExists ? images.map((image, key) => {
																	return (
																		<Carousel.Item>
																			<Alert onClose={() => onClickDeleteImage(article.id, image)} dismissible>
																			<ImageDiv image={image} onContentChange={onContentChange}/>
																			</Alert>
																		</Carousel.Item>
																	
																	)
																}) :<Alert variant="warning" className="mt-2 ml-2">
																No images have been extracted from this article. Click the button below to extract an image!
															</Alert> }
															</Carousel>
															</Tab.Pane>
														)
													})}
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
							</Tab>
						</Tabs>
						<Row>
							<Col>
								<Link to="/inference">
									<Button
										variant="link"
										style={{ color: '#ffb236' }}
										className="rounded-pill mt-4">
										<strong> Go to Data Extraction Tool</strong>
									</Button>
								</Link>
							</Col>
						</Row>
						<hr></hr>
					</>}

				<Row className="mt-3">
					<Col>
						<Form.Label>Add Receipients</Form.Label>
						<EmailEntry setReceipients={setReceipients} recipients={receipients} />
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Form>
							<Form.Label> Subject </Form.Label>
							<Form.Control type="text" placeholder={"Enter a Subject"} value={subject} onChange={(e) => setSubject(e.target.value)}></Form.Control>
						</Form>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Form>
							<Form.Label>Content</Form.Label>
							<ReactQuill style={{ height: '550px' }} theme="snow" value={content} onChange={setContent} modules={modules} />
						</Form>
					</Col>
				</Row>
				<Row className="mt-5 mb-5">
					<Col>
						<Button style={{ width: '100px' }} variant="primary" className="rounded-pill ml-2" disabled={receipients.length === 0} onClick={(e) => {
							sendReport();
							setSendingStatus(true);
						}}>
							{sendingStatus === true ?
								<Spinner
									as="span"
									variant="light"
									size="sm"
									role="status"
									aria-hidden="true"
									animation="border" />
								: "Send"}
						</Button>
						<Button variant="success" className="rounded-pill ml-2 mr-2" onClick={() => onShowSaveModal()}> { isNewReport ? "Save Report" : "Update"}</Button>
						<Button variant="danger" className="rounded-pill ml-2" onClick={clearAll} >Clear Report</Button>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default ReportBuilder