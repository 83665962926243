import React, { useState, useEffect } from 'react';
import { Modal, Card, Col, Row, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { API_ENDPOINT } from '../utils/endpoint';

export const ContactModal = ({ setContacts, receipients }) => {

	const [show, setShow] = useState(false);
	const [addedContacts, setAddededContacts] = useState([])
	const [contactListName, setContactListName] = useState(receipients)
	const [options, setOptions] = useState([])
	const [updated, setUpdated] = useState(false)
	const [showAlert, setShowAlert] = useState(false)

	const showModal = () => { setShow(true) }
	const onHide = () => { setShow(false) }

	// const options = [{ value: ['matt.gray@ec3co.com', 'meagan.gatley@ec3co.com', 'yvonne.levy@marmonfoodservices.com', 'preeti.shastri@marmonfoodservices.com'], label: 'Test List' }]

	const addContactList = async (e) => {
		e.preventDefault();
		const contactinfo = {
				'name': contactListName, 
				'contacts': receipients
			}
			await fetch(`${API_ENDPOINT}/contacts`, {
				headers: {
					'Content-Type':'application/json'
				},
				method: 'POST',
				body: JSON.stringify(contactinfo),
			}).then(d => {
				setUpdated(prevState => !prevState)
				setShowAlert(true)});
	
		};

	
	useEffect(() => {
		const fetchContactLists = async () => {
			const token = localStorage.getItem('token')
			await fetch(`${API_ENDPOINT}/contacts/saved`, {
				headers: {
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${token}`,
				}, 
				method: 'GET'
			}).then(response => response.json())
			.then(result =>  
				{
					setOptions(result.map(res => {return {value: res.contacts, label: res.name}}))
				
				}
			)
		}
		fetchContactLists()

	}, [show, updated])

	
	useEffect(() => {
		if (showAlert === true){
			window.setTimeout(() => {setShowAlert(false)}, 1500)
		}
	}, [showAlert])

	const selectList = (e) => {
		let contacts = e.value 
		setAddededContacts(contacts)
	}

	const submitList = () => {
		setContacts(addedContacts)
		setShow(false)
	}


	return (
		<>
			<Row>
				<Col>
					<Button
						className="rounded-pill mb-3 mt-2"
						onClick={showModal}
						size={'sm'}
					>
						Contact Lists
					</Button>
				</Col>
			</Row>
			<Modal
				size='lg'
				show={show}
				onHide={onHide}
				backdrop="static"
				keyboard={false}>
				<Modal.Header
					closeButton
					className="border-0">
				<Modal.Title>Contact Lists</Modal.Title>
					
				</Modal.Header>
				
				<Modal.Body>
				
				<Alert variant = "info"> Add contacts to a new list or set a saved list as the current contacts </Alert>
					<Form>
						<h6>Save current contacts as a list</h6>
						<Form.Label> Enter a Name for Contact List</Form.Label>
						<Form.Control
							type="input"
							placeholder="List of Contacts"
							onChange={(e) => setContactListName(e.target.value)}
						>

						</Form.Control>
					</Form>
					<Button className = "mt-3 rounded-pill" onClick={addContactList}>Save</Button>
					<Alert variant = "success" clasName = "mt-2 mb-2" show={showAlert}>Contacts saved under:  {contactListName} </Alert>
					<hr></hr>
					<h6>Select a Contact List to Add Receipients</h6>
					<Alert variant = "danger"> This will overwrite the current contacts</Alert>
					<Select
						name={'contacts'}
						options={options}
						onChange={(e) => selectList(e)}
					/>
					<Button className = "mt-3 rounded-pill" onClick = {submitList}>Select</Button>
					<hr></hr>

				</Modal.Body>
			</Modal>
		</>
	)

}