import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Container,
	Card,
	Col,
	Row,
	Form,
	Button,
	Spinner,
	Alert
} from 'react-bootstrap';
import { API_ENDPOINT } from "../utils/endpoint";

export const ResetPassword = () => {

	// const location = useLocation()
	const query = new URLSearchParams(useLocation().search)
	const token = query.get('token')


	const [newPassword, setNewPassword] = useState(null)
	const [confirmPassword, setConfirmPassword] = useState(null)
	const [sent, isSent] = useState(false)
	const [confirmed, setConfirmed] = useState(false)
	const [error, setError] = useState(null)

	const invalid = !newPassword ||
		!confirmPassword ||
		confirmPassword !== newPassword

	const sendPasswordReset = async () => {
		const data = {
			"token": token,
			"password": newPassword
		}
		await fetch(`${API_ENDPOINT}/auth/users/reset-password`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(response => setConfirmed(true))
			.catch((error) => {
				setError(error)
			})
	}

	const callSubmit = () => {
		sendPasswordReset()
		isSent(true)
	}

	return (
		<>
			<Container style={{ width: '30%' }}>
				<Row>
					<Col>
						<Card className="mt-5">
							<Card.Header className="bg-white border-0">
								<h4>Reset Password</h4>
							</Card.Header>
							<Card.Body>
								{!sent &&
									<Form onSubmit={callSubmit}>

										<Form.Group controlId="formNewPassword">
											<Form.Label>New Password</Form.Label>
											<Form.Control type="password" placeholder="Enter password" value={newPassword} onChange={(p) => setNewPassword(p.currentTarget.value)} />
										</Form.Group>

										<Form.Group controlId="formConfirmPassword">
											<Form.Label>Confirm Password</Form.Label>
											<Form.Control type="password" placeholder="Confirm password" value={confirmPassword} onChange={(p) => setConfirmPassword(p.currentTarget.value)} />
										</Form.Group>


										<Button className="mb-2 rounded-pill" variant="primary" type="submit" block disabled={invalid}>
											Reset Password
										</Button>
									</Form>}
								{sent && !confirmed && <Spinner
									animation="border"
									variant="primary"
									role="status"
									style={{
										height: 50,
										width: 50,
										position: "fixed",
										top: "50%",
										left: "50%"
									}}>
								</Spinner>}
								{sent && confirmed && <div>
									Your password has been updated. 
									

								</div>}
								{error && <><Alert>{error}</Alert></>}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	)


}