import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";


const Tip = ({ 
    content, 
    onConfirm, 
    onOpen 
  }) => {

  const [confirmed, setConfirmed] = useState(false)
  const [choice, setChoice] = useState('')

  const contentChoice = Object.keys(content).pop() || null


  return (
    <div className="Tip">
      <div className="Tip__compact">
        <div>
          <Form >
            <Form.Check
              className="mt-3"
              key={0}
              type="radio"
              name="selection"
              id='Summary'
              label="Add as a new selection to summarize"
              onChange={(e) => setChoice(e.target.id)}
              disabled={contentChoice !== 'text'}
            >
            </Form.Check>
            <Form.Check
              className="mt-3"
              key={1}
              type="radio"
              id='Text'
              name="selection"
              label="Add directly to report selections (not going to summarize)"
              onChange={(e) => setChoice(e.target.id)}
              disabled={contentChoice !== 'text'}
              >
            </Form.Check>
            <Form.Check 
                className="mt-3"
                key={2}
                type="radio"
                id='Image'
                name="selection"
                label="Add as image (not applicable to text)"
                disabled={contentChoice === 'text'}
                onChange={(e) => setChoice(e.target.id)}
                >
              </Form.Check>
          </Form>
          <Button className="m-2 rounded-pill" disabled={choice === ''} onClick={() => {
            setConfirmed(true);
            onOpen();
            onConfirm(choice);
          }}>Submit</Button>
        </div>
      </div>
    </div>


  );
}

export default Tip;