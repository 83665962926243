import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  Container,
  Modal,
  Badge,
  Row,
  Col,
  Alert,
  Button,
  Card,
  Spinner,
  Navbar,
  Form,
  FormControl,
  ButtonGroup
} from 'react-bootstrap';
import { FaExclamationCircle, FaSearch, FaCog, FaPlus, FaPencilAlt, FaSave } from 'react-icons/fa';
import auth from '../auth/auth';
import { Defaultnav } from '../components/navbar';
import _ from 'lodash'
import newPageClear from '../utils/clear';
import DashboardGrid from '../components/dashgrid';
import { LayoutDesigner } from '../components/layout';
import { Footer } from '../components/footer'
import DefaultSpinner from '../components/spinner'
import { useDashboardProvider } from '../contexts/dashboardContext';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import NewsGrid from '../components/newsgrid'; 
import { BuildNewsItems } from '../components/news';

export const Home = (props) => {
  // History hook
  const history = useHistory();

  const {
    user,
    setUser,
    topTags,
    freqSearch,
    articleCount,
    sources,
    previousLoginDate,
    layout,
    showSettings,
    setShowSettings,
    showAddCard, 
    setShowAddCard, 
    addNewDashboardItem,
    addNewsItem,
    indicatorLayout, 
    indicatorSelections,
    saveIndicatorSettings,
    saveDashboardSettings
  } = useDashboardProvider()

  const [show, setShow] = useState(true);
  const [loading, isLoading] = useState(true)
  const [searchText, setSearchText] = useState(null)
  const [view, setView] = useState(0)
  const [addItemError, setAddItemError] = useState(null)
  const [submitSuccess, setSubmitSuccess] = useState(false)


  // const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')

    const fetchData = async () => {
      if (!auth.expiredToken()) {
        const result = await auth.getUser();
        setUser(result);
      };
      if (auth.expiredToken()) {
        auth.logout(() => {
          history.push('/');
        })
      }
    };

    fetchData();

  }, [loading])

  useEffect(() => {
    const loginDate = localStorage.getItem('logIn')
    if (Date.now() - loginDate > 30) {
      setShow(false)
    }

  }, [show])

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Customize Dashboard
  //   </Tooltip>
  // );

  // const onClick = (sortDict) => {
  //   newPageClear()
  //   localStorage.setItem('sort', JSON.stringify(sortDict))
  //   localStorage.setItem('limit', 10)
  //   localStorage.setItem("wasSearched", true)
  //   window.location.href = '/search'

  // }

  const tagClick = (t) => {
    newPageClear()
    localStorage.setItem('filters', JSON.stringify({ [t.Field]: [t.Tags], ['type']: ['PDF', 'Excel', 'Word', "Powerpoint"]}))
    localStorage.setItem("wasSearched", true)
    window.location.href = '/search'
  }

  const searchClick = (s) => {
    newPageClear()
    localStorage.setItem('search', s)
    // if (s.replace(/[-+()\s]/g, ' ').includes(' ')) {
    //   localStorage.setItem('searchtype', 'phrase')
    // }
    localStorage.setItem("wasSearched", true)
    localStorage.setItem('filters', JSON.stringify({['type']: ['PDF', 'Excel', 'Word', "Powerpoint"]}))
    window.location.href = '/search'
  }

  const allDataCollected =
    !_.isEmpty(topTags) &&
    !_.isEmpty(freqSearch) &&
    !_.isEmpty(sources) &&
    !_.isEmpty(indicatorLayout) && 
    !_.isEmpty(indicatorSelections)

  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchClick(searchText || freqSearch[0].Phrase)
    }
  }

  const submitNewsItem = (e) => {
    e.preventDefault(); 
    let form = e.target.elements
    let title = form.title.value || null 
    let search = form.search.value || null 
    let type = form.type.value === 'Keyword' ? 'text' : (form.type.value === 'Phrase' ? 'phrase' :  null) 

    if (title && search && type) {
      addNewsItem(title)
      addNewDashboardItem(title, search, type)
      setAddItemError(null);
      setSubmitSuccess(true); 
      setTimeout(() => {setShowAddCard(false); setSubmitSuccess(false);}, 2000)
      // setShowAddCard(false);
    } else {
      setAddItemError('All fields must be provided to add a new item')
    }


  }

  return (
    <>
      <Defaultnav />
      {!allDataCollected && <DefaultSpinner /> }
      {allDataCollected &&
        <Container >
          <Row>
            <Col className="mt-4 mb-4">
              {show && <><h4>Welcome {user.firstName}! </h4>
                <Alert variant={'primary'}
                  onClose={() => setShow(false)} dismissible>
                  <FaExclamationCircle color='#ffae31'>
                  </FaExclamationCircle>
                  {'  ' + articleCount + ' '} new reports have been uploaded since you logged in on {previousLoginDate} </Alert>
              </>}
              <div className="mt-2">
              </div>
            </Col>
          </Row>
          <h1 style={{ textAlign: 'center' }}>Marketing Insights </h1>
          <Navbar className="card-no-shadow" style={{ width: '100%' }} >
            <Form className="d-flex" style={{ 'width': '100%' }} onKeyDown={keyDownHandler}>
              <FormControl
                id='searchbar'
                type="search"
                placeholder="Labor, Wendy's, Restaurant Traffic"
                className="py-2 mr-n5"
                aria-label="Search"
                aria-describedby="search-bar"
                style={{ 'width': '95%', 'borderRadius': '40px', 'outline': 'none !important' }}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Button
                className="btn rounded-pill ml-2"
                onClick={(e) => searchClick(searchText)}
                disabled={searchText == null}>
                Search<FaSearch className="ml-2" style={{ display: "inline-block" }} ></FaSearch>
              </Button>
            </Form>
          </Navbar>
          <Row>
            <Col>
              <Card className="border-0 shadow-none align-items-center">
                <Card.Header className="border-0 bg-white">
                  <h5> Search by Top Tags </h5>
                </Card.Header>
                <Card.Body style = {{overflow: 'hidden'}}>
                  {_.take(topTags, 4).map(t => {
                    return <Badge
                      className="mt-2 ml-1 p-2"
                      variant={'info'}
                      onClick={(e) => tagClick(t)}
                      style={{ cursor: "pointer", borderRadius: '15px' }}>
                      {t.Tags}
                    </Badge>
                  })}
                </Card.Body>
              </Card>
            </Col>
         
            <Col style = {{borderLeft: "2px solid #f2f4f5"}}>
              <Card className="border-0 shadow-none align-items-center">
                <Card.Header className="border-0 bg-white">
                  <h5> Popular Searches </h5>
                </Card.Header>
                <Card.Body>
                  {_.take(freqSearch, 8).map(s => {
                    return <Badge
                      className="mt-2 ml-2 p-2"
                      variant={'primary'}
                      onClick={(e) => searchClick(s.Phrase)}
                      style={{ cursor: "pointer", borderRadius: '15px' }}>
                      {s.Phrase}
                    </Badge>
                  })}
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  {/* <Button
                    className="pull-right gear-button"
                    variant="link"
                    style={{ position: 'absolute', right: '5%', left: '95%', top: '80%' }}
                    onClick={() => setShowSettings(true)}>
                   <OverlayTrigger
                        placement="right"
                        delay={{ show: 50, hide: 200 }}
                        overlay={renderTooltip}
                      >
                        
                    <FaCog size={30} color = "gray" style={
                      { position: 'absolute', 
                      right: '5%', 
                      left: '95%', 
                      top: '95%' }}  
                      onClick={() => setShowSettings(true)}/> 
                      
                      </OverlayTrigger> 
                      
                  </Button> */}
                </Card.Footer>
              </Card>
            </Col>
            <Modal
              size='md'
              show={showSettings}
              onHide={(e) => setShowSettings(false)}
              backdrop="static"
              keyboard={false}>
              <Modal.Header closeButton>
                <h4>Dashboard Settings</h4>
              </Modal.Header>
              <Modal.Body>
                { view === 0 ? <><Alert variant="info">
                  Choose the metrics you want to see on the dashboard. You can rearrange the tiles after updating.
                </Alert>
                <LayoutDesigner /> </> : <>
                <Alert variant="info"> Choose the metrics you want to see on the dashboard. You can rearrange the tiles after updating.</Alert>
                <BuildNewsItems /></>}
              </Modal.Body>
            </Modal>
            <Modal
              size='lg'
              show={showAddCard}
              onHide={(e) => setShowAddCard(false)}
              backdrop="static"
              keyboard={false}>
              <Modal.Header closeButton>
                <h4>Add News Item</h4>
              </Modal.Header>
              <Modal.Body>
               {!submitSuccess && <> <p> Add an aditional news item by typing in keywords or a phrase to search for. 
                  <Alert variant="warning" className = "mt-2 mb-2">The search must find the keyword(s) or phrase in the title of the article to
                  be considered a match. </Alert>
                </p>
                <p> The news item will be added to the end of the dashboard. You may rearrange once the item is added. <strong> Save the layout to preserve changes</strong></p>
                <Form onSubmit={submitNewsItem}> 
                  <Form.Row> 
                    <Col> 
                      <Form.Control name = 'title' type = "input" placeholder="Enter Title for News Card">
                      </Form.Control>
                    </Col>
                  </Form.Row>
                  <Form.Row className = "mt-2 mb-2">
                  <Col >
                  <Form.Control name = 'search' type="input" placeholder="Enter Search Term or Phrase">
                  </Form.Control>
                  </Col>
                  <Col md={2}>
                  <Form.Control name = 'type' as = "select">
                    <option>Keyword</option>
                    <option>Phrase</option>
                  </Form.Control>
                  </Col>
                  </Form.Row >
                  <Row>
                    <Col>
                        <Button type = "submit" className = "mt-2 mb-2 rounded-pill float-right" > Add </Button>
                    </Col>
                  </Row>
                  {addItemError && <Row><Col><Alert variant="danger">{addItemError}</Alert></Col></Row>}
                </Form> </>} 
                {submitSuccess && <div>
                  <p>Your news item has been added to the dashboard!</p>
                  <Alert className = "mt-2" variant="info">The new item will be at the bottom of the dashboard</Alert>
                  </div>}
              </Modal.Body>
            </Modal>
          </Row>
           <Row>
            <Col  >
              <ButtonGroup >
                <Button onClick={() => setView(0)} active={view === 0}>Macroeconomic Indicators </Button>
                <Button onClick={() => setView(1)} active={view === 1}>Latest News</Button>
                
              </ButtonGroup>
            </Col>
            <Col>
           {view === 1 &&  <Button className = "rounded-pill float-end mr-2" style={{float: 'right'}} variant="grayed" onClick={() => setShowAddCard(true)}>
              <FaPlus></FaPlus> Add
            </Button> }
            {view === 0 && <Button className = "rounded-pill float-end mr-2" style={{float: 'right'}} variant="grayed" onClick={() => saveIndicatorSettings()}>
              <FaSave> </FaSave> Save 
            </Button>}
            {view === 1 && <Button className = "rounded-pill float-end mr-2" style={{float: 'right'}} variant="grayed" onClick={() => saveDashboardSettings()}>
              <FaSave> </FaSave> Save 
            </Button>}
            <Button className="rounded-pill float-end mr-2" 
                    onClick={() => setShowSettings(true)} 
                    style={{float: 'right'}} 
                    variant="grayed">
              <FaPencilAlt className = "mr-2">
              </FaPencilAlt>Customize View
              </Button>
            </Col>
          </Row> 
          {view === 0 ? <DashboardGrid /> : <NewsGrid />}
        </Container>}
      <Footer />
    </>
  );
};
