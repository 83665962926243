import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Home } from './pages/Home';
import { Data } from './pages/Data'
import { Admin } from './pages/Admin'
import { ProtectedRoute } from './auth/protected-route';
import './App.css';
import './index.scss';
import axiosInterceptor from './utils/axiosInterceptor.js'
import authchecker from './auth/authchecker';
import { Search } from './pages/Search';
import { Viewer } from './pages/Preview'
import { ForgotPassword } from './auth/forgotpassword';
import { ResetPassword } from './auth/resetpassword';
import { Route } from 'react-router-dom';
import { InferencePage } from './pages/Inference'
import { FilePage } from './pages/Files'
import ReportBuilder from './pages/Report'
import { FeedbackPage } from './pages/Feedback'
import { RegistrationPage } from './pages/Registration'
import { HelpPage } from './pages/Help';


function App() {
  return (
    // Router Code
    <BrowserRouter>
      <div  className="App">
        <ProtectedRoute 
            path='/'
            exact 
            strict
            component = {authchecker(Home)}
          />
         <ProtectedRoute 
            path='/managedata'
            exact 
            strict
            component = {authchecker(Data)}
          /> 
          <ProtectedRoute 
            path='/search'
            exact 
            strict
            component = {authchecker(Search)}
          /> 
          <ProtectedRoute 
            path='/admin'
            exact 
            strict
            component = {authchecker(Admin)}
          /> 
          <ProtectedRoute 
            path='/preview'
            exact 
            strict
            component = {authchecker(Viewer)}
          /> 
           <ProtectedRoute 
            path='/inference'
            exact 
            strict
            component = {authchecker(InferencePage)}
          /> 
          <Route 
            path='/forgotpassword' 
            exact
            strict 
            component = {ForgotPassword}
            />
          <Route 
            path = '/register' 
            exact 
            strict
            component={RegistrationPage}
            />
          <Route 
            path='/resetpassword' 
            exact
            strict 
            component = {ResetPassword}
            />
          <ProtectedRoute 
            path = '/reportbuilder' 
            exact 
            strict 
            component = {ReportBuilder}
          />
          <ProtectedRoute 
            path = '/files' 
            exact 
            strict 
            component = {FilePage}
          />
          <ProtectedRoute 
            path='/feedback' 
            exact 
            strict 
            component={FeedbackPage} 
            />
            <ProtectedRoute 
            path="/help" 
            exact 
            strict 
            component={authchecker(HelpPage)} 
            />
       </div>
    </BrowserRouter>
  );
};
export default App;
