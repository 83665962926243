import React from 'react';
import { Register } from "../auth/register";
import { Container, Card, Row, Col } from 'react-bootstrap';

export const RegistrationPage = () => {
	return (
		<Container >
			<Row className="mt-5">
				<Col >
					<Card className = "w-50 mx-auto">
						<Card.Header className="border-0 bg-white">
							<h2>Register for an Account</h2>
						</Card.Header>
						<Card.Body>
							<Register />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>

	)
}