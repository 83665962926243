import React, { useContext } from 'react';
import {
	Row,
	Col,
	InputGroup,
	AccordionContext,
	useAccordionToggle
} from 'react-bootstrap';

import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

export const  ContextAwareToggle = ({ children, eventKey, callback }) => {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey),
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Row>
			<InputGroup className=" bg-white" onClick={decoratedOnClick}>
				<Col>
					{children}
				</Col>
				<Col>
					<InputGroup.Prepend className=" border-0 bg-white float-right">
						<InputGroup.Text className='border-0 bg-white'>
							{isCurrentEventKey ? <FaAngleUp> </FaAngleUp> : <FaAngleDown></FaAngleDown>}
						</InputGroup.Text>
					</InputGroup.Prepend>
				</Col>
			</InputGroup>
		</Row>
	);
}