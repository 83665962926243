import React from "react";
import { Link } from "react-router-dom";
import { Badge, Spinner } from 'react-bootstrap'
import { FaEdit } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from "react-bootstrap-table2-paginator"
import * as ReactBootStrap from "react-bootstrap";
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import { FaFolderOpen, FaTrash } from "react-icons/fa";
import { useManageDataProvider } from "../contexts/manageDataContext";


export const CrudTable = () => {

	const {
		data, 
		setShowModal,
		setArticleId,
		setShowDeleteModal,
		setArticle} = useManageDataProvider()

	const rankFormatter = (cell, row, rowIndex, formatExtraData) => {
		return (
			< div
				style={{
					textAlign: "center",
					cursor: "pointer",
					lineHeight: "normal"
				}}>

				< FaEdit
					style={{ fontSize: 20 }}
					color="#0032A0"
				/>
			</div>
		);
	}

	const delFormatter = (cell, row, rowIndex, formatExtraData) => {
		return (
			< div
				style={{
					textAlign: "center",
					cursor: "pointer",
					lineHeight: "normal"
				}}>

				< FaTrash
					style={{ fontSize: 20 }}
					color='#d11a2a'
				/>
			</div>
		);
	}


	const colFormatter = (column, colIndex, { sortElement, filterElement }) => {
		return (
			<div style={{ unset: 'all', display: 'flex', flexDirection: 'column' }}>
				{filterElement}
				{column.text}
				{sortElement}
			</div>
		);
	}

	const columns = [
		{
			dataField: 'Title',
			text: 'Title',
			headerFormatter: colFormatter,
			sort: true,
			headerAttrs: { width: 350 },
			style: { width: 350 },
			filter: textFilter(),
			formatter: (cell, row, rowIndex, extraData) => (
				<div>
					<Link to={{ pathname: '/preview', state: { article: row } }} >{row.Title} </Link>
				</div>
			)
		},
		{
			dataField: 'auto_tags',
			text: 'Tags',
			sort: true,
			headerAttrs: { width: 350 },
			style: { width: 350 },
			filter: textFilter(),
			headerFormatter: colFormatter,
			formatter: (cell, row, rowIndex, extraData) => {
				let taginfo = row.auto_tags
				let list = Array.isArray(taginfo) ? taginfo : Array.from([taginfo])
				let tags = new Set([].concat(...list.filter(i => i).map(t => t.split(','))))
				return (Array.from(tags).slice(0, 3).map(tag => {
					return (<Badge className="ml-2" variant={'info'}> {tag} </Badge>)
				}))
			}
		},
		{
			dataField: 'Publish Date',
			text: 'Publish Date', filter: dateFilter(),
			headerFormatter: colFormatter,
			sort: true
		},
		{
			dataField: 'Uploaded By',
			text: 'Uploaded By', sort: true,
			headerFormatter: colFormatter,
			filter: textFilter()
		},
		{
			dataField: 'Type',
			text: 'Type', sort: true,
			headerFormatter: colFormatter,
			filter: textFilter()
		},
		{
			dataField: 'Upload Date',
			text: 'Upload Date',
			sort: true,
			headerFormatter: colFormatter,
			filter: dateFilter()
		},
		{
			dataField: "Edit", text: "", sort: false, formatter: rankFormatter,
			headerAttrs: { width: 50 },
			attrs: { width: 50, class: "EditRow" },
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					e.preventDefault();
					setArticleId(row._id)
					setShowModal(true)
				}
			}
		},
		{
			dataField: "Delete", text: "", sort: false, formatter: delFormatter,
			headerAttrs: { width: 60 },
			attrs: { width: 50, class: "DeleteRow" },
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					e.preventDefault();
					setArticle(row)
					setShowDeleteModal(true)
					
				}
			}
		}
	]

	return (
			<BootstrapTable
				classes="table mt-3"
				keyField="_id"
				data={data || []}
				columns={columns}
				pagination={paginationFactory()}
				filter={filterFactory()}
				bordered={false}
				noDataIndication={() => data ? <div className = "mt-3 text-center"><Spinner
					animation="border"
					variant="primary"
					role="status"
					style={{
						height: 50,
						width: 50,
						// position: "fixed",
						// top: "50%",
						// left: "50%"
					}}>
				</Spinner> </div>: <div> No Data </div>}
				bootstrap4
				condensed
				hover
			/>
		)
	}