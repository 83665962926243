import React, { useState, useEffect } from 'react';
import { Defaultnav } from '../components/navbar';
import { API_ENDPOINT } from '../utils/endpoint';
import { Container, Row, Col, Card } from 'react-bootstrap'

export const HelpPage = () => {

	const [video, setVideo] = useState(null);

	const key = 'Assets/walkthroughupdate.mp4'


	useEffect(() => {
		const token = localStorage.getItem('token')

		const fetchURL = async (url) => {
			await fetch(`${API_ENDPOINT}/data/store/images?key=${key}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'content-type': 'application/json'
				}
			})
				.then(res => res.json())
				.then(result => { setVideo(result) })
		}
		fetchURL(encodeURIComponent(key))


	}, [])

	return (
		<>
			<Defaultnav />
			<Container >
				<Row >
					<Col className = "mt-5 mb-2">
						<h1 style={{ textAlign: 'center' }}>Help Center</h1>
						<p style={{fontSize: '18px'}}>
						The following sections contain helpful information and answers to some frequently asked questions. If you need any additional help, please send an email to: 
						  <a href="mailto:helpdesk@marketinginsights.app.ec3co.com"> helpdesk@marketinginsights.app.ec3co.com</a> with your questions or concerns. </p>
					</Col>
				</Row>
				<Row>
					<Col>
					<Card className = "mb-5 border-0 shadow-none" style={{ textAlign: 'center'}}>
						<Card.Header className = "bg-transparent border-0"> <h3>  </h3></Card.Header>
						<Card.Body className = "embed-responsive embed-responsive-21by9">
							<div className = "mb-2">
								<p className = "lead">
								Please review the video tutorial below to learn more about MIA, how to search for content and how to customize your dashboard.
								</p>
							</div>

							{video &&  video['url'] && <video  src={video['url']}  controls></video>}
						</Card.Body>
					</Card>
					</Col>
				</Row>
				<Row>
					<Col >
					<div className = "mt-5 mb-3" style={{textAlign: 'center'}}><h2>Frequently Asked Questions</h2></div>
					<Card className = "mt-3 mb-3 border-0 shadow-0" style={{backgroundColor: '#e9efed'}}>
						<Card.Header style={{textAlign: 'start'}} className = "bg-transparent border-0"> <h3>How often does the content update? </h3></Card.Header>
						<Card.Body> 
							<p className = "lead">Internal documents from certain vendors are updated periodically when new content comes in. Often this may be weekly, or a few times per week. External content is 
								updated each day at 5:30 AM CT. 
							</p>
						</Card.Body>
					</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

