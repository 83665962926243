import React, { useState, useEffect, useRef} from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";

import { Button } from "react-bootstrap";
import Tip from './tip.js'


export const PDFTextSelector = (
	{url, 
	setSelection, 
	selection, 
	sendText,
	setTextSelection, 
	textSelections, 
	sendImage,
	selectedArticle,
	clearSummary}) => {

	const [highlights, setHighlights] = useState([])

	
	//const [url, setURL] = useState("https://arxiv.org/pdf/1708.08021.pdf") 

	const getNextId = () => String(Math.random()).slice(2); 

	const parseIdFromHash = () => {
		document.location.hash.slice("#highlight-".length)
	}

	const resetHash = () => {
		document.location.hash ="";
	}

	const searchParams = new URLSearchParams(document.location.search);

	const pdfHighlighter = useRef(null)
  	const getHighlightById = id => highlights.find(highlight => highlight.id === id)
  	const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());
    if (highlight) {
      pdfHighlighter.current.scrollTo(highlight);
    }
  };

  useEffect(() => {
    window.addEventListener(
      "hashchange",
      scrollToHighlightFromHash,
      false
    );

    return () => window.removeEventListener("hashchange", scrollToHighlightFromHash);
  });


  useEffect(() => {
	  setHighlights(selection)
  }, [selection])

	const clearHighlights = () => {
		// console.log("clear")
		setHighlights([])
		setSelection([])
		clearSummary()
	}

	const addHighLight = (highlight) => {
		// console.log("Saving Highlight", highlight); 
		setSelection([{...highlight, id: getNextId(), order: highlights.length + 1}, ...highlights])
		// setHighlights([{ ...highlight, id: getNextId(), order: highlights.length + 1}, ...highlights])
	}

	const addDirectText = (highlight) => {
		// console.log("Saving Text", highlight); 
		// console.log(highlight.text, "text");
		// console.log("title: ", selectedArticle.title)
		// console.log('id', selectedArticle.id)
		sendText(highlight.text, selectedArticle)
		// setHighlights([{...highlight, id: getNextId(), order: highlights.length + 1}, ...highlights])
		// setTextSelection([{...highlight}, ...textSelections])
	}

	const addImage = (highlight) => {
		// console.log("Image : ", highlight)
		sendImage(highlight.image, selectedArticle)
	}

	const deleteHighlight = (index) => {
		const newhighlights = highlights.filter((highlight, idx) => {
		  if (index !== idx) {
			return highlight
		  }
		})
		setHighlights({ newhighlights })
	  }

	function updateHighlight(highlightId, position, content) {
		//console.log("Updating highlight", highlightId, position, content);
	
		setHighlights({
		  highlights: highlights.map(h => {
			return h.id === highlightId
			  ? {
				...h,
				position: { ...h.position, ...position },
				content: { ...h.content, ...content }
			  }
			  : h;
		  })
		});
	  }

	
	const onConfirmSelector = () => {
		
	}

	
	return (
		<>
		<div>{highlights.length > 0 && 
		<Button 
			className = "mb-3 rounded-pill" 
			variant = "danger" 
			onClick={(e) => clearHighlights()} >Clear Highlights
		</Button>}
		</div>
		<div className="App" style={{ display: "flex", height: "100vh" }}>

		 <div
		  style={{
			height: "100vh",
			padding: "1 1 1 1",
			width: "40vw",
			overflowX: "scroll",
			position: "relative",
			backgroundColor: "gray",
			border: "1px solid gray",
			boxShadow: '0 0 10px 0'
		  }}
		> 
		  <PdfLoader url={url} >
			{pdfDocument => (
			  <PdfHighlighter
				ref={pdfHighlighter}
				pdfDocument={pdfDocument}
				enableAreaSelection={event => event.altKey}
				onScrollChange={resetHash}
				scrollRef={scrollTo => { }}
				onSelectionFinished={(
				  position,
				  content,
				  hideTipAndSelection,
				  transformSelection,
				  
				) => (
					 <Tip
					   content={content}
					   onOpen={transformSelection}
					   onConfirm={(choice) => {
						   if (choice === 'Summary') {
							addHighLight({ content, position });
							hideTipAndSelection();
						   } else if (choice === 'Text') {
							   addDirectText(content, position)
						   } else if (choice === 'Image') {
							   addImage(content)
						   } else {
							   addHighLight({content, position})
						   }
	
						
					   }}
					 />
				  )}
				highlightTransform={(
				  highlight,
				  index,
				  setTip,
				  hideTip,
				  viewportToScaled,
				  screenshot,
				  isScrolledTo
				) => {
				  const isTextHighlight = !Boolean(
					highlight.content && highlight.content.image
				  );
  
				  const component = isTextHighlight ? (
					<Highlight
					  isScrolledTo={isScrolledTo}
					  position={highlight.position}
					  comment={highlight.comment}
					/>
				  ) : (
					  <AreaHighlight
						highlight={highlight}
						onChange={boundingRect => {
						  updateHighlight(
							highlight.id,
							{ boundingRect: viewportToScaled(boundingRect) },
							{ image: screenshot(boundingRect) }
						  );
						}}
					  />
					);
  
				  return (
					  <div></div>
					// <Popup
					// //   popupContent={<div>Selected Text {selection.order}</div>}
					//   onMouseOver={popupContent =>
					// 	setTip(highlight, highlight => popupContent)
					//   }
					//   onMouseOut={hideTip}
					//   key={index}
					//   children={component}
					// />
				  );
				}}
				highlights={selection}
			  />
			)}
		  </PdfLoader>
		  
		</div>
		
	   </div>
	   </>
	);
  
  

}
