import React, { useEffect, useState, useContext } from 'react';
import { Card, Badge, Spinner, Alert } from 'react-bootstrap';
import { ChartSelector } from './charts/selector'
import { useDashboardProvider } from '../contexts/dashboardContext'
import DefaultSpinner from '../components/spinner'
import { WidthProvider, Responsive } from "react-grid-layout";
import { API_ENDPOINT } from '../utils/endpoint';
import _ from 'lodash';
import { FaEllipsisV, FaPencilAlt, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const NewsGrid = () => {


	const {
		dashboardSettings,
		setDashboardSettings, 
		saveDashboardSettings,
		newsSelections,
		newsData,
		newsLayout,
		setNewsLayout } = useDashboardProvider()

	const [showDeleteText, setShowDeleteText] = useState(false)
	const isLoaded = true
		// newsData.length > 1 && dashboardSettings.length > 1

	const layouts = {
		'lg': newsLayout,
		'md': newsLayout,
		'sm': newsLayout,
		'xs': newsLayout,
		'xxs': newsLayout
	}

	const deleteUserCard = (id) => {
		setDashboardSettings(prevState => prevState.filter(s => s.id !== id)) 
		
	}

	useEffect(() => {
		saveDashboardSettings()
	}, [dashboardSettings])


	const shortenString = (string) => {
		let short = string.substr(0, 500) 
		if (short[short.length-1] === '.') {
			return short 
		} else {
			return short + '...'
		}
	}

	return (
		<>
			{!isLoaded && <Spinner
				animation="border"
				variant="primary"
				role="status"
				style={{
					height: 100,
					width: 100,
					position: "fixed",
					top: "70%",
					left: "50%"
				}}>
			</Spinner>}
			{isLoaded &&
				<ResponsiveReactGridLayout
					key={newsSelections}
					className="layout draggableHand"
					layouts={layouts}
					cols={{ xl: 12, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					rowHeight={30}
					width={1600}
					measureBeforeMount
					verticalCompact={true}
					draggableHandle=".draggHandle"
					onLayoutChange={(l) => setNewsLayout(l)}
				>
					{dashboardSettings.filter(c => newsSelections.includes(c.id)).map((card) => {
						let dataArray = newsData.filter(d => d.id === card.id)
						return (
							<div key={card.id} hidden={false} >
								<Card className='h-100 w-100' key={newsSelections}>
									<Card.Header className='bg-white draggHandle'> <h3 >{card.id} {card.hasOwnProperty("index")  &&
									 <FaTimes size={14} style = {{cursor: 'pointer'}} onClick ={() => deleteUserCard(card.id)} className = "float-right" onMouseOut = {() => setShowDeleteText(null)} onMouseOver={() => setShowDeleteText(card.id)}>
										</FaTimes>} {card.hasOwnProperty("index")  && showDeleteText === card.id && 
										<span 
											className = "mr-2 p-1 float-right" 
											style={{fontSize: '10pt', color: 'white', backgroundColor:'black', borderRadius: '3px'}}>
												Delete News Item
										</span>}</h3> </Card.Header>
									{dataArray.length > 0 && <Card.Body className="scroller " style={{ cursor: "default" }}>
										{dataArray.map(d => {
											if (d && d.content) {
												let shortendedContent = d.content?.split(/(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/).slice(1, 2)
												return (
													<div className="news-container">
														{/* <div className="news-item" >
														
															{d.image && !d.image.includes('data:image') && <img src={d.image} style={{ width: '125px', height: '100px' }} alt="" onError={i => i.target.hidden = true} />}
														</div> */}
														<div className="news-container">
														<div className="news-item" >
															{d.image && !d.image.includes('data:image') && <img src={d.image} style={{ width: '125px', height: '100px' }} alt="" onError={i => i.target.hidden = true} />}
														</div>
														<div className="news-item" >
															<a href={d.url} target="_blank"><h4>{d.title}</h4></a>
															<p>
																<em>{moment(d.date).format('LL')}</em>  {shortenString(shortendedContent.join(' '))}
															</p>
															<footer>
																{d.tags.length > 0 && d.tags?.map(tag => <Badge variant={'alt1'}>{tag}</Badge>)}
																<br></br> 
																<span style={{fontSize: 11}} >Source:<a href={d.url.split('/').slice(0, 3).join('/')}> {d.source}</a></span>
															</footer>
															<hr></hr>
														</div>
													</div>
													</div>
												)
											}
											
										})}
										 <div className="news-container">
											<div className="news-item">
											{dataArray.length < 10 ?
											<em>End of news found within the past 30 days. Use the <a href="/search" >search</a> function to find more articles outside of this date range.</em> :
											<em>Showing the top 10 results based on relevance</em>}
											</div>
										</div>
									</Card.Body>}
									{dataArray.length === 0 && <Card.Body><div><p> No recent news found for this topic. </p></div></Card.Body>}
									<Card.Footer className="border-0 bg-transparent draggHandle">
									</Card.Footer>
								</Card>
							</div>
						)

					})}

				</ResponsiveReactGridLayout>
			}
		</>
	)
}


export default NewsGrid