
import React from 'react'; 
import {FaQuestionCircle} from 'react-icons/fa'
export const MissingPage = () => {
	return(
<div className="message">
	<h1>Sorry! It looks like we can't find this file right now.  </h1>
	{/* <img
							alt=""
							src={require("../images/cat.png")}
							width="300"
							height="300"
							className="d-inline-block align-top"
						/> */}
		<div><FaQuestionCircle size="sm" color="#ffb236"></FaQuestionCircle></div>
		<h3 className="mt-2">Check back later to see if the file has been found. In the meantime, continue <a href="/search">searching!</a></h3>
	</div>
	)
}
