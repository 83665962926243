import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { ChartSelector } from './charts/selector'
import { useDashboardProvider } from '../contexts/dashboardContext'
import DefaultSpinner from '../components/spinner'
import { WidthProvider, Responsive } from "react-grid-layout";
import { API_ENDPOINT } from '../utils/endpoint';
import _ from 'lodash'; 
import moment from 'moment';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const DashboardGrid = () => {
	
		const { 
			filteredSources, 
			layout, 
			indicatorLayout,
			indicatorSelections,
			loading, 
			setLoading, 
			sources,
			showSource, 
			showDate, 
			setIndicatorLayout,
			saveIndicatorSettings,
			indicatorSettings } = useDashboardProvider()
		

		indicatorLayout.forEach(r => r.i += '');

		const layouts = {'lg': indicatorLayout, 'md': indicatorLayout, 'sm': indicatorLayout, 'xs': indicatorLayout, 'xxs': indicatorLayout}

		// useEffect(() => {
		// 	const token = localStorage.getItem('token')
		// 	const checkIfUserExists = async () => {
		// 		await fetch(`${API_ENDPOINT}/dashboard/settings/newuser`, {
		// 			headers: { 'Content-Type': 'application/json', 
		// 			'Authorization': `Bearer ${token}`},
		// 			method: 'POST',
		// 		  }).then(res => res.json())
		// 			.then(result => console.log(result));
		// 	}
				
		// }, [])

		useEffect(() => {
			let timeout
			if (loading) {
			  timeout = setTimeout(() => setLoading(false), 1000);
			}
			return () => clearTimeout(timeout);
		  }, [loading]);

		return (
			<>
			{loading && <DefaultSpinner />}
			{!loading && <ResponsiveReactGridLayout
				className="layout draggableHand"
				layouts={layouts}
				cols= {{xl: 12, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
				rowHeight={30}
				width={1600}
				measureBeforeMount
				draggableHandle=".draggHandle"
				onLayoutChange={(l) => setIndicatorLayout(l)}
			> 
				{indicatorSelections.length > 0 && indicatorSelections.map((name, i) => {
						let source = sources.find(source => source.name === name); 
						let data = source.graph.type !== 'number' ? 
						ChartSelector.numericYVaues(source.data, source.graph.x, source.graph.y) : 
						source.data;
						return (
							<div key={name} hidden={false} >
								<Card className='h-100 w-100'>
									<Card.Header className='border-0 bg-white draggHandle'> <p className = "lead">{source.name} </p></Card.Header>
									<Card.Body style={{cursor: "default"}}>
										 {ChartSelector[source.graph.type](
											data, 
											source.graph.x || null, 
											source.graph.y || null, 
											source.graph.indicator || null
										)} 
									</Card.Body>
									<Card.Footer className = "border-0 bg-transparent draggHandle">
									<a style = {{fontSize: '8pt'}} href={source.url} target="_blank" >Source: {source.source}</a>
									<br/>
									<span style = {{fontSize: '8pt'}}>Updated {moment(source.updated).startOf('day').fromNow()}</span>	
									</Card.Footer>
								</Card>
							</div>
						)
					})
				}
			</ResponsiveReactGridLayout>}
			</>
		)
	}


export default DashboardGrid