import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Alert, Button, Card, Modal, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import auth from '../auth/auth';
import axios from '../utils/axiosInterceptor';
import Select from 'react-select';
import { formatTypeFields } from '../utils/formatter';
import _ from 'lodash';
import { API_ENDPOINT } from '../utils/endpoint';

export const FormModal = ({ setUpdateTable, directory }) => {


	const INITIAL_STATE = {
		"title": "",
		"publish": "",
		"directory": directory,
		"summary": "",
		"outlet": [],
		"informationtype": [],
		"reporttype": [],
		"rating": 1,
		"description": "",
		"customer": ""
	}

	
	const [file, setFile] = useState(null)
	const [form, setForm] = useState(INITIAL_STATE)
	const [status, setStatus] = useState(null)
	const [task, setTask] = useState(null)
	const [result, setResult] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [fileName, setFileName] = useState(null)
	const [fileExtension, setFileExtension] = useState(null)
	const [fields, setFields] = useState([])

	const handleShow = () => setShowModal(true);

	const resetAll = () => {
		setShowModal(false)
		setStatus(null)
		setResult(null)
		setFileName(null)
		setFileExtension(null)
	}

	const handleClose = () => resetAll()

	const handleFileInput = event => {
		let file = event.target.files[0]
		let filenameWithExtension = file.name
		let filename = filenameWithExtension.substring(0, filenameWithExtension.lastIndexOf('.'))
		let fileextension = filenameWithExtension.substring(filenameWithExtension.lastIndexOf('.') + 1, filenameWithExtension.length)
		setFile(event.target.files[0])
		setFileName(filename)
		setFileExtension(fileextension)
		setForm({ ...form, ['title']: filename, ['datatype']: fileExtension })

	}

	const changeHandler = (e) => setForm({
		...form,
		[e.target.name]: e.target.value
	})

	const multiChangeHandler = (options, target) => {
		const name = target.name
		const selections = Object.keys(options).map(key => options[key].value).join(', ')
		setForm({ ...form, [name]: options.map(o => o.value) })
	}

	useEffect(() => {
		const fetchData = async () => {
			if (auth.isAuthenticated()) {
				const result = await auth.getUser();
				setForm({ ...form, ["user"]: result.email });
			}
		};

		const fetchFields = async () => {
			await fetch(`${API_ENDPOINT}/fields/tags`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(res => { setFields(res) })
		}
		//fetchData();
		fetchFields();
	}, [showModal]);

	const disabledSubmit = form.title === '' ||
		form.datatype === '' ||
		typeof (form.rating) !== 'number' ||
		file === null


	// function getStatus(taskID) {
	// 	fetch(`${API_ENDPOINT}/tasks/${taskID}`, {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		},
	// 	})
	// 		.then(response => response.json())
	// 		.then(res => {
	// 			const taskStatus = res.task_status;
	// 			setResult(taskStatus)
	// 			if (taskStatus === 'finished' || taskStatus === 'failed' || taskStatus === "SUCCESS") return false;
	// 			setTimeout(function () {
	// 				getStatus(res.task_id);
	// 			}, 1000);
	// 		})
	// 		.catch(err => console.log(err));
	// }

	// useEffect(() => {
	// 	if (task && result !== 'SUCCESS') {
	// 		getStatus(task)
	// 	}
	// }, [task, result])

	const sendFile = async (event) => {
		event.preventDefault()
		setStatus('sent')
		const token = localStorage.getItem('token')
		const fileData = new FormData()
		fileData.append("fileobject", file, form.directory + '/' + file.name)
		Object.keys(form).map(key => {
			fileData.append(key, JSON.stringify(form[key]))
		})
		await fetch(`${API_ENDPOINT}/data/store`, {
			method: "POST",
			headers: { 'Authorization': `Bearer ${token}` },
			body: fileData,
		}
		).then((response) => response.json()).then(async (responseJson) => {
			if (responseJson.status === 'success') {
				setTask(responseJson.task.toString())
				setStatus('sent')
				setFileName(null)
				setFileExtension(null)
				setUpdateTable(true)
				setResult('SUCCESS')
			}
		}
		)
	}

	const returnFieldOptions = (v) => {
		const opts = fields.length  !== 0 &&
			fields.filter(f => f.field === v)[0]['values'].map(field => { return { value: field, label: field } })
		return opts
	}


	const fileInput = useRef('fileInput')

	const handleClicker = (ref) => {
		ref.current.click()
	}

	const onHide = () => {
		handleClose()
		setUpdateTable(true)
	}

	const dropDownFields = fields.filter(f => !['customer', 'directory', 'informationtype', 'outlet'].includes(f.field))

	return (
		<>
			<Button
				className="float-right rounded-pill"
				variant="primary"
				onClick={handleShow}>
				Upload Data
			</Button>
			<Modal
				size='xl'
				show={showModal}
				onHide={onHide}
				backdrop="static"
				keyboard={false}>
				<Modal.Header
					closeButton
					className="border-0">
					<Modal.Title>Upload Data</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{status === 'sent' && result !== 'SUCCESS' ?
						<div className="d-flex justify-content-center">
							<Spinner className="spinner" animation="border" role="status">
							</Spinner>
						</div> :
						(status === 'sent' && result === 'SUCCESS' ?
							<Alert variant="success">File has been successfully uploaded!</Alert> :
							<Form.Group controlId="formFile" className="mb-3" >
								<Row className="mb-2">
									<Col>
										<Form.Control
											required
											onChange={handleFileInput}
											type="file"
											ref={fileInput}
											style={{ display: "none" }} />
										<Button
											className="btn btn-secondary rounded-pill"
											onClick={() => handleClicker(fileInput)}>
											Upload File
										</Button>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Label className="required-field"> Article Title</Form.Label>
										<Form.Control name='title'
											required
											defaultValue={fileName}
											onChange={changeHandler}
											type="input" />
									</Col>
									<Col className="col-2">
										<Form.Label className="required-field"> Data Type</Form.Label>
										<Form.Control
											required
											name="datatype"
											type="input"
											defaultValue={fileExtension}
											onChange={changeHandler}
											disabled>
										</Form.Control>
									</Col>
								</Row>
								<Form.Label> Summary </Form.Label>
								<Form.Control
									name="summary"
									onChange={changeHandler}
									as="textarea"
									placeholder="Optional Summary"
									style={{ height: '125px' }}
								/>
								<Row>
									<Col>
										<Form.Label className="required-field">Publish Date</Form.Label>
										<Form.Control
											name='publish'
											onChange={changeHandler}
											type="date" />
									</Col>
									<Col className="col-2">
										<Form.Label>Rating</Form.Label>
										<Form.Control
											required
											name='rating'
											type="number"
											defaultValue={1}
											min={1}
											max={5}
											step={1}
											onChange={changeHandler}
										>
										</Form.Control>
									</Col>
									<Col>
										<Form.Label className="required-field">Directory</Form.Label>
										<Form.Control
											required
											name="directory"
											as="input"
											defaultValue={directory} disabled>
										</Form.Control>
									</Col>
								</Row>
								{_.chunk(dropDownFields, 2).map(data => {
									return (
										<Row>
											{data.map(d =>
												<Col>
													<Form.Label>{d.field && formatTypeFields(d.field)}</Form.Label>
													<Select
														name={d.field}
														options={returnFieldOptions(d.field)}
														onChange={multiChangeHandler}
														isMulti />
												</Col>
											)}
										</Row>
									)
								})}
								<Row >
									<Col className="col-4">
										<Form.Label> Customer </Form.Label>
										<Form.Control
											name="customer"
											type="input"
											onChange={changeHandler} >
										</Form.Control>
									</Col>
									<Col>
										<Form.Label> Short Description </Form.Label>
										<Form.Control
											name="description"
											as="textarea"
											onChange={changeHandler}
											style={{ height: '40px' }} >
										</Form.Control>
									</Col>
								</Row>

								<Button
									type="submit"
									className="mt-3 btn btn-success rounded-pill"
									//disabled={disabledSubmit}
									onClick={sendFile}
									disabled={form.publish === ''}>
									Submit
									
								</Button>

							</Form.Group>
						)}
				</Modal.Body>
			</Modal></>)
}


export const DirectoryModal = ({ setUpdateDirectory }) => {
	const [showModal, setShowModal] = useState(false)
	const [directory, setDirectory] = useState(null)
	const [alert, setAlert] = useState(null)


	const handleShow = () => setShowModal(true);
	const handleClose = () => setShowModal(false);

	const addDirectory = async (event) => {
		event.preventDefault()
		const token = localStorage.getItem('token')

		await fetch(`${API_ENDPOINT}/fields/add`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ 'field': 'directory', 'value': [directory] })
		}
		)
			.then((response) => response.json())
			.then(res => {
				setUpdateDirectory(true)
				handleClose()
				setAlert(res)
			})
	}

	
	return (
		<>
			<Button className="float-right ml-1 rounded-pill" variant="secondary" onClick={handleShow}>
				Add Directory
			</Button>
			<Modal
				style={{ verticalAlign: 'center' }}
				show={showModal}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}>
				<Modal.Header closeButton><Modal.Title>Add Directory</Modal.Title></Modal.Header>
				<Modal.Body>
					<Form.Label> Directory </Form.Label>
					<Form.Control name="folderName" type="input" onChange={(e) => setDirectory(e.target.value)}></Form.Control>
					<Button type="submit" className="mt-3" onClick={addDirectory}>Submit</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}


export const EditArticleModal = ({ modalState, setModalState, id, setUpdateTable }) => {

	const history = useHistory() 
	const [showModal, setShowModal] = useState(modalState)
	const [form, setForm] = useState(null)
	const [fields, setFields] = useState([])
	const [articleID, setArticleID] = useState(id)
	const [hasUpdated, setHasUpdated] = useState(false)
	const [confirmed, setConfirmed] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const handleClose = () => {
		setShowModal(false)
		setArticleID(null)
	}

	useEffect(() => {
		const token = localStorage.getItem('token')
		const fetchData = async (id) => {
			await fetch(`${API_ENDPOINT}/data/files/${id}`, {
				method: 'GET',
				headers: { 'Authorization': `Bearer ${token}` }
			})
				.then(res => res.json())
				.then(result => {
					setForm(result[0])

				})
		}

		const fetchFields = async () => {
			await fetch(`${API_ENDPOINT}/fields/tags`, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(res => setFields(res))
		}
	
			fetchData(articleID)
			fetchFields()
			//setShowModal(true)

	}, [articleID])

	const sendUpdates = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/update/${id}`, {
			method: 'PUT',
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(form)
		})
			.then(response => response.json())
			.then(res => {
				setShowModal(false)
				setUpdateTable(prevState => !prevState)
			})

	}

	const goToSummarizer = () => {
		const selectedArticle = {'title': form.title, 'id': form._id, 'summaries': form.summaries, 'key': form.key}
		history.push({pathname:'/inference', 
					  state: selectedArticle})

	}


	const changeHandler = (e) => {
		const originalForm = form
		originalForm[e.target.name] = e.target.value
		setForm(originalForm)
	}

	const multiChangeHandler = (options, target) => {
		const originalForm = form
		originalForm[target.name] = options.map(o => o.value)
		setForm(originalForm)
		//console.log(form)
		setHasUpdated(!hasUpdated)
	}

	const returnFieldOptions = (v) => {
		const opts = fields.length !== 0 &&
			fields.filter(f => f.field === v)[0]['values'].map(field => { return { value: field, label: field } })
		return opts
	}


	const onHide = () => {
		handleClose()
		setUpdateTable(true)
		setModalState(false)
		setArticleID(null)
	}

	const dropDownFields = fields.filter(f => !['customer', 'directory', 'informationtype', 'outlet'].includes(f.field))

	const mapAsValues = (valueFields) => {

		if (!valueFields) {
			return null
		}
		else if (Array.isArray(valueFields)) {
			const labelVals = valueFields.map(v => {
				return { label: v, value: v }
			})
			return labelVals
		} else {
			const labelVals = { label: valueFields, value: valueFields }
			return labelVals
		}
	}



	return (
		<Modal
			size='xl'
			show={showModal}
			onHide={onHide}
			backdrop="static"
			keyboard={false}>
			<Modal.Header
				closeButton
				className="border-0">
				<Modal.Title>Upload Data</Modal.Title>
			</Modal.Header>
			{form && <Modal.Body key={hasUpdated}>
				<Form.Group controlId="formFile" className="mb-3" key={form}>
					<Row>
						<Col>
							<Form.Label>Article Title</Form.Label>
							<Form.Control name='title'
								defaultValue={form.title}
								onChange={changeHandler}
								type="input" />
						</Col>
						<Col className="col-2">
							<Form.Label> Data Type </Form.Label>
							<Form.Control
								name="datatype"
								type="input"
								defaultValue={form.type}
								onChange={changeHandler}
								disabled>
							</Form.Control>
						</Col>
					</Row>
					<Form.Label> Summary </Form.Label>
					<Form.Control
						name="summary"
						onChange={changeHandler}
						defaultValue={form.summary}
						as="textarea"
						placeholder="Optional Summary"
						style={{ height: '125px' }}
					/>
					<Row>
						<Col>
							<Form.Label>Publish Date</Form.Label>
							<Form.Control
								name='publish'
								defaultValue={form.publish}
								onChange={changeHandler}
								type="date" />
						</Col>
						<Col className="col-2">
							<Form.Label>Rating</Form.Label>
							<Form.Control
								name='rating'
								type="number"
								min={1}
								max={5}
								step={1}
								defaultValue={form.rating}
								onChange={changeHandler}
							>
							</Form.Control>
						</Col>
						<Col>
							<Form.Label>Directory</Form.Label>
							<Form.Control
								name="directory"
								as="select"
								defaultValue={form.directory}
								disabled>
								<option value="Cleveland Research Company">
									Cleveland Research Company
								</option>
							</Form.Control>
						</Col>
					</Row>
					{dropDownFields.map(d => {
						return (
							<Row>
								<Col>
									<Form.Label>{d.field && formatTypeFields(d.field)}</Form.Label>
									<Select
										name={d.field}
										value={mapAsValues(form[d.field]) || null}
										options={returnFieldOptions(d.field)}
										onChange={multiChangeHandler}
										isMulti />
								</Col>
							</Row>
						)
					})}
					<Row >

						<Col className="col-4">
							<Form.Label> Customer </Form.Label>
							<Form.Control
								name="customer"
								type="input"
								onChange={changeHandler} >
							</Form.Control>
						</Col>
						<Col>
							<Form.Label> Short Description </Form.Label>
							<Form.Control
								name="description"
								as="textarea"
								onChange={changeHandler}
								style={{ height: '40px' }} >
							</Form.Control>
						</Col>
					</Row>

					<Button
						type="submit"
						onClick={sendUpdates}
						className="mt-3 btn btn-success rounded-pill">
						Submit
					</Button>
					<Button 
						onClick={goToSummarizer} 
						className = "mt-3 ml-3 btn btn-primary rounded-pill"> 
					Summarize
					</Button>
				</Form.Group>
			</Modal.Body>}
		</Modal>
	)
}

