
import React, { useContext, useState, useEffect, createContext, useCallBack } from "react";
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { API_ENDPOINT } from '../utils/endpoint'
import 'react-dropdown-tree-select/dist/styles.css';
import 'react-quill/dist/quill.snow.css';
import _ from 'lodash'

export const ReportContext = createContext();

export function ReportContextProvider({children}) {

	const [fileTree, setFileTree] = useState([])
	const [selectedArticle, setSelectedArticle] = useState({})
	const [content, setContent] = useState('<h1>Title</h1>')
	const [subject, setSubject] = useState('')
	const [receipients, setReceipients] = useState([])
	const [showToast, setShowToast] = useState(false)
	const [message, setMessage] = useState('')
	const [sendingStatus, setSendingStatus] = useState(false)
	const [selectedArticleList, setSelectedArticleList] = useState([])
	const [showSaveModal, setShowSaveModal] = useState(false)
	const [isNewReport, setIsNewReport] = useState(true)
	const [report, setReport] = useState({})
	const [reportList, setReportList] = useState([])
	const [sourceIDs, setSourceIDs] = useState([])
	const onHideToast = () => {
		setShowToast(false)
	}

	// useEffect(() => {
	// 	localStorage.setItem('content', content)
	// 	localStorage.setItem('subject', subject)
	// 	localStorage.setItem('receipients', receipients)
	// }, [content, subject, receipients])

	const onContentChange = (newContent) => {
		let summary = newContent.summary
		let title = newContent.title
		setContent(prevState => prevState + '<br>' + '<h3>' + title + '</h3>' + '<br>' + summary)
	}

	const getFileStructure = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/file/tree`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(response => response.json())
			.then(res => {
				if (Object.keys(selectedArticleList).length > 0) {
					let updatedTree = res
					let fileList = []
					selectedArticleList.forEach(selectedArticle => {
						let folderIndex = updatedTree.findIndex(o => o.label === selectedArticle.directory)
						let fileIndex = updatedTree[folderIndex]['children'].findIndex(o => o.label === selectedArticle.title)
						updatedTree[folderIndex]['children'][fileIndex]['checked'] = true
						let article = updatedTree[folderIndex]['children'][fileIndex]
						fileList.push({...article, 'title': article.label})
					})
					let folderIndex = updatedTree.findIndex(o => o.label === selectedArticle.directory)
					let fileIndex = updatedTree[folderIndex]['children'].findIndex(o => o.label === selectedArticle.title) 
					setFileTree(updatedTree)
					setSelectedArticleList(fileList)
					setSelectedArticle(prevState => ({ ...prevState, 
						summaries: updatedTree[folderIndex]['children'][fileIndex].summaries, 
						extracted_image: updatedTree[folderIndex]['children'][fileIndex].extracted_image, 
						extracted_text: updatedTree[folderIndex]['children'][fileIndex].extracted_text }))
				} else {
					setFileTree(res)
				}

			})
	}

	// useEffect(() => {
	// 	getFileStructure()
	// }, [selectedArticleList])

	const refreshReportSources = async (sourceIDs) => {
		let ids = sourceIDs.join(',')
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/reports/refresh?id=${ids}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		}).then(res => res.json())
		.then(res => setSelectedArticleList(res))
	}

	// useEffect(() => {
	// 	if (sourceIDs.length > 0) {
	// 		refreshReportSources(sourceIDs)
	// 	}
	// }, [sourceIDs])

	const getReportsFromFileTree = (selectedReport) => {
		let fileList = [];
		selectedReport.sources.forEach(source => {
			let folderIndex = fileTree.findIndex(o => o.label === source.directory)
			let fileIndex = fileTree[folderIndex]['children'].findIndex(o => o.label === source.title)
			let article = fileTree[folderIndex]['children'][fileIndex]
			fileList.push({...article, 'title': article.label})
		})
		setSelectedArticleList(fileList)
	}

	const onChangeReport = (title) => {
		let selectedReport = reportList.filter(r => r.title === title)[0]
		getReportsFromFileTree(selectedReport)
		// setSelectedArticleList(selectedReport.sources || [])
		// console.log("selected reports", selectedReport.sources)
		setSourceIDs(selectedReport.sources.map(s => s.id))
		// console.log("test:", fileTree)
		// refreshReportSources(selectedReport.sources.map(s => s.id))
		setSubject(selectedReport.subject)
		setContent(selectedReport.content)
		setReceipients(selectedReport.contacts || [])
		setReport(selectedReport)
		setSelectedArticle(selectedReport.sources.find(() => true)|| {})
		setIsNewReport(false)
		// getFileStructure()
		
	}

	// update selected articles when changing report
	useEffect(() => {
		getFileStructure()
	}, [report])
	

	const fetchReports = async () => {
			await fetch(`${API_ENDPOINT}/reports`, {
				headers: { 'Content-Type': 'application/json' },
				method: 'GET',
			}).then(res => res.json())
				.then(res => setReportList(res))
		}


	const sendReport = async () => {
		const emailinfo = {
			'email': receipients,
			'subject': subject,
			'body': {
				'report': content
			}
		}
		await fetch(`${API_ENDPOINT}/email/report`, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(emailinfo),
		}).then(d => {
			if (d.status === 200) {
				setMessage('Email sent successfully')
			}
			else {
				setMessage('Email failed to be sent')
			}
			setShowToast(true)
			setSendingStatus(false)
		});

	};

	const pingInference = async() => {
		await fetch(`${API_ENDPOINT}/data/ping`, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
		}).then(res => res.json())
		.then(res => console.log(res))
	}

	const getImageUrl = async (key) => {
		return await fetch(`${API_ENDPOINT}/data/store/images?key=${encodeURIComponent(key)}`, {
			method: 'GET', 
			headers: {'Content-Type': 'application/json'}
		})
		.then(res => res.json())
		.then(res => {
			// console.log(res.url) 
			return res.url
		})
	}

	useEffect(() => {
		getFileStructure()
		fetchReports()
		if (localStorage['permissions'] && localStorage.getItem('permissions') === 'admin') {
			//  pingInference()
		}
	}, [])

	const saveReport = async () => {
		const reportDetails = {
			title: report.name || new Date().toISOString(),
			content: content,
			subject: subject,
			contacts: receipients || [],
			frequency: '',
			status: report.status || 'in-progress',
			sources: selectedArticleList || [],
		}
		await fetch(`${API_ENDPOINT}/reports/save`, {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			body: JSON.stringify(reportDetails)
		}).then(res => res.json())
			.then(res => {
				setShowSaveModal(false)
				fetchReports()
			})
	}

	const updateReport = async () => {
			const reportDetails = {
				title: report.name || report.title || new Date().toISOString(),
				content: content,
				subject: subject,
				contacts: receipients || [],
				frequency: '',
				status: report.status || 'in-progress',
				sources: selectedArticleList || [],
			}
			await fetch(`${API_ENDPOINT}/reports/update`, {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST',
				body: JSON.stringify(reportDetails)
			}).then(res => res.json())
				.then(res => {
					setShowSaveModal(false)
					fetchReports()
				})
		}
	

	const onChange = (currentNode, selectedNodes) => {
		// console.log('onChange::', currentNode, selectedNodes)
		// console.log({ title: currentNode.label, key: currentNode.key })
		// localStorage.setItem('selectedArticle', JSON.stringify(
		// 	{ 	title: currentNode.label, 
		// 		key: currentNode.key, 
		// 		id: currentNode.id, 
		// 		summaries: currentNode.summaries, 
		// 		directory: currentNode.directory }))
		setSelectedArticle(
			{ 	title: currentNode.label, 
				key: currentNode.key, 
				id: currentNode.id, 
				summaries: currentNode.summaries, 
				extracted_text: currentNode.extracted_text,
				directory: currentNode.directory, 
				extracted_image: currentNode.extracted_image}
			)
		let newArticleList = selectedNodes.map(node => {
			return {
				['title']: node.label, 
				['key']: node.key, 
				['id']: node.id, 
				['summaries']: node.summaries, 
				['directory']: node.directory, 
				['extracted_text']: node.extracted_text, 
				['extracted_image']: node.extracted_image}})
		setSelectedArticleList(newArticleList)

	}
	const onAction = (node, action) => {
		// console.log('onAction::', action, node)
	}
	const onNodeToggle = currentNode => {
		// console.log('onNodeToggle::', currentNode)
	}

	const clearAll = () => {
		localStorage.removeItem('content')
		localStorage.removeItem('subject')
		localStorage.removeItem('receipients')
		setContent('')
		setSubject('')
		setReceipients([])
		setShowToast(false)
		setSelectedArticleList([])
		setReport({})
		setIsNewReport(true) 

	}

	

	return (
		<ReportContext.Provider
		  value={{
			fileTree, 
			selectedArticle, 
			setSelectedArticle, 
			content, 
			setContent,
			subject, 
			setSubject, 
			receipients,
			setReceipients,
			showToast, 
			setShowToast,
			message, 
			setMessage, 
			sendingStatus, 
			setSendingStatus, 
			onHideToast, 
			onContentChange, 
			sendReport, 
			onAction, 
			onNodeToggle, 
			clearAll, 
			onChange,
			pingInference, 
			getFileStructure,
			selectedArticleList,
			saveReport,
			showSaveModal, 
			setShowSaveModal, 
			isNewReport, 
			setIsNewReport, 
			reportList, 
			setReportList,
			setReport, 
			report,
			onChangeReport, 
			getImageUrl, 
			updateReport, 
			sourceIDs, 
			refreshReportSources
		  }}
		>
		  {children}
		</ReportContext.Provider>
	  );
	}
	
	export function useReportContextProvider() {
	  const context = useContext(ReportContext);
	  if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	  }
	  return context;
	}