import React, { useState} from 'react';
import {  Alert, Button, Modal, Form  } from 'react-bootstrap';
import _ from 'lodash';
import { API_ENDPOINT } from '../utils/endpoint';
import { RemoveUser } from '../auth/manageuser';


export const DeleteModal = ({ article, showDeleteModal, setShowDeleteModal, setUpdateTable }) => {

	const [confirmTitle, setConfirmTitle] = useState(null)

	const disabled = confirmTitle === article.Title

	const onHide = () => {
		setShowDeleteModal(false)
	}

	const deleteArticle = async (id) => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/delete/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		}).then(res => {
			setShowDeleteModal(false)
			setUpdateTable(prevstate => !prevstate)
			}
		)
	}

	return (
		<Modal
			show={showDeleteModal}
			backdrop="static"
			onHide={onHide}
			keyboard={false}>
			<Modal.Header closeButton>
				Confirm Delete
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to delete <strong> {article.Title}? </strong></p>
				<Alert variant={'danger'} > This is a permanent action. </Alert>
				<p>To proceed, please retype the title below</p>
				<Form>
					<Form.Control
						type='input'
						placeholder={article.Title}
						onChange={(e) => setConfirmTitle(e.target.value)}
					>
					</Form.Control>
				</Form>
				<Button className="mt-3 rounded-pill btn-danger" onClick={(e) => deleteArticle(article._id)} disabled={!disabled}>Permanently Delete</Button>
			</Modal.Body>
		</Modal>
	)

}

export const DeleteAllModal = ({ directory, showDeleteAllModal, setShowDeleteAllModal, setView, setWasDeleted }) => {

	const [confirmDirectory, setConfirmDirectory] = useState(null)


	const disabled = confirmDirectory === directory

	const onHide = () => {
		setShowDeleteAllModal(false)
	}

	const deleteDirectory = async (directory) => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/data/delete/folder?folder=${directory}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		}).then(res => {
			setShowDeleteAllModal(false)
			setView('default')
			setWasDeleted(directory)
			}
		)
	}

	return (
		<Modal
			show={showDeleteAllModal}
			backdrop="static"
			onHide={onHide}
			keyboard={false}>
			<Modal.Header closeButton>
				Confirm Delete
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to delete <strong> {directory}? </strong>This will delete all files within this directory.</p>
				<Alert variant={'danger'} > This is a permanent action. </Alert>
				<p>If you are sure about deleting this directory and <strong>all files associated</strong>, please retype the directory name below</p>
				<Form>
					<Form.Control
						type='input'
						placeholder={directory}
						onChange={(e) => setConfirmDirectory(e.target.value)}
					>
					</Form.Control>
				</Form>
				<Button className="mt-3 rounded-pill btn-danger" onClick={(e) => deleteDirectory(directory)} disabled={!disabled}>Permanently Delete</Button>
			</Modal.Body>
		</Modal>
	)

}

export const DeleteUserModal = ({ user, showDeleteModal, setShowDeleteModal, setUpdateTable }) => {

	const [confirmUser, setConfirmUser] = useState(null)

	const disabled = confirmUser === user.email

	const onHide = () => {
		setShowDeleteModal(false)
	}

	const deleteUser = (id) => {
		setShowDeleteModal(false)
		RemoveUser(id) 
		setUpdateTable(prevState => !prevState) 
	
	}

	return (
		<Modal
			show={showDeleteModal}
			backdrop="static"
			onHide={onHide}
			keyboard={false}>
			<Modal.Header closeButton>
				Confirm Delete
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to remove <strong> {user.firstname}  {user.lastname} </strong> from the application? </p>
				<Alert variant={'danger'} > This is a permanent action. The user will no longer have access through this account. </Alert>
				<p>To proceed, please type the user's email below</p>
				<Form>
					<Form.Control
						type='input'
						placeholder={user.email}
						onChange={(e) => setConfirmUser(e.target.value)}
					>
					</Form.Control>
				</Form>
				<Button className="mt-3 rounded-pill btn-danger" onClick={(e) => deleteUser(user.ID)} disabled={!disabled}>Remove User</Button>
			</Modal.Body>
		</Modal>
	)

}